import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {useEffect } from 'react';
import { DocumentAnnotation } from '../../../../types/taker/documentkeyterms.generated';
import { RawHtmlNode } from '../../nodes/RawHtmlNode';
import { usePdfHighlighter } from '../../../pdfHighlighter/context';

interface SyncAnnotationPluginProps {
    annotations: DocumentAnnotation[];
    aiAnnotations: DocumentAnnotation[];
    lexicalDocumentIdentifier: string;
    pageIndex: number;
    pageScaleFactor: number;
}

const SyncAnnotationPlugin = ({
    annotations,
    aiAnnotations,
    lexicalDocumentIdentifier,
    pageIndex,
    pageScaleFactor
}: SyncAnnotationPluginProps) => {
    const [editor] = useLexicalComposerContext();
    const { 
        navigateHighlightElementIDs,
        commentHighlightsPerDocumentPage
    } = usePdfHighlighter();

    useEffect(() => {
        return mergeRegister(
            editor.registerNodeTransform(RawHtmlNode, (rawHtmlNode) => {
                // Synchronize this node's metadata to the outside state.
                if (commentHighlightsPerDocumentPage[lexicalDocumentIdentifier]) {
                    rawHtmlNode.__metadata["commentDocumentHighlights"] = commentHighlightsPerDocumentPage[lexicalDocumentIdentifier][pageIndex] || [];
                }

                rawHtmlNode.__metadata["documentHighlights"] = annotations.flatMap(a => a.documentHighlights || []);
                rawHtmlNode.__metadata["aiDocumentHighlights"] = aiAnnotations.flatMap(a => a.documentHighlights || []);
                rawHtmlNode.__metadata["pageScaleFactor"] = pageScaleFactor;
                rawHtmlNode.__metadata["navigateHighlightElementIDs"] = navigateHighlightElementIDs;
            })
        );
    }, [
        editor,
        annotations.length,
        aiAnnotations.length,
        pageScaleFactor,
        navigateHighlightElementIDs,
        commentHighlightsPerDocumentPage,
    ]);

    return null;
};

export default SyncAnnotationPlugin;