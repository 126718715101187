import { memo, useMemo, useState } from 'react';
import { $generateHtmlFromNodes } from '@lexical/html';
import { InitialConfigType, LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode } from "@lexical/rich-text";
import { ListItemNode, ListNode } from "@lexical/list";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import ToolbarPlugin from "./ToolbarPlugin";
import TableCellActionMenuPlugin from './TableActionMenuPlugin';
import ImagesPlugin from './ImagesPlugin';
import TableCellResizerPlugin from './TableCellResizer';
import { $getRoot } from "lexical";
import EditorThemeClasses from "./theme";
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ImageNode } from '../../nodes/ImageNode';
import Placeholder from '../../ui/Placeholder';
import { useSnackbar } from 'notistack';
import DiffPlugin from './DiffPlugin';

import './index.css';

interface RichTextEditorProps {
  contentLexical: any | undefined;
  displayOnly?: boolean;
  onUpdate?: (content: string, htmlContent: string, lexicalContent: string) => void;
  onEmptyEditor?: () => void;
  withDiff?: string[];
}

const RichTextEditor = ({
  contentLexical,
  displayOnly = false,
  onUpdate,
  onEmptyEditor,
  withDiff
}: RichTextEditorProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [showDiff, setShowDiff] = useState<boolean>(!!withDiff && withDiff.length > 0);

  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);

  const textToImport = useMemo(() => {
    if (withDiff === undefined) {
      return;
    }
    return withDiff.join(" ");
  }, [withDiff]);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  return (
    <LexicalComposer
      initialConfig={{
        editorState: (editor) => {
          if (contentLexical) {
            let editorState = undefined;
            try {
              editorState = editor.parseEditorState(contentLexical);
            } catch (e) {
              console.warn("editor state could not be parsed, this may be expected");
            } finally {
              if (editorState && !editorState.isEmpty()) {
                editor.setEditorState(editorState);
              } else if (onEmptyEditor) {
                onEmptyEditor();
              }
            }
          }
        },
        editable: !displayOnly,
        namespace: "main",
        theme: EditorThemeClasses,
        onError(error: any) {
          if (!displayOnly) {
            enqueueSnackbar(error, {
              variant: "error"
            });
          }
        },
        nodes: [
          HeadingNode,
          ListNode,
          ListItemNode,
          TableNode,
          TableCellNode,
          TableRowNode,
          ImageNode
        ]
      } as InitialConfigType}
    >
      <>
        {!displayOnly && (
          <ToolbarPlugin
            diffToggle={!!withDiff && withDiff.length > 0}
            onChangeDiff={(b) => setShowDiff(b)}
            textToImport={!!textToImport ? [textToImport] : undefined}
          />
        )}
        {showDiff && (
          <DiffPlugin expectedWords={withDiff ? withDiff : []} />
        )}
        {displayOnly ? (
          <>
            <RichTextPlugin
              contentEditable={
                <ContentEditable />
              }
              placeholder={<Placeholder>...</Placeholder>}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <AutoFocusPlugin />
            <ListPlugin />
            <TabIndentationPlugin />
            <HorizontalRulePlugin />
            <ClearEditorPlugin />
            <TablePlugin hasCellMerge={true} />
            <TableCellResizerPlugin />
            <ImagesPlugin />
          </>
        ) : (
          <div className="editor-container">
            <div className="editor-inner">
              <RichTextPlugin
                contentEditable={
                  <div className="editor-scroller">
                    <div className="editor" ref={onRef}>
                      <ContentEditable className="editor-input" />
                    </div>
                  </div>
                }
                placeholder={<Placeholder>Enter text here!</Placeholder>}
                ErrorBoundary={LexicalErrorBoundary}
              />
              <HistoryPlugin />
              <AutoFocusPlugin />
              <ListPlugin />
              <TabIndentationPlugin />
              <HorizontalRulePlugin />
              <ClearEditorPlugin />
              <TablePlugin hasCellMerge={true} />
              <TableCellResizerPlugin />
              <ImagesPlugin />
              <OnChangePlugin
                onChange={(editorState, editor) => {
                  if (!!onUpdate) {
                    editor.getEditorState().read(() => {
                      const htmlString = $generateHtmlFromNodes(editor, null);
                      onUpdate(
                        $getRoot().getTextContent(),
                        htmlString,
                        JSON.stringify(editor.getEditorState().toJSON())
                      );
                    });
                  }
                }}
              />
              {floatingAnchorElem && (
                <>
                  <TableCellActionMenuPlugin
                    anchorElem={floatingAnchorElem}
                    cellMerge={true}
                  />
                </>
              )}
            </div>
          </div>
        )}
      </>
    </LexicalComposer>
  );
}

export default memo(RichTextEditor);
