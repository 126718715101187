import React, { memo, useEffect } from "react";
import { DocumentKeyTermsHolder } from "../../types/documentKeyTerms";
import { useGetLatestKeyTermGroupDataQuery } from "../../redux/services/takerData";

interface KeyTermGroupLoaderProps {
    takerDocumentUploadId: string;
    onLoaded: (holder: DocumentKeyTermsHolder) => void;
}

const KeyTermGroupLoader = ({
    takerDocumentUploadId,
    onLoaded
}: KeyTermGroupLoaderProps) => {
    const {
        data: latestKeyTermsData,
        isFetching,
        isError
    } = useGetLatestKeyTermGroupDataQuery(takerDocumentUploadId);

    useEffect(() => {
        if (isError || isFetching || !latestKeyTermsData) {
            return;
        }

        const kts = new DocumentKeyTermsHolder(latestKeyTermsData.content);
        if (kts) {
            onLoaded(kts);
        }
    }, [
        isError,
        isFetching,
        latestKeyTermsData
    ]);

    return null;
};

export default memo(KeyTermGroupLoader);