import React, { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createParagraphNode, $createTextNode, $getRoot } from "lexical";
import { $insertFirst, $insertNodeToNearestRoot } from "@lexical/utils";

interface SyncSummaryEditorPluginProps {
    defaultValue: string;
    importedContent?: string[];
}

const SyncSummaryEditorPlugin = ({
    defaultValue,
    importedContent
}: SyncSummaryEditorPluginProps) => {
    const [editor] = useLexicalComposerContext();

    // Update editor when importedContent changes, append all text to the end
    useEffect(() => {
        if (importedContent && importedContent.length > 0) {
            editor.update(() => {
                const paragraph = $createParagraphNode();
                $insertFirst(paragraph, $createTextNode(importedContent.join("\n")));
                $insertNodeToNearestRoot(paragraph);
            });
        }
    }, [importedContent]);

    // Update editor when defaultValue changes, set editor's editorState
    useEffect(() => {
        const isEmpty = (defaultValue === null || defaultValue === undefined || defaultValue === "");
        if (!isEmpty) {
            const currentStringValue = JSON.stringify(editor.getEditorState().toJSON());
            const incomingValueDiffers = currentStringValue !== defaultValue;
            if (incomingValueDiffers) {
                try {
                    // Update editor's editorState directly
                    const editorState = editor.parseEditorState(defaultValue);
                    if (!editorState.isEmpty()) {
                        editor.setEditorState(editorState);
                    }
                } catch (e) {
                    // Update editor's editorState with a plain text input
                    editor.update(
                        () => {
                            // Remove existing content
                            const root = $getRoot();
                            root.clear();

                            // Insert a single paragraph with text
                            const paragraph = $createParagraphNode();
                            $insertFirst(paragraph, $createTextNode(defaultValue));
                            $insertNodeToNearestRoot(paragraph);
                        }
                    );
                }
            }
        }
    }, [defaultValue, editor]);

    return null;
};

export default SyncSummaryEditorPlugin;