import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography, List, ListSubheader, ListItemText, ListItemButton, Stack, IconButton, Button, TextField } from "@mui/material";
import { ALL_ADVISOR_SM_TASKS, useResearchPanelData } from '../../../../containers/ResearchPanelData/ResearchPanelData';
import { useAddSourceMaterialsTransformAnalysisMutation } from '../../../../redux/services/taker';
import { useTakerState } from '../../../../containers/TakerDocumentState/TakerDocumentState';
import { Send } from '@mui/icons-material';
import { useDebounce } from '@uidotdev/usehooks';


const CustomFlow = () => {
    const {
        taker,
        takerDocumentId
    } = useTakerState();
    const {
        researchPanelAdvisorState,
        setResearchPanelAdvisorState
    } = useResearchPanelData();
    const [
        addSMTransform,
        addSMTransformResult
    ] = useAddSourceMaterialsTransformAnalysisMutation();

    const [localQuery, setLocalQuery] = useState(researchPanelAdvisorState.customState?.query);
    const debouncedLocalQuery = useDebounce(localQuery, 250);

    useEffect(() => {
        if (!taker || researchPanelAdvisorState.customState === undefined) {
            return;
        }

        setResearchPanelAdvisorState(prev => ({
            ...researchPanelAdvisorState,
            customState: {
                ...researchPanelAdvisorState.customState,
                query: debouncedLocalQuery
            }
        }));
    }, [debouncedLocalQuery]);

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            border="1px solid lightgray"
            borderRadius={1}
            padding={1}
            marginBottom={1}
        >
            <TextField
                value={localQuery}
                onChange={(e) => {
                    setLocalQuery(e.target.value);
                }}
                variant="standard"
                size="small"
                fullWidth
                inputProps={{
                    sx: { padding: 0 }
                }}
                InputProps={{
                    disableUnderline: true
                }}
            />
            <Button
                color="primary"
                variant="contained"
                disabled={addSMTransformResult.isLoading}
                onClick={() => {
                    if (!taker || researchPanelAdvisorState.sourceMaterialsState === undefined) {
                        return;
                    }
                    addSMTransform({
                        takerId: taker.id,
                        takerDocumentId: takerDocumentId,
                        transformType: "CUSTOM",
                        customQuery: researchPanelAdvisorState.customState?.query
                    });
                }}
                endIcon={<Send />}
            >
                Send
            </Button>
        </Box>
    );
};

export default CustomFlow;

