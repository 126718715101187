import React, { useMemo } from 'react';
import { Box, Typography, Breadcrumbs, Toolbar, Link } from "@mui/material";
import { Assistant } from "@mui/icons-material";
import { ALL_ADVISOR_SM_TASKS, useResearchPanelData } from '../../../containers/ResearchPanelData/ResearchPanelData';

const AdvisorHeader = () => {
    const {
        researchPanelAdvisorState,
        setResearchPanelAdvisorState
    } = useResearchPanelData();

    const selectedTaskGroupName = useMemo(() => {
        if (!researchPanelAdvisorState.sourceMaterialsState?.selectedTaskIndex) {
            return;
        }

        const [groupIndex, _] = researchPanelAdvisorState.sourceMaterialsState.selectedTaskIndex;
        const taskObject = ALL_ADVISOR_SM_TASKS[groupIndex];
        return taskObject.displayGroupName;
    }, [researchPanelAdvisorState.sourceMaterialsState?.selectedTaskIndex]);

    const breadcrumbItems = useMemo(() => {
        const crumbs = [
            <Link
                underline="hover"
                color="inherit"
                onClick={() => {
                    setResearchPanelAdvisorState(prev => ({
                        ...prev,
                        topLevelSelection: undefined
                    }))
                }}
            >
                Advisor
            </Link>
        ];

        if (researchPanelAdvisorState?.topLevelSelection === "SOURCE_MATERIALS") {
            if (selectedTaskGroupName !== undefined) {
                crumbs.push(
                    <Link
                        underline="hover"
                        color="inherit"
                        onClick={() => {
                            setResearchPanelAdvisorState(prev => ({
                                ...prev,
                                sourceMaterialsState: {
                                    ...prev.sourceMaterialsState,
                                    selectedTaskIndex: undefined
                                }
                            }))
                        }}
                    >
                        Source Materials
                    </Link>
                );
                crumbs.push(
                    <Typography sx={{ color: 'text.primary' }}>
                        {selectedTaskGroupName}
                    </Typography>
                );
            } else {
                crumbs.push(
                    <Typography sx={{ color: 'text.primary' }}>
                        Source Materials
                    </Typography>
                );
            }
        } else if (researchPanelAdvisorState?.topLevelSelection === "CUSTOM") {
            crumbs.push(
                <Typography sx={{ color: 'text.primary' }}>
                    Custom
                </Typography>
            );
        }
        return crumbs;
    }, [
        researchPanelAdvisorState.topLevelSelection,
        selectedTaskGroupName
    ]);

    return (
        <Box
            paddingLeft={0.5}
            paddingRight={0.5}
        >
            <Toolbar
                variant="dense"
                disableGutters
                sx={{
                    width: "100%",
                    display: "inline-grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        {breadcrumbItems}
                    </Breadcrumbs>
                </Box>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Assistant color="primary" />
                    <Typography variant="h5" color="primary">
                        &nbsp;Advisor
                    </Typography>
                </Box>
                <span></span>
            </Toolbar>
        </Box>
    );
};

export default AdvisorHeader;
