import React, { useCallback, useEffect, useRef, memo } from "react";
import { useAreaSelection, highlightSelectionFromRect } from "./Selection";
import { usePdfHighlighter } from "../context";

const BoxHighlighterLayer = ({
    enabled = false,
    children
}: {
    enabled?: boolean;
    children: React.ReactNode;
}) => {
    const selectContainerRef = useRef<HTMLElement | null>(null);
    const selection = useAreaSelection({ container: selectContainerRef, enabled });
    const { currentPageRange } = usePdfHighlighter();

    const onUpdateHighlight = useCallback((selection: DOMRect | null) => {
        if (!selection || currentPageRange === undefined) {
            return;
        }
        highlightSelectionFromRect(selection, currentPageRange);
    }, [currentPageRange]);

    useEffect(() => {
        if (selection) {
            onUpdateHighlight(selection);
        }
    }, [selection]);

    return (
        <div
            ref={(r) => {
                if (r) {
                    selectContainerRef.current = r;
                }
            }}
            style={{
                width: "100%",
                height: "100%",
                background: "transparent"
            }}
        >
            {children}
        </div>
    );
};

export default memo(BoxHighlighterLayer);