import React, { useMemo, useState } from 'react';
import {
    Box,
    Button,
    Divider,
    Typography
} from "@mui/material/";
import { IndexedGuidance } from '../../../../redux/models/dataModelTypes';
import { TreeItem2 } from '@mui/x-tree-view/TreeItem2';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';

interface GuidanceItemPickerProps {
    indexedGuidance: IndexedGuidance | null;
    selectedItemIds: string[];
    onUpdateSelectedItems: (itemIds: string[]) => void;
}

const GuidanceItemPicker = ({
    indexedGuidance,
    selectedItemIds,
    onUpdateSelectedItems
}: GuidanceItemPickerProps) => {
    const [expandedItems, setExpandedItems] = useState<string[]>([]);

    const getAllIds = (indexEntries: any[]) => {
        const allIds: string[] = []
        const updateLabeledRef = (indexEntries: any[]) => {
            for (const entry of indexEntries) {
                allIds.push(entry['id']);
                updateLabeledRef(entry['nested']);
            }
        };
        updateLabeledRef(indexEntries);
        return allIds;
    };

    const addAllIdsToExpanded = (ids: string[]) => {
        const idsToAdd = [];
        for (const id of ids) {
            if (!expandedItems.includes(id)) {
                idsToAdd.push(id);
            }
        }
        setExpandedItems([...expandedItems, ...idsToAdd]);
    };

    const addAllIdsToSelected = (ids: string[]) => {
        const idsToAdd = [];
        for (const id of ids) {
            if (!selectedItemIds.includes(id)) {
                idsToAdd.push(id);
            }
        }
        onUpdateSelectedItems([...selectedItemIds, ...idsToAdd]);
    };

    const removeAllIdsFromSelected = (ids: string[]) => {
        const idsToRemove: string[] = [];
        for (const id of ids) {
            if (selectedItemIds.includes(id)) {
                idsToRemove.push(id);
            }
        }
        onUpdateSelectedItems(selectedItemIds.filter((id) => !idsToRemove.includes(id)));
    };

    const handleExpandedItemsChange = (
        event: React.SyntheticEvent,
        itemIds: string[],
    ) => {
        setExpandedItems(itemIds);
    };

    const labeledRefs = indexedGuidance?.referenceMap['labeledRefs'];

    const renderSubTrees = (
        depth: number,
        indexEntries: any[]
    ) => {
        const treeItems = []
        for (let i = 0; i < indexEntries.length; i++) {
            const indexEntry = indexEntries[i];
            if (indexEntry['nested'] && indexEntry['nested'].length > 0) {
                treeItems.push(
                    <TreeItem2
                        itemId={indexEntry['id']}
                        slots={{
                            checkbox: () => null
                        }}
                        label={
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                sx={{
                                    p: 0.5,
                                    pr: 0,
                                }}
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                >
                                    <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                                        {indexEntry['label']}
                                    </Typography>
                                    <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />
                                    <Button
                                        size="small"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            const allIds = getAllIds(indexEntry['nested']);
                                            addAllIdsToExpanded(allIds);
                                            addAllIdsToSelected(allIds);
                                        }}
                                    >
                                        Select All
                                    </Button>
                                    <Button
                                        size="small"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            const allIds = getAllIds(indexEntry['nested']);
                                            addAllIdsToExpanded(allIds);
                                            removeAllIdsFromSelected(allIds);
                                        }}
                                    >
                                        Deselect All
                                    </Button>
                                </Box>
                            </Box>
                        }
                    >
                        <>
                            {renderSubTrees(depth + 1, indexEntry['nested'])}
                        </>
                    </TreeItem2>
                );
            } else {
                treeItems.push(
                    <TreeItem2
                        itemId={indexEntry['id']}
                        label={
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    p: 0.5,
                                    pr: 0,
                                }}
                            >
                                <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                                    {indexEntry['label']}
                                </Typography>
                            </Box>
                        }
                    />
                );
            }
        }
        return treeItems;
    }

    const validatedSelectedItemIds = useMemo(() => {
        const idsAsSet = new Set(selectedItemIds);
        const ids: string[] = [];
        // Iterate through the tree and replace ids with non-aliases
        const iterate = (indexEntries: any[]) => {
            for (const entry of indexEntries) {
                const thisId = entry['id'];
                const thisAltIds: string[] = entry['alt_ids'] ?? [];

                // check if either this id or alias is selected
                if (idsAsSet.has(thisId)) {
                    ids.push(thisId);
                } else if (thisAltIds.some(a => idsAsSet.has(a))) {
                    ids.push(thisId);
                }
                
                if (entry['nested']) {
                    iterate(entry['nested']);
                }
            }
        };

        if (labeledRefs) {
            iterate(labeledRefs);
        }
        return ids;
    }, [
        labeledRefs,
        selectedItemIds
    ]);

    if (!labeledRefs) {
        return null;
    }

    return (
        <SimpleTreeView
            multiSelect
            checkboxSelection
            expandedItems={expandedItems}
            onExpandedItemsChange={handleExpandedItemsChange}
            selectedItems={validatedSelectedItemIds}
            onSelectedItemsChange={(e, itemIds) => {
                onUpdateSelectedItems(itemIds);
            }}
        >
            {renderSubTrees(0, labeledRefs)}
        </SimpleTreeView>
    );
};

export default GuidanceItemPicker;