import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
  LexicalCommand,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
} from 'lexical';
import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { DocumentAnnotation, DocumentHighlight } from '../../../../types/taker/documentkeyterms.generated';
import { createRectsFromDOMRange } from '@lexical/selection';
import { getDOMRangeRect } from '../common';
import { usePdfHighlighter } from '../../../pdfHighlighter/context';

import './index.css';

export interface EditHighlightCommandPayload {
  documentAnnotations: DocumentAnnotation[];
};

export const OPEN_STATUS_COMMAND: LexicalCommand<EditHighlightCommandPayload> = createCommand(
  'OPEN_STATUS_COMMAND',
);

interface EditAnnotationPluginProps {
  editorParentBoundingBox?: DOMRect;
  setNavigateHighlightState: (sticky: boolean, dh: DocumentHighlight[] | undefined) => void
}

export default function EditAnnotationPlugin({
  editorParentBoundingBox,
  setNavigateHighlightState
}: EditAnnotationPluginProps): JSX.Element {
  const [editor] = useLexicalComposerContext();
  const {
    injectEditAnnotationWidget
  } = usePdfHighlighter();
  const [showStatus, setShowStatus] = useState(false);
  const [currentPayload, setCurrentPayload] = useState<EditHighlightCommandPayload>();
  const [originalRangeRect, setOriginalRangeRect] = useState<DOMRect>();
  const [originalSelectionRects, setOriginalSelectionRects] = useState<ClientRect[]>();  

  const hideStatus = useCallback(() => {
    setShowStatus(false);
    setNavigateHighlightState(false, undefined);
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        OPEN_STATUS_COMMAND,
        (payload) => {
          const domSelection = window.getSelection();
          const rootElement = editor.getRootElement();          
          if (domSelection !== null && rootElement !== null) {
            const rangeRect = getDOMRangeRect(domSelection, rootElement);
            const selectionRects = createRectsFromDOMRange(editor, domSelection.getRangeAt(0));
            setOriginalRangeRect(rangeRect);
            setOriginalSelectionRects(selectionRects);
          }
          setShowStatus(true);
          setCurrentPayload(payload);

          if (domSelection !== null) {
            domSelection.removeAllRanges();
          }
          return true;
        },
        COMMAND_PRIORITY_EDITOR,
      ),
    );
  }, [
    editor
  ]);

  return (
    <>
      {showStatus &&
        createPortal(
          injectEditAnnotationWidget(
            currentPayload,
            editor,
            hideStatus,
            originalRangeRect,
            originalSelectionRects,
            editorParentBoundingBox,
          ),
          document.body,
        )}
    </>
  );
}