
import { useCallback, useEffect } from 'react';
import { TakerDocumentAnalysis } from '../../../redux/models/dataModelTypes';
import { useAddDispatchMemoGenAnalysisMutation } from '../../../redux/services/taker';
import { useTakerState } from "../../../containers/TakerDocumentState/TakerDocumentState";


const DispatchReportGenerationPlugin = ({ 
    pendingDispatch, 
    analysis 
} : {
    pendingDispatch: boolean;
    analysis: TakerDocumentAnalysis;
}) => {
    const { 
        takerDocumentId, 
        taker, 
        takerPermissionState,
        isTakerDocumentUninitialized,
        fulfillmentStateHolder
    } = useTakerState();
    
    const [addDispatchMemoGenAnalysis, addDispatchMemoGenAnalysisResult] = useAddDispatchMemoGenAnalysisMutation();

    const dispatchMemoGenAnalysis = useCallback(() => {
        const isAutomationFulfilled = fulfillmentStateHolder?.fulfillmentState?.isFulfilled;

        if (!addDispatchMemoGenAnalysisResult.isLoading 
            && !isTakerDocumentUninitialized
            && takerPermissionState.isReadWrite
            && taker
            && isAutomationFulfilled) {
            addDispatchMemoGenAnalysis({
                takerId: taker.id,
                takerDocumentId,
                analysisId: analysis.id
            });
        }
    }, [
        analysis, 
        taker, 
        takerDocumentId, 
        addDispatchMemoGenAnalysisResult,
    ]);

    useEffect(() => {
        if (pendingDispatch) {
            dispatchMemoGenAnalysis();
        }                             
    }, [pendingDispatch]);

    return null;
}

export default DispatchReportGenerationPlugin;