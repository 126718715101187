import { mergeRegister } from '@lexical/utils';
import {
  COMMAND_PRIORITY_LOW,
  LexicalEditor,
  CLICK_COMMAND,
} from 'lexical';
import React, { useCallback, useEffect, useRef } from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { getDOMRangeRect, setFloatingElemPosition } from '../common';

import './index.css';

export default function EditPdfLinkFloatingToolbar({
  editor,
  anchorElem,
  selectedElementId,
  onRemoveHeaderTagId,
  onRemoveContentTagId,
  targetEntryContentTagIds,
  targetEntryHeaderTagIds,
  disabled
}: {
  editor: LexicalEditor;
  anchorElem: HTMLElement;
  selectedElementId: string;
  onRemoveHeaderTagId: (tagId: string) => void;
  onRemoveContentTagId: (tagId: string) => void;
  targetEntryContentTagIds?: string[];
  targetEntryHeaderTagIds?: string[];
  disabled: boolean;
}): JSX.Element {
  const popupCharStylesEditorRef = useRef<HTMLDivElement | null>(null);

  function mouseMoveListener(e: MouseEvent) {
    if (
      popupCharStylesEditorRef?.current &&
      (e.buttons === 1 || e.buttons === 3)
    ) {
      if (popupCharStylesEditorRef.current.style.pointerEvents !== 'none') {
        const x = e.clientX;
        const y = e.clientY;
        const elementUnderMouse = document.elementFromPoint(x, y);

        if (!popupCharStylesEditorRef.current.contains(elementUnderMouse)) {
          // Mouse is not over the target element => not a normal click, but probably a drag
          popupCharStylesEditorRef.current.style.pointerEvents = 'none';
        }
      }
    }
  }
  function mouseUpListener(e: MouseEvent) {
    if (popupCharStylesEditorRef?.current) {
      if (popupCharStylesEditorRef.current.style.pointerEvents !== 'auto') {
        popupCharStylesEditorRef.current.style.pointerEvents = 'auto';
      }
    }
  }

  useEffect(() => {
    if (popupCharStylesEditorRef?.current) {
      document.addEventListener('mousemove', mouseMoveListener);
      document.addEventListener('mouseup', mouseUpListener);

      return () => {
        document.removeEventListener('mousemove', mouseMoveListener);
        document.removeEventListener('mouseup', mouseUpListener);
      };
    }
  }, [popupCharStylesEditorRef]);

  const updateTextFormatFloatingToolbar = useCallback(() => {
    const popupCharStylesEditorElem = popupCharStylesEditorRef.current;
    const nativeSelection = window.getSelection();
    if (popupCharStylesEditorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      nativeSelection !== null &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const rangeRect = getDOMRangeRect(nativeSelection, rootElement);
      setFloatingElemPosition(
        rangeRect,
        popupCharStylesEditorElem,
        anchorElem
      );
    }
  }, [editor, anchorElem]);

  useEffect(() => {
    const scrollerElem = anchorElem.parentElement;

    const update = () => {
      editor.getEditorState().read(() => {
        updateTextFormatFloatingToolbar();
      });
    };

    window.addEventListener('resize', update);
    if (scrollerElem) {
      scrollerElem.addEventListener('scroll', update);
    }

    return () => {
      window.removeEventListener('resize', update);
      if (scrollerElem) {
        scrollerElem.removeEventListener('scroll', update);
      }
    };
  }, [editor, updateTextFormatFloatingToolbar, anchorElem]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateTextFormatFloatingToolbar();
    });
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateTextFormatFloatingToolbar();
        });
      }),
      editor.registerCommand(
        CLICK_COMMAND,
        () => {
          updateTextFormatFloatingToolbar();
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    );
  }, [editor, updateTextFormatFloatingToolbar]);



  return (
    <div ref={popupCharStylesEditorRef} className="floating-text-format-popup">
      <ButtonGroup
        disableElevation
        size="small"
        variant="contained"
        color="inherit"
      >
        <Button
          disabled={disabled || !targetEntryHeaderTagIds || !targetEntryHeaderTagIds.includes(selectedElementId)} 
          startIcon={<Delete />}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onRemoveHeaderTagId(selectedElementId);
          }}
          data-testid="pdf-link-header-removal-button"
        >
          Header
        </Button>
        <Button
          disabled={disabled || !targetEntryContentTagIds || !targetEntryContentTagIds.includes(selectedElementId)}
          startIcon={<Delete />}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onRemoveContentTagId(selectedElementId);
          }}
          data-testid="pdf-link-content-removal-button"
        >
          Content
        </Button>
      </ButtonGroup>
    </div>
  );
}
