import React, { 
    createContext,
    useContext,
    memo
} from "react";
import { DocumentAnnotation, DocumentHighlight } from "../../types/taker/documentkeyterms.generated";
import { EditHighlightCommandPayload } from "../lexical/plugins/EditAnnotationPlugin";
import { LexicalEditor } from "lexical";
import { AddHighlightCommandPayload } from "../lexical/plugins/AddAnnotationPlugin";

export interface HighlightTerm {
    documentAnnotations?: DocumentAnnotation[];
    aiDocumentAnnotations?: DocumentAnnotation[];
    termName: string;
    identifier?: string;
}

export interface PdfHighlighterElements {
    injectEditAnnotationWidget: (
        payload: EditHighlightCommandPayload | undefined,
        editor: LexicalEditor,
        onCloseCallback: () => void,
        originalRangeRect?: DOMRect,
        originalSelectionRects?: ClientRect[],
        editorParentBoundingBox?: DOMRect,
    ) => React.ReactNode;
    injectAddAnnotationWidget: (
        targetFileUploadItemId: string,
        pageIndex: number,
        payload: AddHighlightCommandPayload | undefined,
        editor: LexicalEditor,
        expandHighlights: () => void,
        undoHighlightsChange: () => void,
        onCloseCallback: () => void,
        originalRangeRect?: DOMRect,
        originalSelectionRects?: ClientRect[],
        editorParentBoundingBox?: DOMRect,
    ) => React.ReactNode;
    injectAdditionFloatingToolbar: (
        editor: LexicalEditor,
        anchorElem: HTMLElement,
        isV2Render: boolean,
        lexicalDocumentIdentifier: string,
        pageIndex: number
    ) => React.ReactNode;
    injectEditFloatingToolbar: (
        editor: LexicalEditor,
        anchorElem: HTMLElement,
        selectedElementId: string
    ) => React.ReactNode;
}

export interface PdfHighlighterState {
    commentHighlightsPerDocumentPage: Record<string, Record<number, DocumentHighlight[]>>;
    availableHightlightTerms: HighlightTerm[] | undefined;
    boxHighlightMode: boolean | undefined;
    zoomLevel: number | undefined;
    panelViewMode: 0 | 1 | 2;
    currentPageRange: [number, number] | undefined;
    scrollToPage: { docId: string; page: number } | undefined;
    scrollToElementID: { docId: string; elementId: string } | undefined;
    navigateHighlightElementIDs: DocumentHighlight[] | undefined;
    stickyNavigateHighlightElementIDs: boolean | undefined;
    applyFiltersToDocuments: boolean | undefined;
    keyTermIdentifierFilters: string[] | undefined;
    setCurrentPageRange: (pr: [number, number] | undefined) => void;
    setScrollToPage: (stp: { docId: string; page: number } | undefined) => void;
    setScrollToElementID: (steid: { docId: string; elementId: string } | undefined) => void;
    setNavigateHighlightElementIDs: (nhe: DocumentHighlight[] | undefined) => void;
    setStickyNavigateHighlightElementIDs: (snhe: boolean | undefined) => void;
}

interface PdfHighlighterService {
    //add: (tagNames: string[], documentAnnotation: DocumentAnnotation) => void;
    //bulkUpdate: (newTagNameLists: string[][], tagNameLists: string[][], documentAnnotations: DocumentAnnotation[]) => void;
    getDocumentAnnotations: (identifier: string, page: number) => DocumentAnnotation[] | undefined;
    getAiGeneratedDocumentAnnotations: (identifier: string, page: number) => DocumentAnnotation[] | undefined;
}

interface PdfHighlighterContextType {
    injectEditAnnotationWidget: (
        payload: EditHighlightCommandPayload | undefined,
        editor: LexicalEditor,
        onCloseCallback: () => void,
        originalRangeRect?: DOMRect,
        originalSelectionRects?: ClientRect[],
        editorParentBoundingBox?: DOMRect,
    ) => React.ReactNode;
    injectAddAnnotationWidget: (
        targetFileUploadItemId: string,
        pageIndex: number,
        payload: AddHighlightCommandPayload | undefined,
        editor: LexicalEditor,
        expandHighlights: () => void,
        undoHighlightsChange: () => void,
        onCloseCallback: () => void,
        originalRangeRect?: DOMRect,
        originalSelectionRects?: ClientRect[],
        editorParentBoundingBox?: DOMRect,
    ) => React.ReactNode;
    injectAdditionFloatingToolbar: (
        editor: LexicalEditor,
        anchorElem: HTMLElement,
        isV2Render: boolean,
        lexicalDocumentIdentifier: string,
        pageIndex: number
    ) => React.ReactNode;
    injectEditFloatingToolbar: (
        editor: LexicalEditor,
        anchorElem: HTMLElement,
        selectedElementId: string
    ) => React.ReactNode;
    
    commentHighlightsPerDocumentPage: Record<string, Record<number, DocumentHighlight[]>>;
    availableHightlightTerms: HighlightTerm[] | undefined;
    boxHighlightMode: boolean | undefined;
    zoomLevel: number | undefined;
    panelViewMode: 0 | 1 | 2;
    currentPageRange: [number, number] | undefined;
    scrollToPage: { docId: string; page: number } | undefined;
    scrollToElementID: { docId: string; elementId: string } | undefined;
    navigateHighlightElementIDs: DocumentHighlight[] | undefined;
    stickyNavigateHighlightElementIDs: boolean | undefined;
    applyFiltersToDocuments: boolean | undefined;
    keyTermIdentifierFilters: string[] | undefined;
    pdfHighlighterService: PdfHighlighterService;
    setCurrentPageRange: (pr: [number, number] | undefined) => void;
    setScrollToPage: (stp: { docId: string; page: number } | undefined) => void;
    setScrollToElementID: (steid: { docId: string; elementId: string } | undefined) => void;
    setNavigateHighlightElementIDs: (nhe: DocumentHighlight[] | undefined) => void;
    setStickyNavigateHighlightElementIDs: (nhe: boolean | undefined) => void;
}

const PdfHighlighterContext = createContext<PdfHighlighterContextType | undefined>(undefined);

interface PdfHighlighterProviderProps {
    children: React.ReactNode;
    pdfHighlighterService: PdfHighlighterService;
    initialState: PdfHighlighterState;
    elements: PdfHighlighterElements;
};

const PdfHighlighterProvider: React.FC<PdfHighlighterProviderProps> = ({ 
    children,
    pdfHighlighterService,
    initialState,
    elements
}) => {
    return (
        <PdfHighlighterContext.Provider value={{
            injectAddAnnotationWidget: elements.injectAddAnnotationWidget,
            injectEditAnnotationWidget: elements.injectEditAnnotationWidget,
            injectAdditionFloatingToolbar: elements.injectAdditionFloatingToolbar,
            injectEditFloatingToolbar: elements.injectEditFloatingToolbar,
            commentHighlightsPerDocumentPage: initialState.commentHighlightsPerDocumentPage,
            availableHightlightTerms: initialState.availableHightlightTerms,
            boxHighlightMode: initialState.boxHighlightMode,
            zoomLevel: initialState.zoomLevel,
            panelViewMode: initialState.panelViewMode,
            currentPageRange: initialState.currentPageRange,
            scrollToPage: initialState.scrollToPage,
            scrollToElementID: initialState.scrollToElementID,
            navigateHighlightElementIDs: initialState.navigateHighlightElementIDs,
            stickyNavigateHighlightElementIDs: initialState.stickyNavigateHighlightElementIDs,
            applyFiltersToDocuments: initialState.applyFiltersToDocuments,
            keyTermIdentifierFilters: initialState.keyTermIdentifierFilters,
            pdfHighlighterService,
            setCurrentPageRange: initialState.setCurrentPageRange,
            setScrollToPage: initialState.setScrollToPage,
            setScrollToElementID: initialState.setScrollToElementID,
            setNavigateHighlightElementIDs: initialState.setNavigateHighlightElementIDs,
            setStickyNavigateHighlightElementIDs: initialState.setStickyNavigateHighlightElementIDs 
        }}>
            {children}
        </PdfHighlighterContext.Provider>
    );
};

const MemoizedPdfHighlighterProvider = memo(PdfHighlighterProvider);

const usePdfHighlighter = () => {
    const context = useContext(PdfHighlighterContext);
    if (!context) {
        throw new Error('usePdfHighlighter must be used within a PdfHighlighterProvider');
    }
    return context;
};

export {
    MemoizedPdfHighlighterProvider as PdfHighlighterProvider,
    usePdfHighlighter
}
