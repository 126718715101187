import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import React, { memo, useCallback } from 'react';
import AiGeneratedSummaryComponent from './AiGeneratedSummaryComponent';
import { $createParagraphNode, $createTextNode, $getRoot } from 'lexical';
import { $insertFirst, $insertNodeToNearestRoot } from '@lexical/utils';
import { FORCE_REPROCESS_TEXT_FOR_KEY_TERMS_COMMAND } from '../AnnotateWithKeyTermsPlugin';

export interface AcceptRejectSummary {
    analysisId: string;
    isCombinedTaggingSummary: boolean;
    ogKeyTermName?: string;
    demoData?: string;
    payloadType: "DOCUMENT" | "DOCUMENT_UPLOAD";
}

interface AiSummaryAcceptRejectPluginProps {
    acceptRejectSummaries: AcceptRejectSummary[];
    onRemove: (i: number, a: boolean, es?: string) => void;
    onJobDone: () => void;
    onJobPending: () => void;
    acceptAiGeneratedResultsFlag: boolean;
    rejectAiGeneratedResultsFlag: boolean;
}

const AiSummaryAcceptRejectPlugin = ({
    acceptRejectSummaries,
    onRemove,
    onJobDone,
    onJobPending,
    acceptAiGeneratedResultsFlag,
    rejectAiGeneratedResultsFlag
}: AiSummaryAcceptRejectPluginProps) => {
    const [editor] = useLexicalComposerContext();

    const handleOnRemove = useCallback((
        index: number,
        withAccept: boolean, 
        newSummary?: string
    ) => {
        if (withAccept) {
            if (newSummary) {
                try {
                    // Update editor's editorState directly
                    const editorState = editor.parseEditorState(newSummary);
                    if (!editorState.isEmpty()) {
                        editor.setEditorState(editorState);
                        editor.dispatchCommand(FORCE_REPROCESS_TEXT_FOR_KEY_TERMS_COMMAND, undefined);
                    }

                    editor.getEditorState().read(() => {
                        onRemove(index, withAccept, JSON.stringify(editor.getEditorState().toJSON()));
                    });
                } catch (e) {
                    // If the direct update didn't work, update editor's editorState with a plain text input
                    editor.update(() => {
                        // Remove existing content
                        const root = $getRoot();
                        root.clear();

                        // Insert a single paragraph with text
                        const paragraph = $createParagraphNode();
                        $insertFirst(paragraph, $createTextNode(newSummary));
                        $insertNodeToNearestRoot(paragraph);
                    }, {
                        onUpdate: () => {
                            editor.dispatchCommand(FORCE_REPROCESS_TEXT_FOR_KEY_TERMS_COMMAND, undefined);
                            editor.getEditorState().read(() => {
                                onRemove(index, withAccept, JSON.stringify(editor.getEditorState().toJSON()));
                            });
                        }
                    });
                }
            }
        } else {
            onRemove(index, false);
        }
    }, [
        editor,
        onRemove
    ]);

    return (
        <>
            {acceptRejectSummaries.map((props, index) => (
                <AiGeneratedSummaryComponent
                    analysisId={props.analysisId}
                    demoData={props.demoData}
                    onRemove={(withAccept, newSummary) => handleOnRemove(index, withAccept, newSummary)}
                    onJobDone={onJobDone}
                    onJobPending={onJobPending}
                    acceptAiGeneratedResultsFlag={acceptAiGeneratedResultsFlag}
                    rejectAiGeneratedResultsFlag={rejectAiGeneratedResultsFlag}
                    payloadType={props.payloadType}
                    metadata={{
                        ogKeyTermName: props.ogKeyTermName
                    }}
                />
            ))}
        </>
    );
}

export default memo(AiSummaryAcceptRejectPlugin);


