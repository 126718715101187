import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { RootReducerType } from "../../redux/models/reduxTypes";
import DefaultLoadingScreen from "../loading/defaultLoadingScreen";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { User } from "../../redux/models/dataModelTypes";
import { store } from "../../redux/store";
import { authAction } from "../../redux/actions/authActions";
import { useEffect } from "react";


const PublicRoutes = () => {
  const {
    isAuthenticated: isAuth0Authenticated,
    isLoading: isAuth0Loading,
    getAccessTokenSilently,
  } = useAuth0();
  const { loaded } = useSelector((state: RootReducerType) => state.auth);

  useEffect(() => {
    const fetchUser = async () => {
      const token = await getAccessTokenSilently();
      try {
        let response = await axios.post(`${window.__RUNTIME_CONFIG__.API_ENDPOINT}/current_user_auth0`, {}, {
          headers: { 'Auth0-Authorization': `Bearer ${token}` },
          withCredentials: true
        });

        const userData = response?.data as User;
        store.dispatch({
          type: authAction.SET_USER_SUCESS,
          payload: userData
        });
      } catch (error) {
        store.dispatch({
          type: authAction.SET_USER_FAILURE,
          payload: "no user available"
        });
      }
    }

    // if we happen to be authenticated, then fetch the user, 
    // but if it fails that is okay.
    if (isAuth0Authenticated) {
      fetchUser();

    // if we are not authenticated, then we can just set the user action directly.
    } else if (!isAuth0Loading && !isAuth0Authenticated) {
      store.dispatch({
        type: authAction.SET_USER_FAILURE,
        payload: "no user available"
      });
    }
  }, [
    isAuth0Authenticated, 
    isAuth0Loading
  ]);

  if (!loaded) {
    return <DefaultLoadingScreen />
  }
  return (<Outlet />);
};

export default PublicRoutes;