import React, { 
    createContext,
    useContext,
    memo
} from "react";
import { BuilderDocument } from "../../../../redux/models/dataModelTypes";

interface BuilderDocumentContextType {
    builderDocument: BuilderDocument;
}

const BuilderDocumentContext = createContext<BuilderDocumentContextType | undefined>(undefined);

interface BuilderDocumentProviderProps {
    children: React.ReactNode;
    builderDocument: BuilderDocument;
};

const BuilderDocumentProvider: React.FC<BuilderDocumentProviderProps> = ({ 
    children,
    builderDocument
}) => {
    return (
        <BuilderDocumentContext.Provider 
            value={{
                builderDocument
            }}
        >
            {children}
        </BuilderDocumentContext.Provider>
    );
};

const MemoizedBuilderDocumentProvider = memo(BuilderDocumentProvider);

const useBuilderDocument = () => {
    const context = useContext(BuilderDocumentContext);
    if (!context) {
        throw new Error('useBuilderDocument must be used within a BuilderDocumentProvider');
    }
    return context;
};

export {
    MemoizedBuilderDocumentProvider as BuilderDocumentProvider,
    useBuilderDocument
}
