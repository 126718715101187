import { ThunkAction } from "redux-thunk";
import { authAction } from "../actions/authActions";
import { RootReducerType } from "../models/reduxTypes";
import { AuthActionType } from "../models/authTypes";
import { languageModelApi } from "../services/languageModel";
import { userApi } from "../services/user";
import { takerApi } from "../services/taker";
import { buildersApi } from "../services/builder";
import { assistantApi } from "../services/assistant";
import { organizationApi } from "../services/organization";

// SIGN OUT
export const signOut = (): ThunkAction<void, RootReducerType, null, AuthActionType> => {
  return async (dispatch) => {
    try {
      dispatch({ type: authAction.SIGN_OUT_REQUEST });

      // Reset states of APIs that serve user data
      dispatch(languageModelApi.util.resetApiState());
      dispatch(userApi.util.resetApiState());
      dispatch(takerApi.util.resetApiState());
      dispatch(buildersApi.util.resetApiState());
      dispatch(assistantApi.util.resetApiState());
      dispatch(organizationApi.util.resetApiState());

      dispatch({ type: authAction.SIGN_OUT_SUCESS });
    } catch (err: any) {
      dispatch({
        type: authAction.SIGN_OUT_FAILURE,
        payload: err,
      });
    }
  };
};
