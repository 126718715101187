import { useCallback, useEffect, useMemo, memo, useState } from "react";
import { IndexedGuidanceJob } from "../../redux/models/dataModelTypes";
import {
    useGetIndexedGuidanceJobPayloadQuery
} from "../../redux/services/indexedGuidance";
import { PdfHighlighterProvider } from "../../components/pdfHighlighter/context";
import PdfHighlighter from "../../components/pdfHighlighter";
import { DocumentAnnotation, DocumentHighlight } from "../../types/taker/documentkeyterms.generated";
import EditPdfLinkFloatingToolbar from "../../components/lexical/plugins/FloatingAnnotationToolbarPlugin/EditPdfLinkFloatingToolbar";
import AddPdfLinkFloatingToolbar from "../../components/lexical/plugins/FloatingAnnotationToolbarPlugin/AddPdfLinkFloatingToolbar";
import { calculateIdWeight } from "../../components/lexical/plugins/FloatingAnnotationToolbarPlugin/utils";
import { flushSync } from "react-dom";

interface GuidancePdfHighligherProps {
    readOnly: boolean;
    assetUrl: string;
    indexedGuidanceJob: IndexedGuidanceJob;
    documentWidth: number;
    documentAnnotationsByPage: Record<number, DocumentAnnotation[]>;
    selectedElementId?: string;
    selectedHighlightedIds: string[];
    targetEntryId?: string;
    targetEntryContentTagIds?: string[];
    targetEntryHeaderTagIds?: string[];
    onTextContentChange: (words: string[]) => void;
    onAddHeaderTagIds: (entryId: string, tagIds: string[]) => void;
    onAddContentTagIds: (entryId: string, tagIds: string[]) => void;
    onRemoveHeaderTagId: (entryId: string, tagId: string) => void;
    onRemoveContentTagId: (entryId: string, tagId: string) => void;
}

const GuidancePdfHighligher = ({
    readOnly,
    assetUrl,
    indexedGuidanceJob,
    documentWidth,
    documentAnnotationsByPage,
    selectedElementId,
    selectedHighlightedIds,
    targetEntryId,
    targetEntryContentTagIds,
    targetEntryHeaderTagIds,
    onTextContentChange,
    onAddHeaderTagIds,
    onAddContentTagIds,
    onRemoveHeaderTagId,
    onRemoveContentTagId
}: GuidancePdfHighligherProps) => {
    const {
        data: analysisPayload,
        isSuccess,
        isLoading,
        isError
    } = useGetIndexedGuidanceJobPayloadQuery({ indexedGuidanceJobId: indexedGuidanceJob.id });

    useEffect(() => {
        if (!analysisPayload) {
            return;
        }

        const words: string[] = [];
        const sortedIds = [];
        if (targetEntryContentTagIds) {
            sortedIds.push(...targetEntryContentTagIds);
        }
        sortedIds.sort((a, b) => 
            calculateIdWeight(a.split("-")) - calculateIdWeight(b.split("-"))
        );
        for (let i = 0; i < sortedIds.length; i++) {
            const tid = sortedIds[i];
            const page = parseInt(tid.split("-")[0]);

            const parser = new DOMParser();
            const pageHtml = (analysisPayload["lexical_pages"][page] as any).root?.children[0].rawHtml;
            const pageDocument = parser.parseFromString(pageHtml, "text/html");
            
            const text = pageDocument.getElementById(tid)?.innerText;
            if (!text) {
                continue;
            }
            words.push(text);
        }
        onTextContentChange(words);
    }, [
        analysisPayload,
        targetEntryId,
        targetEntryContentTagIds?.length
    ]);

    const addHeaderTagIds = useCallback((tagIds: string[] | undefined) => {
        if (!tagIds || !targetEntryId) {
            return;
        }
        onAddHeaderTagIds(targetEntryId, tagIds);
    }, [
        targetEntryId,
        onAddHeaderTagIds
    ]);

    const addContentTagIds = useCallback((tagIds: string[] | undefined) => {
        if (!tagIds || !targetEntryId) {
            return;
        }
        onAddContentTagIds(targetEntryId, tagIds);
    }, [
        targetEntryId,
        onAddContentTagIds
    ]);

    const removeHeaderTagId = useCallback((tagId: string | undefined) => {
        if (!tagId || !targetEntryId) {
            return;
        }
        onRemoveHeaderTagId(targetEntryId, tagId);
    }, [
        targetEntryId,
        onRemoveHeaderTagId
    ]);

    const removeContentTagId = useCallback((tagId: string | undefined) => {
        if (!tagId || !targetEntryId) {
            return;
        }
        onRemoveContentTagId(targetEntryId, tagId);
    }, [
        targetEntryId,
        onRemoveContentTagId
    ]);

    const [scrollToPage, setScrollToPage] = useState<{ docId: string; page: number } | undefined>(() => {
        let scrollToPage: { docId: string; page: number } | undefined = undefined;
        if (selectedElementId !== undefined) {
            const page = parseInt(selectedElementId.split("-")[0]);
            return { docId: "guidance", page: page };
        }
        return scrollToPage;
    });

    const [scrollToElementID, setScrollToElementID] = useState<{ docId: string; elementId: string } | undefined>(() => {
        return selectedElementId ? 
            { docId: "guidance", elementId: selectedElementId } : 
            undefined;
    });

    const [navigateHighlightElementIDs, setNavigateHighlightElementIDs] = useState<DocumentHighlight[] | undefined>(() => {
        return selectedHighlightedIds.map(id => 
            ({ 
                elementType: "TEXTBOX",
                elementId: id
            })
        );
    });

    useEffect(() => {
        let scrollToPage: { docId: string; page: number } | undefined = undefined;
        if (selectedElementId !== undefined) {
            const page = parseInt(selectedElementId.split("-")[0]);
            scrollToPage = { docId: "guidance", page: page };
        }

        flushSync(() => {
            setScrollToPage(scrollToPage);
            setScrollToElementID(selectedElementId ? 
                { docId: "guidance", elementId: selectedElementId } : 
                undefined
            );
        });
    }, [
        selectedElementId
    ]);

    useEffect(() => {
        flushSync(() => {
            setNavigateHighlightElementIDs(selectedHighlightedIds.map(id => 
                ({ 
                    elementType: "TEXTBOX",
                    elementId: id
                })
            ));
        });
    }, [
        selectedHighlightedIds
    ]);

    if (isLoading) {
        return <div>Loading PDF</div>;
    } else if (isError) {
        return <div>Failed to load PDF</div>;
    }

    return (
        <PdfHighlighterProvider
            pdfHighlighterService={{
                getDocumentAnnotations: (identifier: string, page: number) => { 
                    return documentAnnotationsByPage[page]; 
                },
                getAiGeneratedDocumentAnnotations: (identifier: string, page: number) => { return []; },
            }}
            initialState={{
                availableHightlightTerms: [],
                scrollToPage,
                scrollToElementID,
                navigateHighlightElementIDs,

                // unused or constant
                boxHighlightMode: false,
                stickyNavigateHighlightElementIDs: false,
                currentPageRange: undefined,
                zoomLevel: undefined,
                commentHighlightsPerDocumentPage: {},
                panelViewMode: 0,
                applyFiltersToDocuments: false,
                keyTermIdentifierFilters: [],
                setCurrentPageRange: (c) => {},
                setScrollToPage: (pid) => {
                    setScrollToPage(pid);
                },
                setScrollToElementID: (eid) => {
                    setScrollToElementID(eid);
                },
                setNavigateHighlightElementIDs: (nids) => {
                    setNavigateHighlightElementIDs(nids);
                },
                setStickyNavigateHighlightElementIDs: (e) => {}
            }}
            elements={{
                injectAddAnnotationWidget(payload, editor, expandHighlights, undoHighlightsChange, onCloseCallback, originalRangeRect, originalSelectionRects, editorParentBoundingBox) {
                    return null;
                },
                injectEditAnnotationWidget(payload, onCloseCallback, originalRangeRect, originalSelectionRects, editorParentBoundingBox) {
                    return null;
                },
                injectAdditionFloatingToolbar(editor, anchorElem, isV2Render, lexicalDocumentIdentifier, pageIndex) {
                    return (
                        <AddPdfLinkFloatingToolbar
                            editor={editor}
                            anchorElem={anchorElem}
                            disabled={readOnly}
                            onAddContent={addContentTagIds}
                            onAddHeader={addHeaderTagIds}
                        />
                    );
                },
                injectEditFloatingToolbar(editor, anchorElem, selectedElementId) {
                    return (
                        <EditPdfLinkFloatingToolbar
                            editor={editor}
                            anchorElem={anchorElem}
                            selectedElementId={selectedElementId}
                            disabled={readOnly}
                            onRemoveHeaderTagId={removeHeaderTagId}
                            onRemoveContentTagId={removeContentTagId}
                            targetEntryContentTagIds={targetEntryContentTagIds} 
                            targetEntryHeaderTagIds={targetEntryHeaderTagIds}
                        />
                    );
                },
            }}
        >
            <PdfHighlighter
                documentWidth={documentWidth}
                lexicalDocuments={[{
                    identifier: "guidance",
                    lexicalPages: analysisPayload["lexical_pages"],
                    pageMetadata: analysisPayload["metadata"]
                }]}
                pdfUrlGetter={(docId) => assetUrl}
                pdfNameGetter={() => ""}
                options={{
                    readOnly,
                    canvasCacheSize: 10
                }}
            />
        </PdfHighlighterProvider>
    );
}

export default memo(GuidancePdfHighligher);