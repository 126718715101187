

import {
    Button,
    Menu, 
    MenuItem,
} from "@mui/material/";
import { useGetIndexedGuidancePackageVersionsQuery } from "../../redux/services/indexedGuidance";
import { useMemo, useState } from "react";
import { useSelector } from "../../redux/reduxUtils/functions";
import { RootReducerType } from "../../redux/models/reduxTypes";
import axios from "axios";

const baseUrl = !!window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.API_ENDPOINT : "localhost";

interface ExportGuidancePackageButtonProps {
    indexedGuidanceId: string;
}

const ExportGuidancePackageButton = ({ indexedGuidanceId }: ExportGuidancePackageButtonProps) => {
    const {
        data: indexedGuidancePackageVersions
    } = useGetIndexedGuidancePackageVersionsQuery(indexedGuidanceId);

    const [anchorEl, setAnchorEl] = useState<Element>();
    const menuOpen = Boolean(anchorEl);

    const { user } = useSelector((state: RootReducerType) => state.auth);

    const canExportPackage = useMemo(
        () => user && user.roles.includes("SUPERADMIN"),
        [user]
    );

    const downloadPackage = (versionFilename: string) => {
        let parts = versionFilename.split('.');
        axios({
            url: `${baseUrl}/indexed_guidance/${indexedGuidanceId}/download_package`,
            method: "POST",
            data: {
                version: parts[0]
            },
            responseType: "blob",
            withCredentials: true,
        }).then(d => {
            const url = window.URL.createObjectURL(
                new Blob([d.data]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `exported_guidance_${indexedGuidanceId}_${versionFilename}`,
            );
            document.body.appendChild(link);
            link.click();
            if (link.parentNode) {
                link.parentNode.removeChild(link);
            }
        });
    };

    if (!canExportPackage) {
        return null;
    }
    return (
        <>
            <Button
                size="small"
                variant="contained"
                sx={{ marginLeft: "15px" }}
                onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                    e.stopPropagation();
                }}
            >
                download package
            </Button>
            <Menu
                elevation={1}
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={() => setAnchorEl(undefined)}
            >
                {indexedGuidancePackageVersions && indexedGuidancePackageVersions.map(v => (
                    <MenuItem
                        disableRipple
                        onClick={(e: any) => {
                            downloadPackage(v);
                            setAnchorEl(undefined);
                        }}
                    >
                        {v}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

export default ExportGuidancePackageButton;

