import React, { useEffect, useState, memo, useMemo } from "react";
import { Box } from "@mui/material";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import EditorThemeClasses from "../../../components/lexical/wrappers/RichTextEditor/theme";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import ToolbarPlugin from "../../../components/lexical/wrappers/RichTextEditor/ToolbarPlugin";
import TableCellResizerPlugin from "../../../components/lexical/wrappers/RichTextEditor/TableCellResizer";
import TableCellActionMenuPlugin from '../../../components/lexical/wrappers/RichTextEditor/TableActionMenuPlugin';
import KeyTermPopupPlugin from "../../../components/lexical/plugins/KeyTermPopupPlugin";
import { KeyTermNode } from "../../../components/lexical/nodes/KeyTermNode";
import AiSummaryAcceptRejectPlugin, { AcceptRejectSummary } from "../../../components/lexical/plugins/AiGeneratedKeyTermSummaryPlugin/AcceptRejectPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useTakerState } from "../../../containers/TakerDocumentState/TakerDocumentState";
import { useUpdateTakerDocumentAnalysisMutation } from "../../../redux/services/taker";

import '../../../components/lexical/wrappers/RichTextEditor/index.css';

const ForceUpdatePlugin = ({
    defaultValue
}: {
    defaultValue: string;
}) => {
    const [currentValue, _] = useState(defaultValue);
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        if (currentValue) {
            const editorState = editor.parseEditorState(defaultValue);
            if (!editorState.isEmpty()) {
                editor.setEditorState(editorState);
            }
        }
    }, [
        editor,
        currentValue
    ]);

    return null;
};

interface EditableSummaryProps {
    defaultValue?: string;
    onChangeValue: (s: string) => void;
    onJobDone: () => void;
    onJobPending: () => void;
}

const EditableSummary = ({
    defaultValue,
    onChangeValue,
    onJobDone,
    onJobPending
}: EditableSummaryProps) => {
    const { activeTakerDocument } = useTakerState();
    const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);
    
    const [updateAnalysis, updateAnalysisRes] = useUpdateTakerDocumentAnalysisMutation();

    const latestExecutiveSummaryAnalysis = useMemo(() => {
        if (!activeTakerDocument) {
            return;
        }
        let analyses = [...activeTakerDocument.executiveSummaryTakerDocumentAnalyses];
        analyses.sort((a, b) => b.createdAt - a.createdAt);
        return analyses[0];
    }, [activeTakerDocument]);

    const acceptRejectSummaries = useMemo(() => {
        const propList: AcceptRejectSummary[] = [];
        const hasIntialized = !!latestExecutiveSummaryAnalysis?.data['intialized'];
        if (latestExecutiveSummaryAnalysis && !hasIntialized) {
            propList.push({
                analysisId: latestExecutiveSummaryAnalysis.id,
                isCombinedTaggingSummary: false,
                payloadType: "DOCUMENT"
            });
        }
        return propList;
    }, [latestExecutiveSummaryAnalysis]);

    return (
        <Box
            sx={{
                width: '100%',
                display: 'inline-block',
                borderRadius: 1,
                border: "1px solid rgb(195, 195, 195)",
                paddingBottom: '10px',
                backgroundColor: 'white',
            }}
            data-testid={`executive-summary`}
        >
            <LexicalComposer
                initialConfig={{
                    editable: true,
                    namespace: `lexical-executive-summary`,
                    theme: EditorThemeClasses,
                    onError(error: any) {
                        console.error(error);
                    },
                    nodes: [
                        HeadingNode,
                        ListNode,
                        ListItemNode,
                        TableNode,
                        TableCellNode,
                        TableRowNode,
                        KeyTermNode
                    ]
                }}
            >
                <ToolbarPlugin hideAlign />
                <AiSummaryAcceptRejectPlugin
                    acceptRejectSummaries={acceptRejectSummaries}
                    onRemove={(i, withAccept, summaryEditorState) => {
                        if (latestExecutiveSummaryAnalysis) {
                            updateAnalysis({
                                id: latestExecutiveSummaryAnalysis.id,
                                data: {
                                    ...(latestExecutiveSummaryAnalysis.data ?? {}),
                                    intialized: true
                                }
                            });
                        }
                        
                        if (withAccept && summaryEditorState) {
                            onChangeValue(summaryEditorState);
                        }
                    }}
                    onJobDone={onJobDone}
                    onJobPending={onJobPending}
                    acceptAiGeneratedResultsFlag={false} // set to constant to disable
                    rejectAiGeneratedResultsFlag={false} // set to constant to disable
                />   
                <RichTextPlugin
                    contentEditable={
                        <div className="editor-scroller">
                            <div 
                                className="editor" 
                                ref={(_floatingAnchorElem: HTMLDivElement) => {
                                    if (_floatingAnchorElem !== null) {
                                        setFloatingAnchorElem(_floatingAnchorElem);
                                    }
                                }}
                            >
                                <ContentEditable
                                    className="editor-input"
                                    style={{
                                        paddingTop: '10px',
                                        paddingBottom: '0px',
                                        minHeight: '25px',
                                    }}
                                />
                            </div>
                        </div>
                    }
                    placeholder={<div className="editor-placeholder"></div>}
                    ErrorBoundary={LexicalErrorBoundary}
                />
                <OnChangePlugin
                    onChange={(editorState, editor) => onChangeValue(JSON.stringify(editorState.toJSON()))}
                />
                <>
                    {defaultValue && (
                        <ForceUpdatePlugin defaultValue={defaultValue} />
                    )}
                </>
                <ListPlugin />
                <HistoryPlugin />
                <TabIndentationPlugin />
                <HorizontalRulePlugin />
                <ClearEditorPlugin />
                <TablePlugin hasCellMerge />
                <TableCellResizerPlugin />
                <>
                    {floatingAnchorElem && (
                        <TableCellActionMenuPlugin
                            anchorElem={floatingAnchorElem}
                            cellMerge={true}
                        />
                    )}
                </>
                <KeyTermPopupPlugin />
            </LexicalComposer>
        </Box>
    );
}

export default memo(EditableSummary);