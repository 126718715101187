import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { WidgetProps } from '@rjsf/utils';
import { Commentary } from '../../../../types/builderv2.generated';
import { Stack } from "@mui/material/";
import GuidancePicker from './GuidancePicker';
import GuidanceDisplayItem, { GuidanceContainer } from './GuidanceDisplayItem';
import { useBuilderDocument } from './context';

const CustomCommentaryWidget: FC<WidgetProps<Commentary>> = ({
	value,
	onChange,
	label,
	required,
}) => {
	const builderDocumentContext = useBuilderDocument();

	const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
	const [selectedIndexedGuidanceId, setSelectedIndexedGuidanceId] = useState<string>();

	const [localValue, setLocalValue] = useState<GuidanceContainer[]>(value);

	useEffect(
		() => onChange(localValue),
		[localValue]
	);

	const selectedGuidanceId = useMemo(() => {
		if (!selectedIndexedGuidanceId) {
			return;
		}

		const selectedBdg = builderDocumentContext.builderDocument.builderDocumentGuidances.find(
			bdg => bdg.indexedGuidanceId === selectedIndexedGuidanceId
		);

		if (!selectedBdg) {
			return;
		}
		return selectedBdg.indexedGuidance?.guidanceId;
	}, [
		builderDocumentContext.builderDocument,
		selectedIndexedGuidanceId
	]);

	const handleOnAdd = useCallback(() => {
		if (!selectedIndexedGuidanceId || !selectedGuidanceId || !selectedItemIds.length) {
			return;
		}

		setLocalValue([
			...localValue,
			{
				id: selectedIndexedGuidanceId,
				label: "",
				type: "custom-guidance-container",
				value: selectedGuidanceId,
				nested: selectedItemIds.map(itemId => ({
					id: itemId,
					label: "",
					type: "custom-guidance-item",
					value: itemId,
					nested: []
				}))
			}
		]);
	}, [
		localValue,
		selectedIndexedGuidanceId,
		selectedGuidanceId,
		selectedItemIds
	]);

	const handleOnDelete = useCallback((index: number) => {
		setLocalValue(localValue.filter((item, i) => i !== index));
	}, [
		localValue
	]);

	return (
		<div>
			<label>
				Guidance
				{required ? ' *' : ''}
			</label>
			<Stack spacing={2} >
				<GuidancePicker
					builderDocument={builderDocumentContext.builderDocument}
					indexedGuidanceId={selectedIndexedGuidanceId}
					setIndexedGuidanceId={setSelectedIndexedGuidanceId}
					selectedItemIds={selectedItemIds}
					onUpdatedItems={setSelectedItemIds}
					onAdd={handleOnAdd}
				/>
				{localValue.map((item, i) => (
					<GuidanceDisplayItem
						key={i}
						builderDocument={builderDocumentContext.builderDocument}
						guidanceContainer={item}
						onDelete={() => handleOnDelete(i)}
						onSelect={() => {
							let bdg = builderDocumentContext.builderDocument.builderDocumentGuidances.find(
								bdg => bdg.indexedGuidance?.guidanceId === item.value
							);
							setSelectedIndexedGuidanceId(bdg?.indexedGuidanceId);
							setSelectedItemIds(item.nested.map(nItem => nItem.value));
						}}
					/>
				))}
			</Stack>
		</div>
	);
};

export default CustomCommentaryWidget;
