import { Box, Divider, IconButton, Stack, Toolbar, Typography } from "@mui/material";
import { BuilderDocument } from "../../../../redux/models/dataModelTypes";
import { useMemo } from "react";
import { Remove, SelectAll } from "@mui/icons-material";

interface GuidanceDisplayItemTreeProps {
    labeledRefs: any[];
}

const GuidanceDisplayItemTree = ({ labeledRefs }: GuidanceDisplayItemTreeProps) => {
    const renderSubTrees = (
        depth: number,
        indexEntries: any[]
    ) => {
        const treeItems = []
        for (let i = 0; i < indexEntries.length; i++) {
            const indexEntry = indexEntries[i];
            if (indexEntry['nested'] && indexEntry['nested'].length > 0) {
                treeItems.push(
                    <li>
                        <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                            {indexEntry['label']}
                        </Typography>
                        <ul>
                            {renderSubTrees(depth + 1, indexEntry['nested'])}
                        </ul>
                    </li>
                );
            } else {
                treeItems.push(
                    <li>
                        <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                            {indexEntry['label']}
                        </Typography>
                    </li>
                );
            }
        }
        return treeItems;
    }

    if (!labeledRefs) {
        return null;
    }
    return (
        <ul>
            {renderSubTrees(0, labeledRefs)}
        </ul>
    );
};


export interface GuidanceContainer {
    id: string;
    label: string;
    type: "custom-guidance-container";
    value: string;
    nested: {
        id: string;
        label: string;
        type: "custom-guidance-item";
        value: string;
    }[];
}

interface GuidanceDisplayItemProps {
    builderDocument: BuilderDocument;
    guidanceContainer: GuidanceContainer;
    onDelete: () => void;
    onSelect: () => void;
}

const GuidanceDisplayItem = ({
    builderDocument,
    guidanceContainer,
    onDelete,
    onSelect
}: GuidanceDisplayItemProps) => {

    const indexedGuidance = useMemo(() => {
        let bdg = builderDocument.builderDocumentGuidances.find(
            g => g.indexedGuidance?.guidanceId === guidanceContainer.value
        );
        if (bdg) {
            return bdg.indexedGuidance;
        }
        return null;
    }, [
        builderDocument,
        guidanceContainer
    ]);

    const guidanceItemIdSet = useMemo(() => {
        if (!guidanceContainer) {
            return new Set<string>();
        }
        return new Set<string>(guidanceContainer.nested.map(n => n.value));
    }, [guidanceContainer]);

    const filteredLabeledRefs = useMemo(() => {
        if (!indexedGuidance) {
            return [];
        }

        const labeledRefsCopy = JSON.parse(JSON.stringify(indexedGuidance.referenceMap['labeledRefs']));
        const renderSubTrees = (indexEntries: any[]) => {
            const treeItems = []
            for (let i = 0; i < indexEntries.length; i++) {
                const indexEntry = indexEntries[i];
                const altIds: string[] = indexEntry['alt_ids'] ?? [];
                if (indexEntry['nested'] && indexEntry['nested'].length > 0) {
                    const newNested = renderSubTrees(indexEntry['nested']);
                    if (newNested.length > 0) {
                        indexEntry['nested'] = newNested;
                        treeItems.push(indexEntry);
                    }
                } else if (
                    guidanceItemIdSet.has(indexEntry['id'])
                    || (altIds.some(a => guidanceItemIdSet.has(a)))) {
                    treeItems.push(indexEntry);
                }
            }
            return treeItems;
        }
        return renderSubTrees(labeledRefsCopy);
    }, [
        guidanceItemIdSet,
        indexedGuidance
    ]);

    return (
        <Stack
            sx={{
                border: '1px solid #ddd',
                borderRadius: 1,
                justifyContent: 'space-between',
            }}
        >
            <Toolbar
                disableGutters
                variant="dense"
                sx={{
                    width: '100%',
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant="body2">
                    {indexedGuidance?.name}
                </Typography>
                <Box display="flex" gap={1}>
                    <IconButton
                        size="small"
                        onClick={onSelect}
                    >
                        <SelectAll />
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={onDelete}
                    >
                        <Remove />
                    </IconButton>
                </Box>
            </Toolbar>
            <Divider />
            <Box sx={{ width: '100%', }} >
                {filteredLabeledRefs.length === 0 ? (
                    <Typography>{JSON.stringify(guidanceContainer)}</Typography>
                ) : (
                    <GuidanceDisplayItemTree labeledRefs={filteredLabeledRefs} />
                )}
            </Box>
        </Stack>
    );
};

export default GuidanceDisplayItem;