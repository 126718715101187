import type { BaseQueryFn, FetchBaseQueryError } from '@reduxjs/toolkit/query'
import axios from 'axios'
import type { AxiosRequestConfig, AxiosError } from 'axios'

interface AxiosBaseQueryProps { 
    baseUrl?: string 
};

const defaultAxiosBaseQueryProps = { 
    baseUrl: !!window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.API_ENDPOINT : "localhost",
} as AxiosBaseQueryProps;

export type ApiError = {
    data: string;
}

type ReturnType = BaseQueryFn<
    {
        url: string
        method: AxiosRequestConfig['method']
        data?: AxiosRequestConfig['data']
        params?: AxiosRequestConfig['params']
    },
    unknown,
    ApiError
>;

type PostFormReturnType = BaseQueryFn<
    {
        url: string
        formData: any
    },
    unknown,
    ApiError
>;

const axiosBaseQuery = ({ 
    baseUrl 
}: AxiosBaseQueryProps = defaultAxiosBaseQueryProps): ReturnType => async ({ 
    url, 
    method, 
    data, 
    params 
}) => {
    try {
        const result = await axios({ 
            url: `${baseUrl}/${url}`, 
            method, 
            data, 
            params,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            withCredentials: true // this should be set for every request
        });
        return { 
            data: result.data 
        };
    } catch (axiosError) {
        let err = axiosError as AxiosError;
        let errData = err.response?.data && err.response.data as any;
        return {
            error: {
                data: errData
            }
        };
    }
};

const axiosPostForm = ({ 
    baseUrl 
}: AxiosBaseQueryProps = defaultAxiosBaseQueryProps): PostFormReturnType => async ({ 
    url, 
    formData
}) => {
    try {
        const result = await axios.post(
            `${baseUrl}/${url}`, 
            formData,
            { withCredentials: true },
        );
        return { 
            data: result.data 
        };
    } catch (axiosError) {
        let err = axiosError as AxiosError;
        let errData = err.response?.data && err.response.data as any;
        return {
            error: {
                data: errData['error'] || err.message,
            }
        };
    }
};

export { axiosBaseQuery, axiosPostForm };