import { createApi } from '@reduxjs/toolkit/query/react';
import { 
    TakerDocumentData,
    TakerDocumentUploadData
} from "../models/dataModelTypes";
import { axiosBaseQuery } from '../reduxUtils/baseQuery';

export const takerDataApi = createApi({
    reducerPath: 'takerDataApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: [
        'LatestTakerDocumentData', 
        'LatestTakerDocumentUploadData',
        'LatestKeyTermsGroupData',
        'TakerDocumentFulfillmentData'
    ],
    endpoints: (build) => ({
        getLatestTakerDocumentData: build.query<TakerDocumentData, { takerDocumentId: string, contentType: string }>({
            query({ takerDocumentId, contentType }) {
                return {
                    url: `taker_document_data/latest?taker_document_id=${takerDocumentId}&content_type=${contentType}`,
                    method: "GET"
                };
            },
            providesTags: (result, error, { takerDocumentId, contentType }) => [
                { type: 'LatestTakerDocumentData', id: `${takerDocumentId}-${contentType}` }
            ],
        }),
        getLatestTakerDocumentUploadData: build.query<TakerDocumentUploadData, { takerDocumentUploadId: string, contentType: string }>({
            query({ takerDocumentUploadId, contentType }) {
                return {
                    url: `taker_document_upload_data/latest?taker_document_upload_id=${takerDocumentUploadId}&content_type=${contentType}`,
                    method: "GET"
                };
            },
            providesTags: (result, error, { takerDocumentUploadId, contentType }) => [
                { type: 'LatestTakerDocumentUploadData', id: `${takerDocumentUploadId}-${contentType}` }
            ],
        }),
        addTakerDocumentData: build.mutation<TakerDocumentData, Partial<TakerDocumentData> & { takerId: string }>({
            query(data) {
                const {
                    takerDocumentId,
                    content,
                    contentType
                } = data;
                return {
                    url: `taker_document_data`,
                    method: 'POST',
                    data: {
                        taker_document_id: takerDocumentId,
                        content: content,
                        content_type: contentType
                    }
                }
            },
            invalidatesTags: (result, error, { takerDocumentId, contentType }) => [
                { type: 'LatestTakerDocumentData', id: `${takerDocumentId}-${contentType}` }
            ],
        }),
        updateTakerDocumentData: build.mutation<TakerDocumentData, Partial<TakerDocumentData> & { takerId: string }>({
            query(data) {
                const { id, content } = data;
                return {
                    url: `taker_document_data/${id}`,
                    method: 'PUT',
                    data: { content: content }
                }
            },
            invalidatesTags: (result, error, { takerDocumentId, contentType }) => [
                { type: 'LatestTakerDocumentData', id: `${takerDocumentId}-${contentType}` }
            ],
        }),
        deleteTakerDocumentData: build.mutation<TakerDocumentData, Partial<TakerDocumentData> & { takerId: string }>({
            query(data) {
                const { id } = data;
                return {
                    url: `taker_document_data/${id}`,
                    method: 'DELETE'
                }
            },
            invalidatesTags: (result, error, { takerDocumentId, contentType }) => [
                { type: 'LatestTakerDocumentData', id: `${takerDocumentId}-${contentType}` }
            ],
        }),
        addTakerDocumentUploadData: build.mutation<TakerDocumentUploadData, Partial<TakerDocumentUploadData> & { takerId: string, takerDocumentId: string; }>({
            query(data) {
                const {
                    takerDocumentUploadId,
                    content,
                    contentType
                } = data;
                return {
                    url: `taker_document_upload_data`,
                    method: 'POST',
                    data: {
                        taker_document_upload_id: takerDocumentUploadId,
                        content: content,
                        content_type: contentType
                    }
                }
            },
            invalidatesTags: (result, error, { takerDocumentUploadId, contentType }) => {
                let tags: any = [
                    { type: 'LatestTakerDocumentUploadData', id: `${takerDocumentUploadId}-${contentType}` },
                ]; 

                if (contentType === 'KEY_TERMS') {
                    tags.push({ type: 'LatestKeyTermsGroupData', id: `${takerDocumentUploadId}` });
                }
                return tags;
            },        
        }),
        updateTakerDocumentUploadData: build.mutation<TakerDocumentUploadData, Partial<TakerDocumentUploadData> & { takerId: string, takerDocumentId: string; }>({
            query(data) {
                const { id, content } = data;
                return {
                    url: `taker_document_upload_data/${id}`,
                    method: 'PUT',
                    data: { content: content }
                }
            },
            invalidatesTags: (result, error, { takerDocumentUploadId, contentType }) => [
                { type: 'LatestTakerDocumentUploadData', id: `${takerDocumentUploadId}-${contentType}` }
            ],
        }),
        deleteTakerDocumentUploadData: build.mutation<TakerDocumentUploadData, Partial<TakerDocumentUploadData> & { takerId: string, takerDocumentId: string; }>({
            query(data) {
                const { id } = data;
                return {
                    url: `taker_document_upload_data/${id}`,
                    method: 'DELETE'
                }
            },
            invalidatesTags: (result, error, { takerDocumentUploadId, contentType }) => [
                { type: 'LatestTakerDocumentUploadData', id: `${takerDocumentUploadId}-${contentType}` }
            ],
        }),
        getLatestKeyTermGroupData: build.query<TakerDocumentUploadData, string>({
            query(takerDocumentUploadId) {
                return {
                    url: `taker_document_upload_data/latest?taker_document_upload_id=${takerDocumentUploadId}&content_type=KEY_TERMS`,
                    method: "GET"
                };
            },
            providesTags: (result, error, takerDocumentUploadId) => [
                { type: 'LatestKeyTermsGroupData', id: takerDocumentUploadId }
            ]
        }),
        updateKeyTermGroupData: build.mutation<TakerDocumentUploadData, Partial<TakerDocumentUploadData> & { takerId: string, takerDocumentId: string; }>({
            query(data) {
                const { id, content } = data;
                return {
                    url: `taker_document_upload_data/${id}`,
                    method: 'PUT',
                    data: { content: content }
                }
            },
            invalidatesTags: (result, error, { takerDocumentUploadId }) => [
                { type: 'LatestKeyTermsGroupData', id: takerDocumentUploadId }
            ],
        }),
        updateKeyTermGroupDataDiff: build.mutation<TakerDocumentUploadData, { id: string, mutations: any[] }>({
            query(data) {
                const { id, mutations } = data;
                return {
                    url: `taker_document_upload_data/${id}/diff`,
                    method: 'PUT',
                    data: { mutations }
                }
            },
            // TODO: add invalidatesTags at a different interval
            // invalidatesTags: (result, error, { }) => [
            // ],
        }),
        getTakerDocumentStateV2: build.query<any, { id: string, enableCaching: string }>({
            query: ({ id, enableCaching = 'FALSE' }) => ({
                url: `taker_documents/${id}/state_v2?enable_caching=${enableCaching}`,
                method: "GET"
            }),
            providesTags: (result, error, { id }) => [{ type: 'TakerDocumentFulfillmentData', id }],
        }),
        updateTakerDocumentQuestionnaire: build.mutation<TakerDocumentData, Partial<TakerDocumentData> & { takerId: string }>({
            query(data) {
                const { id, content } = data;
                return {
                    url: `taker_document_data/${id}`,
                    method: 'PUT',
                    data: { content: content }
                }
            },
            invalidatesTags: (result, error, { takerDocumentId}) => [
                { type: 'TakerDocumentFulfillmentData', id: takerDocumentId }
            ],
            // TODO: add more invalidatesTags at a different interval
            // invalidatesTags: (result, error, { }) => [
            // ],
        })
    })
})

export const {
    useGetLatestTakerDocumentDataQuery,
    useGetLatestTakerDocumentUploadDataQuery,
    useLazyGetLatestTakerDocumentUploadDataQuery,
    useAddTakerDocumentDataMutation,
    useUpdateTakerDocumentDataMutation,
    useDeleteTakerDocumentDataMutation,
    useAddTakerDocumentUploadDataMutation,
    useUpdateTakerDocumentUploadDataMutation,
    useDeleteTakerDocumentUploadDataMutation,
    useGetLatestKeyTermGroupDataQuery,
    useUpdateKeyTermGroupDataMutation,
    useUpdateKeyTermGroupDataDiffMutation,
    useGetTakerDocumentStateV2Query,
    useUpdateTakerDocumentQuestionnaireMutation
} = takerDataApi;