import {
    Box,
    Chip,
    IconButton,
    Typography
} from "@mui/material/";
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Edit, FindInPage, Grading, MoreHoriz } from "@mui/icons-material";

export interface TreeItemClickPayload {
    targetEntry: any;
    targetEntryIsFirst: boolean;
    targetEntryIsLast: boolean;
    targetEntryIsTopLevel: boolean;
    targetEntryHasNoSiblings: boolean;
}

export const renderSubTrees = (
    depth: number,
    indexEntries: any[],
    onClickMore: (e: HTMLButtonElement, d: TreeItemClickPayload) => void,
    onClickEdit: (d: TreeItemClickPayload) => void,
    onClickReview: (d: TreeItemClickPayload) => void,
    onClickFindInPage: (d: TreeItemClickPayload) => void
) => {
    const treeItems = []
    for (let i = 0; i < indexEntries.length; i++) {
        const indexEntry = indexEntries[i];
        const isFirst = (i === 0);
        const isLast = (i === indexEntries.length - 1);
        const isTopLevel = (depth === 0);
        const hasNoSiblings = (indexEntries.length === 1);
        const treeClickPayload: TreeItemClickPayload = {
            targetEntry: indexEntry,
            targetEntryIsFirst: isFirst,
            targetEntryIsLast: isLast,
            targetEntryIsTopLevel: isTopLevel,
            targetEntryHasNoSiblings: hasNoSiblings
        };

        if (indexEntry['nested'] && indexEntry['nested'].length > 0) {
            treeItems.push(
                <TreeItem
                    itemId={indexEntry['id']}
                    label={
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            sx={{
                                p: 0.5,
                                pr: 0,
                            }}
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                            >
                                <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                                    {indexEntry['label']}
                                    {!(indexEntry['label_tag_ids'] && indexEntry['label_tag_ids'].length > 0) && (
                                        <Chip
                                            size="small"
                                            color="warning"
                                            label="tag label"
                                            sx={{ marginLeft: "2px" }}
                                        />
                                    )}
                                </Typography>
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onClickFindInPage(treeClickPayload);
                                    }}
                                    size="small"
                                >
                                    <FindInPage />
                                </IconButton>
                            </Box>
                            <IconButton
                                onClick={(e) => {
                                    onClickMore(e.currentTarget, treeClickPayload);
                                    e.stopPropagation();
                                }}
                                size="small"
                            >
                                <MoreHoriz />
                            </IconButton>
                        </Box>
                    }
                >
                    <>
                        {renderSubTrees(depth + 1, indexEntry['nested'], onClickMore, onClickEdit, onClickReview, onClickFindInPage)}
                    </>
                </TreeItem>
            );
        } else {
            treeItems.push(
                <TreeItem
                    itemId={indexEntry['id']}
                    label={
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: "space-between",
                                p: 0.5,
                                pr: 0,
                            }}
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                            >
                                <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                                    {indexEntry['label']}
                                    {!(indexEntry['label_tag_ids'] && indexEntry['label_tag_ids'].length > 0) && (
                                        <Chip
                                            size="small"
                                            color="warning"
                                            label="tag label"
                                            sx={{ marginLeft: "2px" }}
                                        />
                                    )}
                                    {indexEntry['hasContent'] && (
                                        <>
                                            <Chip
                                                size="small"
                                                label="content"
                                                sx={{ marginLeft: "2px" }}
                                            />
                                            {!(indexEntry['content_tag_ids'] && indexEntry['content_tag_ids'].length > 0) && (
                                                <Chip
                                                    size="small"
                                                    color="warning"
                                                    label="tag content"
                                                    sx={{ marginLeft: "2px" }}
                                                />
                                            )}
                                        </>
                                    )}
                                </Typography>
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onClickFindInPage(treeClickPayload);
                                    }}
                                    size="small"
                                >
                                    <FindInPage />
                                </IconButton>
                                {indexEntry['hasContent'] && (
                                    <>
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onClickEdit(treeClickPayload);
                                            }}
                                            size="small"
                                        >
                                            <Edit />
                                        </IconButton>
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onClickReview(treeClickPayload);
                                            }}
                                            size="small"
                                            color={!!indexEntry['reviewed'] ? "success" : "error"}
                                        >
                                            <Grading />
                                        </IconButton>
                                    </>
                                )}
                            </Box>
                            <IconButton
                                onClick={(e) => {
                                    onClickMore(e.currentTarget, treeClickPayload);
                                    e.stopPropagation();
                                }}
                                size="small"
                            >
                                <MoreHoriz />
                            </IconButton>
                        </Box>
                    }
                />
            );
        }
    }
    return treeItems;
}