import React, { useEffect, useState, useCallback, memo, useMemo } from 'react';
import { Checkbox, ListItemText, List, ListItem, Divider, ListItemButton, ListItemIcon } from '@mui/material';
import { Article, Key } from '@mui/icons-material';
import { useCounter } from '@uidotdev/usehooks';
import { useTakerState } from '../../../../containers/TakerDocumentState/TakerDocumentState';
import { Task, useResearchPanelData } from '../../../../containers/ResearchPanelData/ResearchPanelData';

export type Filter = {
    type: "DOCUMENT" | "INDIVIDUAL_KEY_TERM";
    value: string;
    label: any;
    enabled: boolean;
    icon?: React.ReactNode;
    hasDivider?: boolean;
};

export interface SourceMaterialFiltersProps {
    defaultDocumentIdFilter?: string[];
    defaultTargetKeyTermIdFilter?: string[];
    selectedTask: Task;
    onChangeEnabledFilters: (filters: Filter[]) => void;
};

const SourceMaterialFilters = ({
    defaultDocumentIdFilter,
    defaultTargetKeyTermIdFilter,
    selectedTask,
    onChangeEnabledFilters
}: SourceMaterialFiltersProps) => {
    const { documentKeyTermHolders } = useResearchPanelData();
    const { takerDocumentUploads } = useTakerState();
    const [availableFilters, setAvailableFilters] = useState<Filter[]>([]);
    const [savedFilters, setSavedFilters] = useState<Record<string, boolean>>({});
    const [count, countMutator] = useCounter(0);

    const enableDocumentSelection = useMemo(() => {
        return selectedTask.transformType === "TXN_SUMMARY_OVERALL"
            || selectedTask.transformType === "TXN_SUMMARY_BY_GROUP"
            || selectedTask.transformType === "TXN_SUMMARY_BY_DOCUMENTS"
            || selectedTask.transformType === "DOCUMENT_QA_HIGH_LEVEL_ACCOUNTING_ISSUES"
            || selectedTask.transformType === "DOCUMENT_QA_CONTRACT_CONTRADICTIONS"
            || selectedTask.transformType === "DOCUMENT_QA_IRRELEVANT_INFO";
    }, [selectedTask]);

    const enableIndividualKeyTermsSelection = useMemo(() => {
        return selectedTask.transformType === "INDIVIDUAL_KEY_TERMS_LEFT_OUT_DETAILS";
    }, [selectedTask]);

    useEffect(() => {
        const newFilters: string[] = [];
        if (defaultDocumentIdFilter) {
            for (const docId of defaultDocumentIdFilter) {
                let key = `DOCUMENT-${docId}`;
                if (savedFilters[key]) {
                    continue;
                }
                newFilters.push(key);
            }
        }
        if (defaultTargetKeyTermIdFilter) {
            for (const keyTermId of defaultTargetKeyTermIdFilter) {
                let key = `INDIVIDUAL_KEY_TERM-${keyTermId}`;
                if (savedFilters[key]) {
                    continue;
                }
                newFilters.push(key);
            }
        }

        if (newFilters.length > 0) {
            setSavedFilters((prev) => {
                let newSavedFilters = { ...prev };
                for (const key of newFilters) {
                    newSavedFilters[key] = true;
                }
                return newSavedFilters;
            });
            countMutator.increment();
        }
    }, [
        defaultDocumentIdFilter,
        defaultTargetKeyTermIdFilter,
    ]);

    const applySavedFilterOrDefault = useCallback((filter: Partial<Filter>, defaultEnabled: boolean) => {
        const key = `${filter.type}-${filter.value}`;
        filter.enabled = savedFilters[key] ?? defaultEnabled;
        return filter as Filter;
    }, [savedFilters]);

    // All available filters.
    useEffect(() => {
        const filters: Filter[] = [];

        if (takerDocumentUploads) {
            for (let i = 0; i < takerDocumentUploads.length; i++) {
                const takerDocumentUpload = takerDocumentUploads[i];
                if (enableIndividualKeyTermsSelection) {
                    let holder = documentKeyTermHolders[takerDocumentUpload.id];
                    if (!holder) {
                        continue;
                    }

                    for (const kt of holder.documentKeyTerms.keyTerms) {
                        if (kt.termName && kt.identifier) {
                            const individualKeyTermFilter = applySavedFilterOrDefault(
                                {
                                    type: "INDIVIDUAL_KEY_TERM",
                                    value: kt.identifier,
                                    label: `${takerDocumentUpload.name} - ${kt.termName}`,
                                    icon: <Key fontSize="small" />
                                },
                                false
                            )
                            filters.push(individualKeyTermFilter);
                        }
                    }
                }

                if (enableDocumentSelection) {
                    for (const fileItem of takerDocumentUpload.fileUpload.fileUploadItems) {
                        if (fileItem.state !== "ARCHIVED") {
                            const documentFilter = applySavedFilterOrDefault(
                                {
                                    type: "DOCUMENT",
                                    value: fileItem.id,
                                    label: `${takerDocumentUpload.name} - ${fileItem.label}`,
                                    icon: <Article fontSize="small" />
                                },
                                true
                            )
                            filters.push(documentFilter);
                        }
                    }
                }

                if (i < takerDocumentUploads.length - 1) {
                    filters[filters.length - 1].hasDivider = true;
                }
            }
        }

        if (filters.length > 0) {
            filters[filters.length - 1].hasDivider = true;
        }
        setAvailableFilters(filters);
    }, [
        enableDocumentSelection,
        enableIndividualKeyTermsSelection,
        takerDocumentUploads,
        count,
        documentKeyTermHolders
    ]);

    // Relay enabled filters to parent
    useEffect(() => {
        onChangeEnabledFilters(availableFilters.filter(f => f.enabled));
    }, [availableFilters]);

    return (
        <>
            <List
                sx={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    overflow: 'auto',
                    '& ul': { padding: 0 },
                    backgroundColor: "white"
                }}
                disablePadding
            >
                {availableFilters.map((f, filterIndex) => (
                    <>
                        <ListItem
                            key={filterIndex}
                            secondaryAction={f.icon}
                            disablePadding
                        >
                            <ListItemButton
                                role={undefined}
                                dense
                                onClick={() => {
                                    let newSavedFilters = { ...savedFilters };
                                    newSavedFilters[`${f.type}-${f.value}`] = !f.enabled;
                                    setSavedFilters(newSavedFilters);
                                    countMutator.increment();
                                }}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        tabIndex={-1}
                                        disableRipple
                                        checked={f.enabled}
                                        inputProps={{
                                            'aria-labelledby': `checkbox-list-label-${f.value}`
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={f.value} primary={f.label} />
                            </ListItemButton>
                        </ListItem>
                        {f.hasDivider && <Divider component="li" />}
                    </>
                ))}
            </List>
        </>
    );
}

export default memo(SourceMaterialFilters);