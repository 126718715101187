import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import DefaultLoadingScreen from "../loading/defaultLoadingScreen";
import { RootReducerType } from "../../redux/models/reduxTypes";
import { pageOption } from "./../../data/pages";
import UserScopedAppDataContainer from "../../containers/UserScopedAppData/UserScopedAppData";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { User } from "../../redux/models/dataModelTypes";
import { store } from "../../redux/store";
import { authAction } from "../../redux/actions/authActions";


interface RoleGatedRoutesProps {
  roles?: Array<string>;
}

const GatedRoutes = ({ roles }: RoleGatedRoutesProps) => {
  const {
    user,
    loaded
  } = useSelector((state: RootReducerType) => state.auth);
  const { 
    isAuthenticated: isAuth0Authenticated, 
    isLoading: isAuth0Loading, 
    getAccessTokenSilently 
  } = useAuth0();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const token = await getAccessTokenSilently();
      let response;
      try {
        response = await axios.post(`${window.__RUNTIME_CONFIG__.API_ENDPOINT}/current_user_auth0`, {}, {
          headers: { 'Auth0-Authorization': `Bearer ${token}` },
          withCredentials: true
        });
      } catch (error) {
        // either an auth error or the user doesn't exist
        // either way it requires user interation
        navigate("/authError");
      }

      // we got some user data an can now update the store
      if (response) {
        const userData = response?.data as User;
        store.dispatch({
          type: authAction.SET_USER_SUCESS,
          payload: userData
        });

        // shortcut for the INDEXER role if we are trying to go home.
        if (userData.roles.length === 1 
            && userData.roles.includes("INDEXER")
            && location.pathname === pageOption.HOME.route) {
          navigate(pageOption.INDEXED_GUIDANCE_LIST.route);
        }
      }
    }

    // We either need to redirect to the login page here or fetch some
    // user data for this route to load.

    // The first case is that we are not authenticated and auth0 is finished
    // loading.
    if (!isAuth0Loading && !isAuth0Authenticated) {
      navigate(`${pageOption.AUTH0_LOGIN.route}?redirect=${location.pathname}`);
    }

    // The other case is that we are already authenticated.
    else if (isAuth0Authenticated) {
      fetchCurrentUser();
    }
  }, [
    isAuth0Loading,
    isAuth0Authenticated
  ]);

  if (!user || !loaded) {
    return <DefaultLoadingScreen />
  }

  const shouldAllow = (!roles || user.roles.some((r: string) => roles.includes(r)));
  return shouldAllow ? (
    <UserScopedAppDataContainer>
      <Outlet />
    </UserScopedAppDataContainer>
  ) : (
    <Navigate to={pageOption.ERROR.route} />
  );
};

export default GatedRoutes;

