import React, { useEffect, useMemo, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from "@lexical/list";
import ForceUpdateEditorStatePlugin from '../../plugins/ForceUpdateEditorStatePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import EditorThemeClasses from "./theme";
import { text } from 'node:stream/consumers';

// Types for clarity
interface CheckWordsPluginProps {
    expectedWords: string[];
}

interface WordInfo {
    word: string;
    nodePath: number[]; // path to the text node in the JSON tree
    indexInNodeText: number; // the order of this word in that node's text
}

// A type for the final sequence after diff: word plus optional style
interface DiffWord {
    actual: boolean;
    word: string;
    style?: string;

    // You might also want flags to indicate if it's missing or extra, but style is enough here
}

function tokenize(str: string): string[] {
    // This regex matches:
    // - sequences of word characters (a-z, A-Z, 0-9, and _)
    // - or any single character that is not a word character and not whitespace (often punctuation)
    const tokens = str.match(/\w+|[^\w\s]/g);
    return tokens || [];
}

/**
 * Extract words and their locations from the Lexical JSON.
 * nodePath is used to identify where each text node is located in the JSON tree.
 */
function extractWordsWithLocation(
    node: any,
    path: number[] = []
): WordInfo[] {
    const results: WordInfo[] = [];

    if (!node || !node.children) {
        return results;
    }

    node.children.forEach((child: any, index: number) => {
        const childPath = [...path, index];
        if (child.type === 'text') {
            // Extract words from this text node
            const text = child.text || '';
            const words = text.trim().length > 0 ? tokenize(text) : [];
            // Record each word with its position
            let wordIndex = 0;
            for (const w of words) {
                results.push({ 
                    word: w,
                    nodePath: childPath, 
                    indexInNodeText: wordIndex++ 
                });
            }
        } else {
            // Recurse into non-text nodes
            results.push(...extractWordsWithLocation(child, childPath));
        }
    });

    return results;
}

/**
 * Perform a diff-like operation to determine which words are correct, extra, or missing.
 * This simple approach:
 *  - Align words in order,
 *  - If matches, correct.
 *  - If differs, mark actual as extra and expected as missing.
 * A more sophisticated algorithm may be needed for complex scenarios.
 */
function diffWords(actualWords: WordInfo[], expectedWords: string[]): DiffWord[] {
    const result: DiffWord[] = [];
    let i = 0; // expectedWords index
    let j = 0; // actualWords index

    while (i < expectedWords.length || j < actualWords.length) {
        if (i < expectedWords.length && j < actualWords.length) {
            if (expectedWords[i] === actualWords[j].word) {
                // correct
                result.push({ 
                    actual: true, 
                    word: actualWords[j].word 
                });
                i++;
                j++;
            } else {
                // found a mismatch: 
                // at least one expected word is missing
                // actual word might be extra or just further along in the list
                let nextOccurrenceIndex = expectedWords.findIndex((w, wi) => wi > i && w === actualWords[j].word);
                //console.log(expectedWords[i], actualWords[j], nextOccurrenceIndex);
                if (nextOccurrenceIndex === -1) {
                    // the actual word is extra, don't advance the expectedWords index because it's missing.
                    result.push({
                        actual: true,
                        word: actualWords[j].word, 
                        style: 'background-color: #ffdddd;'
                    });
                    j++;
                } else {
                    // The actual word is just further along in the list and not extra.
                    // But we are missing several expected words in-between, count these as one word for simplification.
                    let allExpected = expectedWords.slice(i, nextOccurrenceIndex).join(' ');
                    result.push({ 
                        actual: false,
                        word: allExpected, 
                        style: 'background-color: #ddffdd;'
                    });
                    i = nextOccurrenceIndex + 1;
                    
                    result.push({
                        actual: true,
                        word: actualWords[j].word, 
                    });
                    j++;
                }
            }
        } else if (i < expectedWords.length) {
            // missing words
            result.push({ 
                actual: false,
                word: expectedWords[i], 
                style: 'background-color: #ddffdd;' 
            });
            i++;
        } else {
            // extra words
            result.push({ 
                actual: true,
                word: actualWords[j].word, 
                style: 'background-color: #ffdddd;' 
            });
            j++;
        }
    }

    return result;
}

function getTextWithSpaces (diffWord: DiffWord, i: number, allDiffWords: DiffWord[]): string {
    if (i === allDiffWords.length - 1) {
        return diffWord.word + ' ';
    }

    let nextWord = allDiffWords[i + 1];
    if (nextWord.word === '.' 
        || nextWord.word === ',' 
        || nextWord.word === ';' 
        || nextWord.word === ':'
        || nextWord.word === '!'
        || nextWord.word === '?'
        || nextWord.word === '“'
        || nextWord.word === '"'
        || nextWord.word === "'"
        || nextWord.word === ')'
        || nextWord.word === ']') {
        return diffWord.word;
    }

    if (diffWord.word === '“'
        || diffWord.word === '"'
        || diffWord.word === "'") {
        return diffWord.word;
    }

    return diffWord.word + ' ';
};

/**
 * Given the final diff result (DiffWord[]) and the original JSON structure,
 * rebuild the JSON by distributing the diff words back into the text nodes.
 *
 * We'll need to:
 * - Track how many words a given text node originally contributed.
 * - Replace that text node's `text` with the corresponding words from the diff array.
 * - If the number of words differs, we either add or remove text nodes as needed.
 */
function rebuildJSONWithDiff(originalJson: any, diffWords: DiffWord[]): any {
    // We'll walk the tree again and whenever we find a text node,
    // we consume as many words from diffWords as that text node originally contributed.
    // But because missing/extra words can change the count, we must adapt:
    // Actually, we will consume words from diffWords linearly in the same order we encountered them originally.

    // To do that, first we need a linear list of original node references:
    const textNodeInfos = collectTextNodesInfo(originalJson);

    // Now textNodeInfos is an array like:
    // [{nodePath: [..], originalWordsCount: X}, ...]
    // Each corresponds to one original text node.
    // We'll reconstruct these text nodes from the next X words in diffWords, but X might differ now.

    // Actually, since we now have a new distribution of words, we must decide how to break them into nodes.
    // For simplicity, each original text node will be replaced with one or more text nodes, one per word (plus spaces).
    // If fewer words are available now, we get fewer text nodes.
    // If more words are available (due to inserted words), we create extra text nodes in the same parent.
    // This preserves structure but may change how many text nodes appear at that position.

    let diffIndex = 0;

    // We'll create a function that rebuilds the node recursively,
    // replacing text nodes with their corresponding diff words:
    function rebuildNode(node: any): any {
        if (!node.children || !Array.isArray(node.children)) {
            return node;
        }

        const newChildren = node.children.map((child: any) => {
            if (child.type === 'text') {
                // This node originally had some words (let's find how many by re-splitting)
                const wordsInThisNode = child.text.trim().length > 0 ? tokenize(child.text) : [];
                const wordCount = wordsInThisNode.length;

                // Consume 'wordCount' words from diffWords or if we don't have enough,
                // just consume as many as we have left. If we have more (missing inserted),
                // we also consume them, assigning them to this node.
                // const assignedWords: DiffWord[] = [];
                // for (let k = 0; k < wordCount && diffIndex < diffWords.length; k++) {
                //     assignedWords.push(diffWords[diffIndex++]);
                // }

                // Consume as many words from diffWords to where we have consumed all of the original words.
                // As a side effect, this will also consume any inserted words that fall into this node that are
                // expected.
                const assignedWords: DiffWord[] = [];
                let actualWordsLeftToConsume = wordCount;
                while (actualWordsLeftToConsume > 0 && diffIndex < diffWords.length) {
                    // Consume a new word from diffWords
                    const diffWord = diffWords[diffIndex++];
                    if (diffWord.actual) {
                        actualWordsLeftToConsume --;
                    }
                    assignedWords.push(diffWord);
                }

                // If we still have diffWords left that need to be inserted at this position
                // (for example, missing words that didn't appear in the original),
                // we can assign them as well. This might deviate from the original node count,
                // but we'll just append them to this node for demonstration purposes.
                // A more sophisticated approach might try to keep the exact structure.
                // For simplicity, let's just consume exactly wordCount words here, no more, no less.
                // Missing words that fall beyond this node should appear in subsequent nodes.

                // If there are fewer words than original, we end up with a shorter sequence.
                // If no words assigned, we must at least create an empty text node to avoid empty state errors.
                if (assignedWords.length === 0) {
                    // create an empty text node
                    return {
                        detail: 0,
                        format: 0,
                        mode: 'normal',
                        style: '',
                        text: '',
                        type: 'text',
                        version: 1
                    };
                } else {
                    // Merge assigned words into a single text with spaces
                    // Since we want to preserve styling per word, we actually need multiple text nodes:
                    const textNodes = assignedWords.map((dw, i) => {
                        return {
                            detail: 0,
                            format: 0,
                            mode: 'normal',
                            style: dw.style || '',
                            text: getTextWithSpaces(dw, i, assignedWords), // add space after each word for styling per word, not per text node. See below.
                            type: 'text',
                            version: 1
                        };
                    });

                    // If we want them as a single text node, we'd lose separate styling unless we rely on single style per node.
                    // The specification allows multiple text nodes, so we can just return a list of them.
                    // But original structure expects a single node for child? Actually Lexical JSON supports multiple children arrays.
                    // We'll return multiple text nodes. That means at a higher level, we must handle children arrays with multiple nodes.

                    // However, the original node might have been a single text node. Returning multiple text nodes here is allowed 
                    // as long as the parent node's children array can have multiple text nodes. That's valid Lexical JSON.
                    // So we can just replace this single text node with multiple if needed.

                    // Return them as an array to be handled by the caller. Wait, the caller expects a single node.
                    // Instead, we must handle this case at the parent level. Let's store a marker and handle merging after the map.

                    return textNodes;
                }
            } else {
                // Non-text node: recurse
                const rebuilt = rebuildNode(child);
                return rebuilt;
            }
        });

        // Now, newChildren might contain arrays of text nodes where we had one text node.
        // Flatten them:
        const flattenedChildren = newChildren.reduce((acc: any[], c: any) => {
            if (Array.isArray(c)) {
                // Multiple text nodes replaced a single node
                return acc.concat(c);
            } else {
                return acc.concat(c);
            }
        }, []);

        return {
            ...node,
            children: flattenedChildren
        };
    }

    const rebuilt = rebuildNode(originalJson["root"]);

    // If there are still diffWords left (missing words not inserted), we must
    const textNodes = [];
    while (diffIndex < diffWords.length) {
        const diffWord = diffWords[diffIndex++];
        textNodes.push({
            detail: 0,
            format: 0,
            mode: 'normal',
            style: diffWord.style || '',
            text: diffWord.word + ' ', // add space after each word for styling per word, not per text node. See below.
            type: 'text',
            version: 1
        });
    } 

    if (textNodes.length > 0) {
        rebuilt.children.push({
            type: 'paragraph',
            version: 1,
            indent: 0,
            direction: 'ltr',
            format: '',
            children: textNodes
        });
    }

    return { root: rebuilt };
}

function collectTextNodesInfo(node: any, path: number[] = []): { nodePath: number[], wordCount: number }[] {
    const results: { nodePath: number[], wordCount: number }[] = [];
    if (!node || !node.children) {
        return results;
    }
    node.children.forEach((child: any, index: number) => {
        const childPath = [...path, index];
        if (child.type === 'text') {
            const words = child.text.trim().length > 0 ? tokenize(child.text) : [];
            results.push({ nodePath: childPath, wordCount: words.length });
        } else {
            results.push(...collectTextNodesInfo(child, childPath));
        }
    });
    return results;
}

export function CheckWordsPlugin({ expectedWords }: CheckWordsPluginProps) {
    const [mainEditor] = useLexicalComposerContext();
    const [enhancedEditorStateJSON, setEnhancedEditorStateJSON] = useState<string | null>(null);

    const trimmedExpectedWords = useMemo(
        () => {
            const allTokenized = [];
            for (const words of expectedWords) {
                allTokenized.push(...tokenize(words));
            }
            return allTokenized;
        },
        [expectedWords]
    );

    const doComputeEnhancedEditorStateJSON = () => {
        // Convert current state to JSON
        const originalJSON = mainEditor.getEditorState().toJSON();

        // Extract actual words with their locations
        const actualWordsWithLoc = extractWordsWithLocation(originalJSON["root"]);

        // Compute diff
        const diff = diffWords(actualWordsWithLoc, trimmedExpectedWords);

        // Rebuild JSON with diff
        const rebuiltJSON = rebuildJSONWithDiff(originalJSON, diff);

        // Ensure at least one paragraph if empty
        if (rebuiltJSON.root && (!rebuiltJSON.root.children || rebuiltJSON.root.children.length === 0)) {
            rebuiltJSON.root.children = [
                {
                    type: 'paragraph',
                    version: 1,
                    indent: 0,
                    direction: 'ltr',
                    format: '',
                    children: [
                        {
                            type: 'text',
                            version: 1,
                            detail: 0,
                            format: 0,
                            mode: 'normal',
                            style: '',
                            text: ''
                        }
                    ]
                }
            ];
        }

        setEnhancedEditorStateJSON(JSON.stringify(rebuiltJSON));
    };

    useEffect(() => {
        return mainEditor.registerTextContentListener(() => {
            doComputeEnhancedEditorStateJSON();
        });
    }, [
        mainEditor, 
        trimmedExpectedWords
    ]);

    useEffect(() => {
        doComputeEnhancedEditorStateJSON();
    }, [trimmedExpectedWords]);

    return (
        <div style={{ marginBottom: '1em', border: '1px solid #ccc' }}>
            {enhancedEditorStateJSON ? (
                <LexicalComposer initialConfig={{
                    namespace: 'enhanced-check',
                    editable: false, // read-only enhanced view
                    theme: EditorThemeClasses,
                    onError(error: Error) {
                        console.error(error);
                    },
                    editorState: enhancedEditorStateJSON || undefined,
                    nodes: [
                        ListNode,
                        ListItemNode,
                        TableNode,
                        TableCellNode,
                        TableRowNode,
                    ],
                }}>
                    <ForceUpdateEditorStatePlugin newEditorState={enhancedEditorStateJSON}/>
                    <RichTextPlugin
                        contentEditable={
                        <div className="editor-scroller">
                            <div className="editor">
                            <ContentEditable className="editor-input" />
                            </div>
                        </div>
                        }
                        placeholder={null}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                </LexicalComposer>
            ) : (
                <div>Loading enhanced view...</div>
            )}
        </div>
    );
}

export default CheckWordsPlugin;