import React, { Suspense, useState } from 'react';
import { Box, Typography, IconButton, Tooltip } from "@mui/material";
import MiniToolbarWithShadow from "../../../components/navigation/Toolbars/MiniToolbarWithShadow";
import { ZoomIn, ZoomOut, ZoomOutMap, Menu } from "@mui/icons-material";
import { APP_BAR_HEIGHT } from '../../navigation/SideNav';
import { useResearchPanelData } from '../../../containers/ResearchPanelData/ResearchPanelData';
import GuidanceOutline from './GuidanceOutline';
import RectangularSkeleton from '../../loading/RectangularSkeleton';
import GuidanceSearch from './GuidanceSearch';

const MIN_ZOOM_LEVEL = 0.97;
const MAX_ZOOM_LEVEL = 2.97;

const GuidancePdfViewLazy = React.lazy(() => import('./GuidancePdfView'));
const GuidanceLexicalViewLazy = React.lazy(() => import('./GuidanceLexicalView'));

const GuidanceMain = () => {
    const { 
        setGuidanceOutlineOpen,
        targetDisplayMode,
        guidanceOutlineOpen
    } = useResearchPanelData();
    const [zoomLevel, setZoomLevel] = useState<number>(0.97);

    const drawerContainerStyle: React.CSSProperties = {
        height: `calc(100vh - ${2 * APP_BAR_HEIGHT}px)`,
        width: '100%',
        overflow: 'hidden',
    }

    const otherChildrenStyle: React.CSSProperties = {
        marginTop: `${3 * APP_BAR_HEIGHT}px`,
        height: `calc(100vh - ${3 * APP_BAR_HEIGHT}px)`,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
    };

    if (guidanceOutlineOpen) {
        otherChildrenStyle.width = `calc(50vw - 200px)`;
        otherChildrenStyle.left = '200px';
    } else {
        otherChildrenStyle.width = '50vw';
    }

    return (
        <>
            <MiniToolbarWithShadow
                variant="dense"
                disableGutters
                data-testid="guidance-toolbar"
                sx={{
                    paddingLeft: 1,
                    paddingRight: 1,
                    zIndex: 10001,
                    height: "48px",
                    width: "100%",
                    display: "inline-grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                }}
            >
                <Box padding={1}>
                    <Tooltip title="Outline">
                        <IconButton
                            onClick={() => setGuidanceOutlineOpen(prev => !prev)}
                        >
                            <Menu />
                        </IconButton>
                    </Tooltip>
                </Box>
                <GuidanceSearch />
                <Box 
                    display="flex" 
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Tooltip title="Zoom in">
                        <IconButton
                            data-testid="zoom-in-button"
                            disabled={targetDisplayMode !== "PDF"}
                            onClick={() => setZoomLevel(Math.min((zoomLevel ?? MIN_ZOOM_LEVEL) + 0.25, MAX_ZOOM_LEVEL))}
                        >
                            <ZoomIn />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Zoom out">
                        <IconButton
                            data-testid="zoom-out-button"
                            disabled={targetDisplayMode !== "PDF"}
                            onClick={() => setZoomLevel(Math.max((zoomLevel ?? MIN_ZOOM_LEVEL) - 0.25, MIN_ZOOM_LEVEL))}
                            sx={{ marginLeft: "5px" }}
                        >
                            <ZoomOut />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Reset zoom">
                        <IconButton
                            data-testid="zoom-out-map"
                            disabled={targetDisplayMode !== "PDF"}
                            onClick={() => { setZoomLevel(MIN_ZOOM_LEVEL) }}
                            sx={{ marginLeft: "5px" }}
                        >
                            <ZoomOutMap />
                        </IconButton>
                    </Tooltip>
                </Box>
            </MiniToolbarWithShadow>
            <Box sx={drawerContainerStyle}>
                <GuidanceOutline />
                <Box style={otherChildrenStyle}>
                    {targetDisplayMode === "NONE" ? (
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height="100%"
                            width="100%"
                        >
                            <Typography variant="h6">Select Literature</Typography>
                        </Box>
                    ) : (
                        <Suspense fallback={<RectangularSkeleton />}>
                            {targetDisplayMode === "PDF" && (
                                <GuidancePdfViewLazy
                                    zoomLevel={zoomLevel} 
                                />
                            )}
                            {(targetDisplayMode === "LEXICAL") && (
                                <GuidanceLexicalViewLazy />
                            )}
                        </Suspense>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default GuidanceMain;
