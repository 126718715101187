import React, { useMemo } from "react";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { useTakerState } from "../../containers/TakerDocumentState/TakerDocumentState";
import ReportEditor from "./ReportEditor";
import { useReportState } from "../../containers/TakerDocumentState/ReportState";
import { useAddTakerDocumentDataMutation } from "../../redux/services/takerData";
import { ReportRevision, ReportStateData } from "../../types/taker/reportstate.generated";
import DispatchReportGenerationPlugin from "./ReportEditor/DispatchReportGenerationPlugin";
import { useSnackbar } from "notistack";

const DisplayReport = () => {
    const {
        latestMemoGenAnalyses,
        latestReportDataId,
        latestReportRevision,
        isReportUninitialized
    } = useReportState();
    const {
        taker,
        takerDocumentId,
        isTakerDocumentUninitialized,
        takerPermissionState,
        fulfillmentStateHolder
    } = useTakerState();

    const { enqueueSnackbar} = useSnackbar();

    const [addTakerDocumentData, addTakerDocumentDataRes] = useAddTakerDocumentDataMutation();

    const isReadOnly = useMemo(() =>
        takerPermissionState.isRead && !takerPermissionState.isReadWrite,
        [takerPermissionState]
    );

    const latestMemoGenAnalysis = useMemo(() => {
        if (latestMemoGenAnalyses) {
            return latestMemoGenAnalyses[0];
        }
    }, [latestMemoGenAnalyses]);

    const syncedMemoGenAnalysis = useMemo(() => {
        if (latestMemoGenAnalyses && latestReportRevision) {
            for (const memoGenAnalysis of latestMemoGenAnalyses) {
                if (memoGenAnalysis.id === latestReportRevision.syncedWithGenerationId) {
                    return memoGenAnalysis;
                }
            }
        }
    }, [latestMemoGenAnalyses, latestReportRevision]);

    const isLatestComplete = useMemo(() => {
        if (latestMemoGenAnalysis) {
            return latestMemoGenAnalysis.state === "PENDING_REVIEW"
                || latestMemoGenAnalysis.state === "APPROVED";
        }
        return false;
    }, [latestMemoGenAnalysis?.state]);

    const isLatestCreated = useMemo(() => {
        if (latestMemoGenAnalysis) {
            return latestMemoGenAnalysis.state === "CREATED";
        }
        return false;
    }, [latestMemoGenAnalysis?.state]);

    // The document is loading
    if (isTakerDocumentUninitialized) {
        throw Promise.reject("taker document still initializing");
    } else if (!latestMemoGenAnalysis) {
        return (
            <Box padding={1}>
                <em>A Report Must Be Available Before Starting</em>
            </Box>
        );
    }

    if (!latestReportDataId || !latestReportRevision || !syncedMemoGenAnalysis) {
        return (
            <Box
                height="100%"
                padding={1}
            >
                <Box
                    sx={{ backgroundColor: "white" }}
                    height="100%"
                    display="block"
                    alignItems="center"
                    alignContent="center"
                    textAlign="center"
                >
                    {(addTakerDocumentDataRes.isLoading || !isLatestComplete) ? (
                        <>
                            <DispatchReportGenerationPlugin 
                                pendingDispatch={isLatestCreated} 
                                analysis={latestMemoGenAnalysis} 
                            />
                            <CircularProgress />
                        </>
                    ) : (
                        <Button
                            disabled={isReadOnly}
                            onClick={() => {
                                if (!isReadOnly) {
                                    if (!fulfillmentStateHolder?.fulfillmentState.isFulfilled) {
                                        enqueueSnackbar("Please complete the questionnaire before starting a report");
                                        return;
                                    }

                                    const hasFatalError = !!latestMemoGenAnalysis?.data['job_failure'];
                                    if (hasFatalError) {
                                        enqueueSnackbar("Cannot start report due to a fatal error");
                                        return;
                                    }

                                    if (latestMemoGenAnalysis?.state !== "PENDING_REVIEW") {
                                        enqueueSnackbar("Report is not ready to be started");
                                        return;
                                    }


                                    if (taker && latestMemoGenAnalysis?.state === "PENDING_REVIEW") {
                                        const reportRevision: ReportRevision = {
                                            revisionId: window.crypto.randomUUID(),
                                            syncedWithGenerationId: latestMemoGenAnalysis.id,
                                            updatedAt: new Date().getTime(),
                                            reportEdits: []
                                        };
                                        addTakerDocumentData({
                                            takerId: taker.id,
                                            takerDocumentId: takerDocumentId,
                                            contentType: "REPORT",
                                            content: {
                                                reportRevisions: [reportRevision]
                                            } as ReportStateData
                                        });
                                    }
                                }
                            }}
                        >
                            Start Report
                        </Button>
                    )}
                </Box>
            </Box>
        );
    }
    return (
        <Grid
            container
            sx={{
                overflowY: "auto",
                height: "100%"
            }}
            justifyContent="center"
        >
            <div id="report-container">
                <ReportEditor
                    readOnly={isReadOnly || isReportUninitialized}
                    syncedMemoGenAnalysis={syncedMemoGenAnalysis}
                    latestMemoGenAnalysis={latestMemoGenAnalysis}
                    reportRevision={latestReportRevision}
                />
            </div>
        </Grid>
    );
};

export default DisplayReport;