import React, { Suspense, useEffect, useMemo, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Stack, ToggleButton, ToggleButtonGroup, Toolbar, Tooltip, useTheme } from "@mui/material";
import TakerToolbar from "../../components/taker/TakerToolbar/TakerToolbar";
import { useTakerState } from "../../containers/TakerDocumentState/TakerDocumentState";
import { useResearchPanelData } from "../../containers/ResearchPanelData/ResearchPanelData";
import { useNavigate } from "react-router-dom";
import { AnalysisState } from "../../containers/WidgetWrapper/states";
import WidgetWrapper, { useWidgetState } from "../../containers/WidgetWrapper/wrapper";
import AssistantIcon from '@mui/icons-material/Assistant';

import "./index.css"
import CommentsDrawer from "../../components/taker/CommentsDrawer";
import { useSideNav } from "../../components/navigation/SideNav";
import RectangularLoading from "../../components/loading/RectangularLoading";
import { ResearchPanel } from "../../components/research/ResearchPanel";

const QuestionnaireLazy = React.lazy(() => import("./Questionnaire"));
const DiagramLazy = React.lazy(() => import("./Diagram/Diagram"));

const buildInitialAnalysisState = (savedState: string | null) => {
    const gpsState: AnalysisState = (!!savedState && JSON.parse(savedState)) || {
        selectedMid: null,
        selectedIteration: null,
        controlledByQuestionnaire: true,
        collapsedState: {},
        showAnswerQuestionsDialog: false,
        panelViewMode: 0
    };
    return gpsState;
};

const AnalysisToolbar = () => {
    const { getState, mutateState } = useWidgetState()
    const [openComingSoon, setOpenComingSoon] = useState(false);

    const panelViewMode = getState<AnalysisState>().panelViewMode;

    const selectedPanels = useMemo(() => {
        if (panelViewMode === 0) {
            return [0, 1];
        } else if (panelViewMode === 1) {
            return [0];
        } else if (panelViewMode === 2) {
            return [1];
        }
    }, [panelViewMode]);

    return (
        <Box sx={{ padding: 0.5 }}>
            <Toolbar
                variant="dense"
                disableGutters
                sx={{
                    width: "100%",
                    display: "inline-grid",
                    gridTemplateColumns: "1fr 1fr",
                }}
            >
                <span></span>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Tooltip title={"Toggle Panel View"}>
                        <ToggleButtonGroup
                            size="small"
                            color="primary"
                            value={selectedPanels}
                            data-testid="toolbar-toggle-group"
                            onChange={(_, newMode) => {
                                let hasQuestionnaire = newMode.includes(0);
                                let hasGps = newMode.includes(1);
                                if (hasQuestionnaire && hasGps) {
                                    mutateState<AnalysisState>({
                                        panelViewMode: 0
                                    });
                                } else if (hasQuestionnaire) {
                                    mutateState<AnalysisState>({
                                        panelViewMode: 1
                                    });
                                } else if (hasGps) {
                                    mutateState<AnalysisState>({
                                        panelViewMode: 2
                                    });
                                }
                            }}
                        >
                            <ToggleButton
                                value={0}
                            >
                                Questionnaire
                            </ToggleButton>
                            <ToggleButton
                                value={1}
                            >
                                GPS
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Tooltip>
                </Box>
            </Toolbar>
        </Box>
    );
};

const TakerWidgets = () => {
    const { getState } = useWidgetState();

    const panelViewMode = getState<AnalysisState>().panelViewMode;

    let showQuestionnaire = panelViewMode === 0 || panelViewMode === 1;
    let showGps = panelViewMode === 0 || panelViewMode === 2;

    return (
        <Grid
            container
            height="100%"
            width="100%"
        >
            {showQuestionnaire && (
                <Grid
                    item
                    xs={showGps ? 6 : 12}
                    sx={{
                        height: '100%',
                        width: "100%"
                    }}
                >
                    <Suspense fallback={<RectangularLoading />}>
                        <QuestionnaireLazy />
                    </Suspense>
                </Grid>
            )}
            {showGps && (
                <Grid
                    item
                    xs={showQuestionnaire ? 6 : 12}
                    sx={{
                        height: '100%',
                        width: "100%"
                    }}
                    alignItems="center"
                    alignContent="center"
                >
                    <Suspense fallback={<RectangularLoading />}>
                        <DiagramLazy />
                    </Suspense>
                </Grid>
            )}
        </Grid>
    );
}

const TakerAnalysis = () => {
    const { isOpen: isSideNavOpen } = useSideNav();
    const navigate = useNavigate();
    const { taker, takerDocumentId } = useTakerState();
    const { 
        researchPanelBasicState, 
        setResearchPanelBasicState 
    } = useResearchPanelData();
    const theme = useTheme();

    const [widgetState, setWidgetState] = useState<AnalysisState>(
        buildInitialAnalysisState(localStorage.getItem(`AnalysisState-${takerDocumentId}`)),
    );

    /* Sync with localStorage */
    useEffect(() => {
        if (!!widgetState) {
            localStorage.setItem(`AnalysisState-${takerDocumentId}`, JSON.stringify(widgetState));
        }
    }, [widgetState]);

    const contentStyle: React.CSSProperties = {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    };
    if (isSideNavOpen) {
        contentStyle.width = 'calc(100vw - 200px)';
        contentStyle.transition = theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        });
    } else {
        contentStyle.width = '100vw';
        contentStyle.transition = theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        });
    }

    return (
        <Box sx={contentStyle}>
            <WidgetWrapper
                widgetState={widgetState}
                setPartialWidgetState={(s) => {
                    setWidgetState(Object.assign({}, widgetState, s));
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        alignItems: "center",
                        display: "flex",
                        paddingLeft: 1.5,
                        paddingRight: 1.5,
                        borderRadius: 1,
                        backgroundColor: "#F0F4F8",
                        borderBottom: "1px solid rgb(228, 228, 228, 0.9)"
                    }}
                >
                    <Stack width="100%">
                        {researchPanelBasicState?.isOpen && (
                            <ResearchPanel />
                        )}
                        <TakerToolbar
                            onClickBack={() => {
                                if (taker) {
                                    navigate(`/mainTaker/${taker.id}/keyTerms`);
                                }
                            }}
                            onClickForward={() => {
                                if (taker) {
                                    navigate(`/mainTaker/${taker.id}/report`);
                                }
                            }}
                            workflowDisplay={"Analysis"}
                            workflowDescription={`Please review and approve each answer and analysis.`}
                        />
                        <AnalysisToolbar />
                    </Stack>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        overflow: 'hidden'
                    }}
                    flexGrow={1}
                >
                    <CommentsDrawer commentTypes={[]} disabled>
                        <TakerWidgets />
                    </CommentsDrawer>
                </Box>
            </WidgetWrapper>
        </Box>
    );
}

export default TakerAnalysis;
