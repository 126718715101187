import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
  LexicalCommand,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
} from 'lexical';
import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { DocumentHighlight } from '../../../../types/taker/documentkeyterms.generated';
import { usePdfHighlighter } from '../../../pdfHighlighter/context';

import './index.css';

export interface AddHighlightCommandPayload {
  documentHighlights: DocumentHighlight[];
  suggestedContractTerms: string[];
};

export const INSERT_INLINE_COMMAND: LexicalCommand<AddHighlightCommandPayload> = createCommand(
  'INSERT_INLINE_COMMAND',
);

interface AddAnnotationPluginProps {
  targetFileUploadItemId: string;
  pageIndex: number;
  editorParentBoundingBox?: DOMRect;
  setNavigateHighlightState: (sticky: boolean, dh: DocumentHighlight[] | undefined) => void
}

export default function AddAnnotationPlugin({
  targetFileUploadItemId,
  pageIndex,
  editorParentBoundingBox,
  setNavigateHighlightState,
}: AddAnnotationPluginProps): JSX.Element {
  const { injectAddAnnotationWidget } = usePdfHighlighter();
  const [editor] = useLexicalComposerContext();
  const [showAddAnnotationInput, setShowAddAnnotationInput] = useState(false);
  const [currentPayload, setCurrentPayload] = useState<AddHighlightCommandPayload>();
  const [previousDocumentHighlights, setPreviousDocumentHighlights] = useState<DocumentHighlight[][]>([]);
  const [originalRangeRect, setOriginalRangeRect] = useState<DOMRect>();
  const [originalSelectionRects, setOriginalSelectionRects] = useState<ClientRect[]>();
  
  const closeAddAnnotation = useCallback(() => {
    setNavigateHighlightState(false, undefined);
    setShowAddAnnotationInput(false);
  }, [])

  const filterSelectionRangeByAncestor = useCallback(
    (range: Range, ancestor: HTMLElement) => {
      
      if (ancestor !== null) {
        let baseAncestor = range.commonAncestorContainer
        if(ancestor.contains(baseAncestor)) {
          return range;
        }

        let treeWalker = document.createTreeWalker(ancestor);
        while(treeWalker.nextNode() != null) {
          let node = treeWalker.currentNode;
          if(range.intersectsNode(node)) {
            range.setStart(node, 0);
            break;
          }
        }

        let prevNode = treeWalker.currentNode;
        while(treeWalker.nextNode() != null) {
          let node = treeWalker.currentNode;
          if(!range.intersectsNode(node) && range.intersectsNode(prevNode)) {
            range.setEnd(prevNode, 0);
            break;
          }
          prevNode = node;
        }

        if(prevNode != null && range.intersectsNode(prevNode)) {
          range.setEnd(prevNode, 0);
        }
        return range;
      }
      return range;
    },
    [editor]
  )

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        INSERT_INLINE_COMMAND,
        (payload) => {
          const domSelection = window.getSelection();
          const rootElement = editor.getRootElement();
          if (domSelection !== null && rootElement !== null) {
            let range = domSelection.getRangeAt(0);
            range = filterSelectionRangeByAncestor(range, rootElement);
            const selectionRects = Array.from(range.getClientRects());
            setOriginalRangeRect(range.getBoundingClientRect());
            setOriginalSelectionRects(selectionRects);
          }
          setPreviousDocumentHighlights([]);
          setShowAddAnnotationInput(true);
          setCurrentPayload(payload);

          setNavigateHighlightState(true, payload.documentHighlights);

          if (domSelection !== null) {
            domSelection.removeAllRanges();
          }
          return true;
        },
        COMMAND_PRIORITY_EDITOR,
      )
    );
  }, [editor]);

  const expandHighlights = useCallback(() => {
    const newDocumentHighlights = [];
    const newSelectedElements = new Set();
    const selectedHighlightType = new Set();
    if (currentPayload?.documentHighlights) {
      for (const dh of currentPayload.documentHighlights) {
        const elementParts = dh.elementId.split('-');
        const newElementId = elementParts.slice(0, elementParts.length - 1).join('-');
        newSelectedElements.add(newElementId);
        selectedHighlightType.add(dh.elementType);
      }

      // this should be 1, don't act if it's not
      if (selectedHighlightType.size === 1) {
        // get first value from set
        const elementType: "WORD" | "LINE" | "TEXTBOX" | unknown = Array.from(selectedHighlightType)[0];
        if (elementType === "WORD" || elementType === "LINE") {
          const newElementType = elementType === "WORD" ? "LINE" : "TEXTBOX";
          newDocumentHighlights.push(
            ...Array.from(newSelectedElements).map((id) => ({ 
              elementType: newElementType, 
              elementId: id 
            } as DocumentHighlight))
          );
        } else if (elementType === "TEXTBOX") {
          // do nothing, this is the most we can expand
        }
      }

      setPreviousDocumentHighlights([...previousDocumentHighlights, [...currentPayload.documentHighlights]]);

      const documentHighlightsToSet = (newDocumentHighlights.length > 0) ? newDocumentHighlights : currentPayload.documentHighlights;
      setCurrentPayload({
        documentHighlights: documentHighlightsToSet,
        suggestedContractTerms: currentPayload?.suggestedContractTerms ?? []
      });

      setNavigateHighlightState(true, documentHighlightsToSet);
    }
  }, [
    currentPayload, 
    editor
  ]);

  const undoHighlightsChange = useCallback(() => {
    const previousDocumentHighlightsCopy = [...previousDocumentHighlights];
    
    const lastDocumentHighlights = previousDocumentHighlightsCopy.pop();
    setPreviousDocumentHighlights(previousDocumentHighlightsCopy);

    if (lastDocumentHighlights) {
      setCurrentPayload({
        documentHighlights: lastDocumentHighlights,
        suggestedContractTerms: currentPayload?.suggestedContractTerms ?? []
      });

      setNavigateHighlightState(true, lastDocumentHighlights);
    }
  }, [previousDocumentHighlights, currentPayload])

  return (
    <>
      {showAddAnnotationInput &&
        createPortal(
          injectAddAnnotationWidget(
            targetFileUploadItemId,
            pageIndex,
            currentPayload,
            editor,
            expandHighlights,
            undoHighlightsChange,
            closeAddAnnotation,
            originalRangeRect,
            originalSelectionRects,
            editorParentBoundingBox,
          ),
          document.body,
        )}
    </>
  );
}