import React, { useEffect, useMemo } from "react";
import { Button, Grid } from "@mui/material/";
import Box from '@mui/material/Box';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { Form } from "../../../components/jsonschema/theme";
import { SimpleModalWrapper } from "../../../components/dialog/wrappers/simpleModalWrapper";
import { UseAQuestionToFulfillData, EmbedAModuleInsideOfAGraph, EndGraphNode } from "../../../types/builderv2.generated";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ArrowDownward, ArrowUpward, Delete, Edit } from "@mui/icons-material";
import { CrudButtonGroup } from "../../../components/buttons/crudButtonGroup";
import CustomCommentaryWidget from "./CustomCommentaryWidget";

type Node = UseAQuestionToFulfillData | EmbedAModuleInsideOfAGraph | EndGraphNode;

interface NodeRowProps {
    node: Node;
    shouldDisableUp: boolean;
    shouldDisableDown: boolean;
    clickUp: () => void;
    clickDown: () => void;
    onDelete: () => void;
    setEditNode: (n: Node) => void;
}

const NodeRow = ({
    node,
    shouldDisableUp,
    shouldDisableDown,
    clickUp,
    clickDown,
    onDelete,
    setEditNode
}: NodeRowProps) => {
    return (
        <React.Fragment>
            <TableRow
                sx={{
                    '& > *': { borderBottom: 'unset' },
                    "&:hover": {
                        bgcolor: "rgba(0, 0, 0, 0.04)",
                    },
                }}
            >
                <TableCell component="th" scope="row">
                    {node.id}
                </TableCell>
                <TableCell component="th" scope="row">
                    {node.nodeType}
                </TableCell>
                <TableCell scope="row">
                    <CrudButtonGroup 
                        buttons={[
                            {
                                icon: (<Edit fontSize="small" />),
                                handleClick: () => {
                                    setEditNode(node);
                                }
                            },
                            {
                                disabled: shouldDisableUp,
                                icon: (<ArrowUpward fontSize="small" />),
                                handleClick: () => {
                                    clickUp();
                                }
                            },
                            {
                                disabled: shouldDisableDown,
                                icon: (<ArrowDownward fontSize="small" />),
                                handleClick: () => {
                                    clickDown();
                                }
                            },
                            {
                                icon: (<Delete fontSize="small" />),
                                handleClick: () => {
                                    onDelete();
                                }
                            }
                        ]} 
                    />
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

interface NodesEditorProps {
    fullSchema: RJSFSchema;
    nodes: Node[];
    onUpdate: (ns: Node[]) => void;
}

const NodesEditor = ({
    fullSchema,
    nodes,
    onUpdate
}: NodesEditorProps) => {
    const [editNode, setEditNode] = React.useState<Node | null>(null);
    const [adding, setAdding] = React.useState<boolean>(false);

    const nextNodeId = useMemo(() => {
        if (nodes) {
            let maxNum = -1
            for (let node of nodes) {
                let num = parseInt(node.id.substring(1));
                if (maxNum < num) {
                    maxNum = num;
                }
            }
            return `n${(maxNum + 1)}`
        }
        return "n0"
    }, [nodes]);

    const updateNode = (node: Node) => {
        const newNodes = JSON.parse(JSON.stringify(nodes));
        if (adding) {
            newNodes.push(node);
        } else {
            for (let i = 0; i < newNodes.length; i++) {
                if (node.id === newNodes[i].id) {
                    newNodes[i] = node;
                }
            }
        }

        onUpdate(newNodes);
        setAdding(false);
    };

    const onClickUp = (i: number) => {
        const newNodes = JSON.parse(JSON.stringify(nodes));
        let tempTop = newNodes[i - 1];
        let tempCur = newNodes[i];

        newNodes[i - 1] = tempCur;
        newNodes[i] = tempTop;
        
        onUpdate(newNodes);
    };

    const onClickDown = (i: number) => {
        const newNodes = JSON.parse(JSON.stringify(nodes));

        let tempBottom = newNodes[i + 1];
        let tempCur = newNodes[i];

        newNodes[i + 1] = tempCur;
        newNodes[i] = tempBottom;
    
        onUpdate(newNodes);
    };

    const onDelete = (i: number) => {
        const newNodes = JSON.parse(JSON.stringify(nodes));
        newNodes.splice(i, 1);
        onUpdate(newNodes);
    };

    return (
        <>
            <Grid container p={1}>
                <Grid item xs={12}>
                    <Button
                        sx={{ m: 1 }}
                        variant="contained"
                        size="small"
                        onClick={() => {
                            setAdding(true);
                            setEditNode({
                                id: nextNodeId,
                                nodeType: "question"
                            } as UseAQuestionToFulfillData);
                        }}
                    >
                        Add Question Node
                    </Button>
                    <Button
                        sx={{ m: 1 }}
                        variant="contained"
                        size="small"
                        onClick={() => {
                            setAdding(true);
                            setEditNode({
                                id: nextNodeId,
                                nodeType: "module"
                            } as EmbedAModuleInsideOfAGraph);
                        }}
                    >
                        Add Module Node
                    </Button>
                    <Button
                        sx={{ m: 1 }}
                        variant="contained"
                        size="small"
                        onClick={() => {
                            setAdding(true);
                            setEditNode({
                                id: nextNodeId,
                                nodeType: "end"
                            } as EndGraphNode);
                        }}
                    >
                        Add End Node
                    </Button>
                </Grid>
            </Grid>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                id
                            </TableCell>
                            <TableCell>
                                type
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {nodes.map((n: Node, i: number) => (
                            <NodeRow
                                node={n}
                                shouldDisableUp={(i === 0)}
                                shouldDisableDown={(i === (nodes.length - 1))}
                                clickUp={() => {
                                    onClickUp(i);
                                }}
                                clickDown={() => {
                                    onClickDown(i);
                                }}
                                onDelete={() => {
                                    onDelete(i);
                                }}
                                setEditNode={setEditNode}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <SimpleModalWrapper
                headerText="Edit Node"
                open={!!editNode}
                handleClose={() => {
                    setEditNode(null);
                    setAdding(false);
                }}
                maxWidth='md'
            >
                <Box
                    sx={{
                        width: '100%',
                        paddingTop: '3%',
                        alignContent: "center"
                    }}
                >
                    {editNode?.nodeType === "question" && (
                        <Form
                            formData={editNode}
                            onSubmit={(data, event) => {
                                updateNode(data.formData);
                                setEditNode(null)
                            }}
                            uiSchema={{
                                questionCommentary: {
                                    'ui:widget': 'CustomCommentaryWidget'
                                }
                            }}
                            widgets={ {
                                CustomCommentaryWidget
                            }}
                            schema={({
                                $ref: "#/$defs/graphFulfillmentQuestionNode",
                                $defs: fullSchema.$defs
                            } as RJSFSchema)}
                            validator={validator}
                        />
                    )}
                    {editNode?.nodeType === "module" && (
                        <Form
                            formData={editNode}
                            onSubmit={(data, event) => {
                                updateNode(data.formData);
                                setEditNode(null)
                            }}
                            schema={({
                                $ref: "#/$defs/graphFulfillmentModuleNode",
                                $defs: fullSchema.$defs
                            } as RJSFSchema)}
                            validator={validator}
                        />
                    )}
                    {editNode?.nodeType === "end" && (
                        <Form
                            formData={editNode}
                            onSubmit={(data, event) => {
                                updateNode(data.formData);
                                setEditNode(null)
                            }}
                            schema={({
                                $ref: "#/$defs/graphFulfillmentEndNode",
                                $defs: fullSchema.$defs
                            } as RJSFSchema)}
                            validator={validator}
                        />
                    )}
                </Box>
            </SimpleModalWrapper>
        </>
    );
}

export default NodesEditor;
