import { InitialConfigType, LexicalComposer } from "@lexical/react/LexicalComposer";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin"
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import React, { memo, useMemo } from 'react';
import { LexicalEditor } from "lexical";
import ForceUpdatePlugin from "./ForceUpdatePlugin";
import { DocumentAnnotation, DocumentHighlight } from "../../types/taker/documentkeyterms.generated";
import { RawHtmlNode } from "../lexical/nodes/RawHtmlNode";
import SyncAnnotationPlugin from "../lexical/plugins/SyncAnnotationPlugin";
import AddAnnotationPlugin from "../lexical/plugins/AddAnnotationPlugin";
import EditAnnotationPlugin from "../lexical/plugins/EditAnnotationPlugin";
import FloatingAnnotationToolbarPlugin from "../lexical/plugins/FloatingAnnotationToolbarPlugin";
import { usePdfHighlighter } from "./context";
import { PraxiPlainText } from "./PraxiPlainText";

interface PageMaskProps {
    readOnly: boolean;
    lexicalDocumentIdentifier: string;
    pageIndex: number;
    lexicalPage?: Object,
    annotations: DocumentAnnotation[];
    aiAnnotations: DocumentAnnotation[];
    pageScaleFactor: number;
    isV2Render: boolean;
    editorParentBoundingBox?: DOMRect;
}

const PageMask = ({
    readOnly,
    lexicalDocumentIdentifier,
    pageIndex,
    lexicalPage,
    annotations,
    aiAnnotations,
    pageScaleFactor,
    isV2Render,
    editorParentBoundingBox
}: PageMaskProps) => {
    const { 
        setStickyNavigateHighlightElementIDs,
        setNavigateHighlightElementIDs,
        applyFiltersToDocuments,
        keyTermIdentifierFilters,
        availableHightlightTerms
    } = usePdfHighlighter();

    const setNavigateHighlightState = (sticky: boolean, dh: DocumentHighlight[] | undefined) => {
        setStickyNavigateHighlightElementIDs(sticky);
        setNavigateHighlightElementIDs(dh);
    };

    const initialConfig = {
        editorState: (editor: LexicalEditor) => {
            if (lexicalPage) {
                const editorState = editor.parseEditorState(JSON.stringify(lexicalPage));
                if (!editorState.isEmpty()) {
                    editor.setEditorState(editorState);
                } else {
                    console.warn(`editorState was empty for page with index=${pageIndex}`);
                }
            }
        },
        editable: false,
        namespace: "main",
        onError(error: any) {
            throw error;
        },
        nodes: [RawHtmlNode]
    } as InitialConfigType;

    const annotationIdsFilter = useMemo(() => {
        if (
            !(applyFiltersToDocuments && 
            (keyTermIdentifierFilters && keyTermIdentifierFilters.length > 0))
        ) { 
            return undefined;
        }

        const allAnnotationIds = new Set<string>();
        if (keyTermIdentifierFilters && availableHightlightTerms) {
            for (const ht of availableHightlightTerms) {
                // if no identifier or no annotations, skip
                if (!ht.identifier || (!ht.documentAnnotations && !ht.aiDocumentAnnotations)) {
                    continue;
                }

                if (keyTermIdentifierFilters.includes(ht.identifier)) {
                    if (ht.documentAnnotations) {
                        for (const da of ht.documentAnnotations) {
                            allAnnotationIds.add(da.annotationId);
                        }
                    }
                    if (ht.aiDocumentAnnotations) {
                        for (const da of ht.aiDocumentAnnotations) {
                            allAnnotationIds.add(da.annotationId);
                        }
                    }
                }
            }
        }
        return allAnnotationIds;
    }, [
        keyTermIdentifierFilters,
        availableHightlightTerms
    ]);

    const filteredAnnotations = useMemo(() => {
        return annotations.filter((a) => 
            !annotationIdsFilter || annotationIdsFilter.has(a.annotationId)
        )
    }, [
        annotations, 
        annotationIdsFilter,
    ]);

    const filteredAiAnnotations = useMemo(() => {
        return aiAnnotations.filter((a) =>
            !annotationIdsFilter || annotationIdsFilter.has(a.annotationId)
        )
    }, [
        aiAnnotations,
        annotationIdsFilter
    ]);

    return (
        <LexicalComposer initialConfig={initialConfig}>
            <ForceUpdatePlugin 
                lexicalDocumentIdentifier={lexicalDocumentIdentifier}
                lexicalPage={lexicalPage}
            />
            <PraxiPlainText
                contentEditable={<ContentEditable/>}
                placeholder={null}
                ErrorBoundary={LexicalErrorBoundary}
            />
            <SyncAnnotationPlugin
                annotations={filteredAnnotations}
                aiAnnotations={filteredAiAnnotations}
                lexicalDocumentIdentifier={lexicalDocumentIdentifier}
                pageIndex={pageIndex}
                pageScaleFactor={pageScaleFactor}
            />
            <AddAnnotationPlugin
                targetFileUploadItemId={lexicalDocumentIdentifier}
                pageIndex={pageIndex}
                editorParentBoundingBox={editorParentBoundingBox}
                setNavigateHighlightState={setNavigateHighlightState}
            />
            <EditAnnotationPlugin
                //readOnly={readOnly}
                //onUpdateAnnotation={pdfHighlighterService.bulkUpdate}
                editorParentBoundingBox={editorParentBoundingBox}
                setNavigateHighlightState={setNavigateHighlightState}
            />
            <FloatingAnnotationToolbarPlugin
                annotations={filteredAnnotations}
                isV2Render={isV2Render}
                lexicalDocumentIdentifier={lexicalDocumentIdentifier}
                pageIndex={pageIndex}
            />
        </LexicalComposer>
    );
}

export default memo(PageMask);