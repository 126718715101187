import React, { useMemo } from 'react';
import {
    Button,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
} from "@mui/material/";
import GuidanceItemPicker from './GuidanceItemPicker';
import { BuilderDocument } from '../../../../redux/models/dataModelTypes';

interface GuidancePickerProps {
    builderDocument: BuilderDocument;
    indexedGuidanceId: string | undefined;
    selectedItemIds: string[];
    setIndexedGuidanceId: (id: string) => void;
    onUpdatedItems: (itemIds: string[]) => void;
    onAdd: () => void;
}

const GuidancePicker = ({
    builderDocument,
    indexedGuidanceId,
    selectedItemIds,
    setIndexedGuidanceId,
    onUpdatedItems,
    onAdd
}: GuidancePickerProps) => {
    const selectedIndexedGuidance = useMemo(() => {
        if (indexedGuidanceId) {
            let bdg = builderDocument.builderDocumentGuidances.find((bdg) => bdg.indexedGuidanceId === indexedGuidanceId);
            if (bdg) {
                return bdg.indexedGuidance;
            }
        }
        return null;
    }, [indexedGuidanceId]);

    return (
        <Stack spacing={2}>
            <Select
                size="small"
                data-testid="indexed-guidance-select"
                value={indexedGuidanceId}
                onChange={(event: SelectChangeEvent<string>) => {
                    setIndexedGuidanceId(event.target.value);
                }}
                label=""
            >
                {builderDocument.builderDocumentGuidances.map((bdg) => (
                    <MenuItem value={bdg.indexedGuidanceId}>
                        {bdg.indexedGuidance?.name}
                    </MenuItem>
                ))}
            </Select>
            <GuidanceItemPicker
                indexedGuidance={selectedIndexedGuidance}
                selectedItemIds={selectedItemIds}
                onUpdateSelectedItems={(selectedItems) => {
                    onUpdatedItems(selectedItems);
                }}
            />
            <Button
                size="small"
                onClick={() => {
                    if (selectedIndexedGuidance) {
                        onAdd();
                    }
                }}
            >
                Add
            </Button>
        </Stack>
    );
};

export default GuidancePicker;