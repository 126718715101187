import React, { useEffect } from "react";
import { useAnimate } from "motion/react";
import { stagger } from "motion";


interface TypedTextProps {
    text: string;
    /** 
     * How long each character’s fade-in should last, in seconds 
     * (actual “typing” speed). 
     */
    durationPerChar?: number;
    /**
     * Delay between each character in the stagger, in seconds
     * (you can decouple this from durationPerChar or make them the same).
     */
    staggerDelay?: number;
}

const TypedText: React.FC<TypedTextProps> = ({
    text,
    durationPerChar = 0.005,  // 50ms fade per character
    staggerDelay = 0.05,      // 50ms delay between characters
}) => {
    const [scope, animate] = useAnimate();

    useEffect(() => {
        // Animate each character’s opacity from 0 → 1, 
        // staggering each character in sequence.
        // Note: The total time per character is controlled by `durationPerChar`,
        // while the delay between characters is controlled by `staggerDelay`.
        animate(
            scope.current.querySelectorAll(".char"),
            {
                opacity: [0, 1] 
            },
            {
                duration: durationPerChar,
                delay: stagger(staggerDelay),
                ease: "linear",
            }
        );
    }, [text, durationPerChar, staggerDelay]);

    return (
        <div
            ref={scope}
        >
            {/* Render each character as its own span for individual animation */}
            {text.split("").map((char, index) => (
                <span key={index} className="char" style={{ opacity: 0 }}>
                    {char}
                </span>
            ))}
        </div>
    );
};

export default TypedText;
