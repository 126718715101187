import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography, List, ListSubheader, ListItemText, ListItemButton, Stack, IconButton, Button } from "@mui/material";
import { ALL_ADVISOR_SM_TASKS, useResearchPanelData } from '../../../../containers/ResearchPanelData/ResearchPanelData';
import { useAddSourceMaterialsTransformAnalysisMutation } from '../../../../redux/services/taker';
import { useTakerState } from '../../../../containers/TakerDocumentState/TakerDocumentState';
import { Send } from '@mui/icons-material';
import SourceMaterialFilters from './SourceMaterialFilters';


const SourceMaterialFlow = () => {
    const {
        taker,
        takerDocumentId
    } = useTakerState();
    const {
        researchPanelAdvisorState,
        setResearchPanelAdvisorState
    } = useResearchPanelData();
    const [
        addSMTransform,
        addSMTransformResult
    ] = useAddSourceMaterialsTransformAnalysisMutation();

    const selectedTask = useMemo(() => {
        if (!researchPanelAdvisorState.sourceMaterialsState?.selectedTaskIndex) {
            return;
        }

        const [groupIndex, taskIndex] = researchPanelAdvisorState.sourceMaterialsState.selectedTaskIndex;
        const taskObject = ALL_ADVISOR_SM_TASKS[groupIndex];
        return taskObject.tasks[taskIndex];
    }, [researchPanelAdvisorState.sourceMaterialsState?.selectedTaskIndex]);

    // on completion, go back to the empty source materials flow
    useEffect(() => {
        setResearchPanelAdvisorState(prev => ({
            ...prev,
            topLevelSelection: "SOURCE_MATERIALS",
            sourceMaterialsState: {}
        }));
    }, [addSMTransformResult.isSuccess]);

    return (
        <Stack
            spacing={2}
            paddingTop={2}
            paddingBottom={2}
        >
            <List
                sx={{
                    width: '100%',
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: 200,
                    '& ul': { padding: 0 },
                    border: '1px solid lightgray',
                    borderRadius: 1
                }}
                subheader={<li />}
            >
                {ALL_ADVISOR_SM_TASKS.map((taskObject, groupIndex) => (
                    <li key={`section-${groupIndex}`}>
                        <ul>
                            <ListSubheader color="inherit">{taskObject.displayGroupName}</ListSubheader>
                            {taskObject.tasks.map((task, taskIndex) => (
                                <ListItemButton
                                    key={`item-${groupIndex}-${taskIndex}`}
                                    selected={(
                                        researchPanelAdvisorState.sourceMaterialsState?.selectedTaskIndex &&
                                        researchPanelAdvisorState.sourceMaterialsState.selectedTaskIndex[0] === groupIndex &&
                                        researchPanelAdvisorState.sourceMaterialsState.selectedTaskIndex[1] === taskIndex
                                    )}
                                    onClick={(event) => {
                                        setResearchPanelAdvisorState(prev => ({
                                            ...prev,
                                            sourceMaterialsState: {
                                                ...prev.sourceMaterialsState,
                                                selectedTaskIndex: [groupIndex, taskIndex]
                                            }
                                        }));
                                    }}
                                >
                                    <ListItemText secondary={task.display} />
                                </ListItemButton>
                            ))}
                        </ul>
                    </li>
                ))}
            </List>
            {(selectedTask !== undefined) && (
                <Stack
                    display="flex"
                    sx={{
                        border: '1px solid lightgray',
                        borderRadius: 1,
                        maxHeight: 200,
                        backgroundColor: "white"
                    }}
                >
                    <Box 
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        borderBottom="1px solid lightgray"
                        padding={1}
                    >
                        <Typography noWrap>
                            <strong>{selectedTask.display}</strong>
                        </Typography>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={addSMTransformResult.isLoading}
                            onClick={() => {
                                if (!taker || researchPanelAdvisorState.sourceMaterialsState === undefined) {
                                    return;
                                }
                                addSMTransform({
                                    takerId: taker.id,
                                    takerDocumentId: takerDocumentId,
                                    transformType: selectedTask.transformType,
                                    documentIdFilter: researchPanelAdvisorState.sourceMaterialsState.documentIdFilter,
                                    targetKeyTermIdFilter: researchPanelAdvisorState.sourceMaterialsState.targetKeyTermIdFilter
                                });
                            }}
                            endIcon={<Send />}
                        >
                            Send
                        </Button>
                    </Box>
                    <SourceMaterialFilters
                        defaultDocumentIdFilter={researchPanelAdvisorState.sourceMaterialsState?.documentIdFilter}
                        defaultTargetKeyTermIdFilter={researchPanelAdvisorState.sourceMaterialsState?.targetKeyTermIdFilter}
                        selectedTask={selectedTask}
                        onChangeEnabledFilters={(filters) => {
                            const newDocumentIdFilter: string[] = [];
                            const newTargetKeyTermIdFilter: string[] = [];
                            for (const filter of filters) {
                                if (filter.type === "DOCUMENT") {
                                    newDocumentIdFilter.push(filter.value);
                                } else if (filter.type === "INDIVIDUAL_KEY_TERM") {
                                    newTargetKeyTermIdFilter.push(filter.value);
                                }
                            }

                            setResearchPanelAdvisorState(prev => ({
                                ...prev,
                                sourceMaterialsState: {
                                    ...prev.sourceMaterialsState,
                                    documentIdFilter: newDocumentIdFilter,
                                    targetKeyTermIdFilter: newTargetKeyTermIdFilter,
                                    customQuery: undefined
                                }
                            }));
                        }}
                    />
                </Stack>
            )}
        </Stack>
    );
};

export default SourceMaterialFlow;

