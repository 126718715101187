const WEIGHTS = [
    10 ** 7,
    10 ** 5,
    10 ** 3,
    10 ** 1,
]

export const calculateIdWeight = (wordIdParts: string[]) => {
    return wordIdParts.map((p, i) => (parseInt(p) * WEIGHTS[i])).reduce((a, b) => a + b, 0);
}

export const betweenStartAndEnd = (wordIdParts: string[], firstIdParts: string[], lastIdParts: string[]) => {
    const thisWordWeight = calculateIdWeight(wordIdParts);
    const firstWordWeight = calculateIdWeight(firstIdParts);
    const lastWordWeight = calculateIdWeight(lastIdParts);

    // somtimes this happens when a single chunk is highlighted 
    if (lastWordWeight <= firstWordWeight) {
        return thisWordWeight >= firstWordWeight;
    }

    if (lastIdParts.length === 3) {
        return thisWordWeight >= firstWordWeight && thisWordWeight < lastWordWeight;
    }
    return thisWordWeight >= firstWordWeight && thisWordWeight <= lastWordWeight;
}