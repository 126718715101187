import React, { useState } from "react";
import {
    Toolbar,
    styled,
} from "@mui/material/";

const MiniToolbarWithShadow = styled(Toolbar)`
    justify-content: space-between;
    align-items: center;
    background-color: #FBFCFE;
    border: 1px solid transparent; 
    box-shadow: 0 4px 10px -6px rgba(0, 0, 0, 0.1); 
`;

export default MiniToolbarWithShadow;