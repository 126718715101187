import React, { createContext, useContext } from 'react';
import { PDFDocumentProxy, getDocument } from 'pdfjs-dist';

const pdfCache = new Map<string, Promise<PDFDocumentProxy>>();

export function loadPdf(url: string): Promise<PDFDocumentProxy> {
  if (!pdfCache.has(url)) {
    if (pdfCache.size >= 2) {
        pdfCache.delete(pdfCache.entries().next().value[0]);
    }
    
    const loadingTask = getDocument({
        url,
        withCredentials: true,
        cMapUrl: '/cmaps/',
        standardFontDataUrl: '/standard_fonts/'
    }).promise;
    pdfCache.set(url, loadingTask);
  }
  return pdfCache.get(url)!;
}

interface PdfContextType {
    getDocument: (url: string) => Promise<PDFDocumentProxy>;
}

const PdfContext = createContext<PdfContextType | undefined>(undefined);

export const PdfDocumentProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const getDocument = (url: string) => loadPdf(url);
    return <PdfContext.Provider value={{ getDocument }}>{children}</PdfContext.Provider>;
};

export const usePdf = () => {
    const context = useContext(PdfContext);
    if (!context) {
        throw new Error('usePdf must be used within a PdfDocumentProvider');
    }
    return context;
};
