import React from 'react';
import { Box } from "@mui/material";
import AdvisorResponses from './AdvisorResponses';
import AdvisorFlow from './AdvisorFlow';
import AdvisorHeader from './AdvisorHeader';


const Advisor = () => {
    return (
        <Box 
            padding={1}
            height="calc(100vh - 96px)"
            display="flex"
            flexDirection="column"
        >
            <AdvisorHeader />
            <AdvisorFlow />
            <AdvisorResponses 
                onLatestPending={() => {}}
                onLatestComplete={() => {}}
            />
        </Box>
    );
};

export default Advisor;
