import React, { useMemo, useRef } from "react";
import { Box, Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, ToggleButton, ToggleButtonGroup, Toolbar, Tooltip } from "@mui/material";
import { useTakerState } from "../../containers/TakerDocumentState/TakerDocumentState";
import { useKeyTermsWrapper } from "../../containers/WidgetWrapper/wrapper";
import { ArrowDropDown, Article, Settings } from "@mui/icons-material";
import GroupManagerModal from "./GroupManagerModal";
import { useKeyTermGroupState } from "../../containers/TakerDocumentState/KeyTermGroupState";
import { useLocalStorage } from "@uidotdev/usehooks";
import ExecutiveSummaryModal from "./ExecutiveSummaryModal";

const MainToolbar = ({
    readOnly
}: {
    readOnly: boolean;
}) => {
    const {
        taker,
        takerDocumentUploads,
        takerDocumentId
    } = useTakerState();
    const { state, mutateState } = useKeyTermsWrapper()
    const [selectedTtduId, saveSelectedTtduId] = useLocalStorage<string | null>(`${taker?.id}-ttduId`, null);

    const { 
        groupManagerOpen, 
        executiveSummaryModalOpen,
        panelViewMode 
    } = state;

    const groupButtonRef = useRef<HTMLDivElement>(null);
    const [groupsBtnOpen, setGroupsBtnOpen] = React.useState(false);

    const handleCloseGroups = (event: Event) => {
        if (
            groupButtonRef.current &&
            groupButtonRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }
        setGroupsBtnOpen(false);
    };

    const selectedPanels = useMemo(() => {
        if (panelViewMode === 0) {
            return [0, 1];
        } else if (panelViewMode === 1) {
            return [0];
        } else if (panelViewMode === 2) {
            return [1];
        }
    }, [panelViewMode]);

    const sortedTakerDocumentUploads = useMemo(() =>
        [...(takerDocumentUploads || [])].sort((a, b) => b.createdAt - a.createdAt),
        [takerDocumentUploads]
    );

    const selectedTtdu = useMemo(
        () => sortedTakerDocumentUploads.find(tdu => tdu.id === selectedTtduId),
        [
            sortedTakerDocumentUploads,
            selectedTtduId
        ]
    );

    return (
        <Box sx={{ padding: 0.5 }}>
            <Toolbar
                variant="dense"
                disableGutters
                sx={{
                    width: "100%",
                    display: "inline-grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    alignItems: "center",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                    <Button
                        variant="contained"
                        data-testid="group-manager-button"
                        aria-haspopup="menu"
                        onClick={() => mutateState({
                            groupManagerOpen: true
                        })}
                        startIcon={<Settings />}
                    >
                        Key Term Groups
                    </Button>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-around",
                    }}
                >
                    <Box>
                        <Tooltip title={"Select a Key Term Group"} placement="top">
                            <ButtonGroup
                                variant="text"
                                color="inherit"
                                ref={groupButtonRef}
                            >
                                <Button
                                    data-testid="key-term-group-button"
                                    disabled={sortedTakerDocumentUploads.length === 0}
                                    onClick={() => {
                                        setGroupsBtnOpen((prevOpen) => !prevOpen);
                                    }}
                                    startIcon={<ArrowDropDown />}
                                >
                                    {!!selectedTtdu ? (
                                        selectedTtdu.name
                                    ) : (
                                        "select a group"
                                    )}
                                </Button>
                            </ButtonGroup>
                        </Tooltip>
                        <Popper
                            data-testid="key-term-group-dropdown-popper"
                            sx={{
                                zIndex: 11,
                            }}
                            open={groupsBtnOpen}
                            anchorEl={groupButtonRef.current}
                            role={undefined}
                            transition
                            disablePortal
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom' ? 'center top' : 'center bottom',
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleCloseGroups}>
                                            <MenuList>
                                                {sortedTakerDocumentUploads.map((tdu, index) => (
                                                    <MenuItem
                                                        key={tdu.id}
                                                        data-testid={`key-term-group-dropdown-item-${index}`}
                                                        onClick={(event) => {
                                                            mutateState({
                                                                targetFileUploadItemIds: [],
                                                                keyTermIdentifierFilters: [],
                                                                applyFiltersToDocuments: undefined,
                                                                scrollToKeyTermIdentifier: undefined,
                                                                scrollToPage: undefined,
                                                                scrollToElementID: undefined,
                                                                expandedSummaries: {},
                                                                keyTermOtherFilters: []
                                                            });
                                                            saveSelectedTtduId(tdu.id);
                                                            setGroupsBtnOpen(false);
                                                        }}
                                                    >
                                                        {tdu.name}
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Tooltip title={"Show Hide Panels"}>
                        <ToggleButtonGroup
                            size="small"
                            color="primary"
                            value={selectedPanels}
                            data-testid="panel-view-mode"
                            onChange={(_, newMode) => {
                                let hasSummaries = newMode.includes(0);
                                let hasDocuments = newMode.includes(1);
                                if (hasSummaries && hasDocuments) {
                                    mutateState({
                                        panelViewMode: 0
                                    });
                                } else if (hasSummaries) {
                                    mutateState({
                                        panelViewMode: 1
                                    });
                                } else if (hasDocuments) {
                                    mutateState({
                                        panelViewMode: 2
                                    });
                                }
                            }}
                        >
                            <ToggleButton
                                data-testid="toggle-key-terms-summaries"
                                value={0}
                            >
                                Key Terms
                            </ToggleButton>
                            <ToggleButton
                                data-testid="toggle-documents"
                                value={1}
                            >
                                Documents
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Tooltip>
                    <Button
                        variant="contained"
                        data-testid="executive-summary-button"
                        aria-haspopup="menu"
                        onClick={() => mutateState({
                            executiveSummaryModalOpen: true
                        })}
                        startIcon={<Article />}
                    >
                        Executive Summary
                    </Button>
                </Box>
            </Toolbar>
            <GroupManagerModal
                readOnly={readOnly}
                takerDocumentId={takerDocumentId}
                open={groupManagerOpen}
                setOpen={(o) => mutateState({
                    groupManagerOpen: o
                })}
            />
            <ExecutiveSummaryModal
                readOnly={readOnly}
                open={executiveSummaryModalOpen}
                setOpen={(o) => mutateState({
                    executiveSummaryModalOpen: o
                })}
            />
        </Box>
    );
};

export default MainToolbar;