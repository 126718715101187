import { Box } from "@mui/material/";
import { useEffect, useState, useRef } from "react";

interface AutoScalingInputProps {
    name: string | undefined;
    onChange: (s: string) => void;
}

const AutoScalingInput = ({
    name,
    onChange
}: AutoScalingInputProps) => {
    const [width, setWidth] = useState<number>(0);
    const spanElem = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        if (spanElem.current) {
            setWidth(spanElem.current.offsetWidth * 1.1);
        }
    }, [name]);

    const changeHandler = (evt: any) => {
        onChange(evt.target.value);
    };

    return (
        <Box>
            <span
                ref={spanElem}
                style={{
                    position: "absolute",
                    opacity: 0,
                    zIndex: -100,
                    whiteSpace: "pre",
                    fontSize: "1.4em"
                }}>
                {name}
            </span>
            <input
                type="text"
                style={{
                    fontSize: "1.4em",
                    width: width,
                    border: "none"
                }}
                autoFocus
                onChange={changeHandler}
                value={name}
            />
        </Box>
    );
};

export default AutoScalingInput;