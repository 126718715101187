import React, { useEffect, useMemo } from 'react';
import { Box } from "@mui/material";
import { useTakerState } from '../../../containers/TakerDocumentState/TakerDocumentState';
import AdvisorResponse from './AdvisorResponse';
import { blue } from '@mui/material/colors';
import { useVirtualizer } from '@tanstack/react-virtual'

interface AdvisorResponsesProps {
    onLatestPending: () => void;
    onLatestComplete: () => void;
}

const AdvisorResponses = ({
    onLatestPending,
    onLatestComplete
}: AdvisorResponsesProps) => {
    const parentRef = React.useRef<HTMLDivElement>(null);
    const { activeTakerDocument } = useTakerState();

    const sortedContractQATakerDocumentAnalyses = useMemo(() => {
        if (!activeTakerDocument) {
            return [];
        }

        let sortedAnalyses = [...activeTakerDocument.contractQATakerDocumentAnalyses];
        sortedAnalyses.sort((a, b) => b.createdAt - a.createdAt);
        return sortedAnalyses;
    }, [activeTakerDocument]);

    useEffect(() => {
        let latestAnalysis = sortedContractQATakerDocumentAnalyses[0];
        if (latestAnalysis) {
            if (latestAnalysis.state === "PENDING_GENERATION") {
                onLatestPending();
            } else if (latestAnalysis.state === "PENDING_REVIEW") {
                onLatestComplete();
            }
        }
    }, [sortedContractQATakerDocumentAnalyses]);

    const virtualizer = useVirtualizer({
        count: sortedContractQATakerDocumentAnalyses.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 45,
        overscan: 10
    });

    const items = virtualizer.getVirtualItems();

    return (
        <Box
            flexGrow={1}
            overflow="hidden"
            width="100%"
            sx={{
                backgroundColor: 'white',
                border: `2px solid ${blue[200]}`,
                borderRadius: 1,
            }}
        >
            <div
                ref={parentRef}
                style={{
                    height: '100%',
                    width: '100%',
                    overflowY: 'auto',
                }}
            >
                <div
                    style={{
                        height: virtualizer.getTotalSize(),
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            transform: `translateY(${items[0]?.start ?? 0}px)`,
                        }}
                    >
                        {items.map((virtualRow) => (
                            <Box
                                key={virtualRow.key}
                                padding={1}
                                component="div"
                                data-index={virtualRow.index}
                                ref={virtualizer.measureElement}
                            >
                                <AdvisorResponse 
                                    key={sortedContractQATakerDocumentAnalyses[virtualRow.index].id}
                                    tduAnalysis={sortedContractQATakerDocumentAnalyses[virtualRow.index]} 
                                />
                            </Box>
                        ))}
                    </div>
                </div>
            </div>
        </Box>
    );
};

export default AdvisorResponses;
