import React, { useEffect, useMemo, useState } from "react";
import {
    Box,
    Grid,
    Typography,
    Skeleton,
    Popper,
    Fade,
    Paper,
    IconButton,
} from "@mui/material/";
import Markdown from 'react-markdown';
import { blue, grey } from "@mui/material/colors";
import { useGetTakerDocumentAnalysisPayloadQuery } from "../../../redux/services/taker";
import { TakerDocumentAnalysis } from "../../../redux/models/dataModelTypes";
import { ALL_ADVISOR_SM_TASKS, useResearchPanelData } from "../../../containers/ResearchPanelData/ResearchPanelData";
import { Info } from "@mui/icons-material";
import { useTakerState } from "../../../containers/TakerDocumentState/TakerDocumentState";

import '../../lexical/wrappers/RichTextEditor/index.css';


interface AdvisorResponseProps {
    tduAnalysis: TakerDocumentAnalysis;
}

const AdvisorResponse = ({
    tduAnalysis
}: AdvisorResponseProps) => {
    const { takerDocumentUploads } = useTakerState();
    const { documentKeyTermHolders } = useResearchPanelData();
    const { data, isSuccess, refetch } = useGetTakerDocumentAnalysisPayloadQuery(tduAnalysis.id);

    const [pendingGeneration, setPendingGeneration] = useState<boolean | undefined>(undefined);
    const [failureMessage, setFailureMessage] = useState<string | undefined>(undefined);
    const [localAnswers, setLocalAnswers] = useState<{title: string, answer: string}[]>([]);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState(false);

    const handleInfoClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => !prev);
    };

    useEffect(() => {
        // If the analysis is still pending generation
        if (tduAnalysis.state === "PENDING_GENERATION") {
            setPendingGeneration(true);
        } else if (tduAnalysis.state === "PENDING_REVIEW") {
            setPendingGeneration(false);
            refetch();
        } else if (tduAnalysis.state === "PENDING_GENERATION_CANCELED") {
            let msg = tduAnalysis.data['job_failure'];
            if (!msg) {
                msg = "The generation was canceled or something went wrong.";
            }
            setFailureMessage(msg);
        }
    }, [tduAnalysis.state]);

    useEffect(() => {
        if (!isSuccess) {
            return;
        }

        if (tduAnalysis && data && data.answers) {
            setLocalAnswers(data.answers);
        }
    }, [
        tduAnalysis,
        isSuccess,
        data
    ]);

    const taskDisplayText = useMemo(() => {
        let tfType = tduAnalysis.data['transform_type']
        for (const taskGroup of ALL_ADVISOR_SM_TASKS) {
            for (const task of taskGroup.tasks) {
                if (task.transformType === tfType) {
                    return task.display;
                }
            }
        }
    }, [tduAnalysis]);

    const popupDisplay = useMemo(() => {
        let documentIdFilter = tduAnalysis.data['document_id_filter'];
        let targetKeyTermIdFilter = tduAnalysis.data['target_key_term_id_filter'];

        let documentNames = [];
        if (takerDocumentUploads && documentIdFilter && documentIdFilter.length > 0) {
            for (const tdu of takerDocumentUploads) {
                for (const item of tdu.fileUpload.fileUploadItems) {
                    if (documentIdFilter.includes(item.id)) {
                        documentNames.push(item.label);
                    }
                }
            }
        }

        let targetKeyTermNames = [];
        if (targetKeyTermIdFilter && targetKeyTermIdFilter.length > 0) {
            for (const holder of Object.values(documentKeyTermHolders)) {
                for (const keyTerm of holder.documentKeyTerms.keyTerms) {
                    if (targetKeyTermIdFilter.includes(keyTerm.identifier)) {
                        targetKeyTermNames.push(keyTerm.termName);
                    }
                }
            }
        }

        return (
            <Typography sx={{ p: 1 }}>
                {documentIdFilter && documentIdFilter.length > 0 && (
                    <>
                        Document Inputs:
                        <ul>
                            {documentNames.map((n) => <li>{n}</li>)}
                        </ul>
                    </>
                )}
                {targetKeyTermIdFilter && targetKeyTermIdFilter.length > 0 && (
                    <>
                        Target Key Terms:
                        <ul>
                            {targetKeyTermNames.map((n) => <li>{n}</li>)}
                        </ul>
                    </>
                )}
            </Typography>
        );
    }, [
        documentKeyTermHolders,
        takerDocumentUploads,
        tduAnalysis
    ]);

    return (
        <Grid
            height="100%"
            width="100%"
            container
            borderBottom="1px solid #f0f0f0"
        >
            <Grid
                item
                xs={12}
                display="flex"
                justifyContent="start"
            >
                <Typography 
                    display="flex" 
                    gap={1} 
                    alignItems="center"
                >
                    <i style={{ color: blue[500] }}>
                        {`${new Date(tduAnalysis.createdAt).toLocaleString()}: `}
                        {taskDisplayText}
                    </i>
                    <span>
                        {popupDisplay && (
                            <Popper
                                open={open}
                                anchorEl={anchorEl}
                                placement="left"
                                transition
                                sx={{
                                    zIndex: 1200
                                }}
                            >
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Paper
                                            sx={{
                                                backgroundColor: grey[600],
                                                color: "white"
                                            }}
                                        >
                                            {popupDisplay}
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                        )}
                        <IconButton onClick={handleInfoClick}>
                            <Info />
                        </IconButton>
                    </span>
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                paddingBottom={1}
            >
                {pendingGeneration === true && (
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="100%"
                        height="35px"
                        sx={{
                            marginTop: 1
                        }}
                    />
                )}
                {(pendingGeneration === false && failureMessage === undefined) && (
                    <>
                        {localAnswers.map((a, i) => (
                            <Box
                                key={i}
                                width="100%"
                            >
                                <Typography
                                    sx={{
                                        marginTop: 1,
                                        marginBottom: 1
                                    }}
                                    variant="h6"
                                >
                                    <u>{a.title}</u>
                                </Typography>
                                <Markdown>{a.answer}</Markdown>
                            </Box>
                        ))}
                    </>
                )}
                {(pendingGeneration === false && failureMessage !== undefined) && (
                    <Box
                        paddingLeft={1}
                        paddingRight={1}
                        width="100%"
                    >
                        <Typography>
                            <i>{failureMessage}</i>
                        </Typography>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
};

export default AdvisorResponse;
