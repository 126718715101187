
import React, { useContext, PropsWithChildren, memo, useEffect, useState, useMemo } from 'react';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useReadOnlyBuilderData } from '../ReadOnlyBuilderData/ReadOnlyBuilderData';
import {
    BuilderDocumentGuidance,
    BuilderDocumentGuidanceDisplayMode,
    IndexedGuidance,
    TakerDocument
} from '../../redux/models/dataModelTypes';
import KeyTermGroupLoader from './KeyTermGroupLoader';
import { DocumentKeyTermsHolder } from '../../types/documentKeyTerms';

export interface ResearchPanelBasicState {
    isOpen: boolean;
    currentTab: "GUIDANCE" | "ADVISOR";
}

export interface ResearchPanelAdvisorState {
    topLevelSelection?: "CUSTOM" | "SOURCE_MATERIALS" | "QUESTIONNAIRE" | "REPORT";
    customState?: ResearchPanelCustomState;
    sourceMaterialsState?: ResearchPanelSourceMaterialsState;
}

export interface ResearchPanelSourceMaterialsState {
    selectedTaskIndex?: [number, number] | undefined;
    documentIdFilter?: string[];
    targetKeyTermIdFilter?: string[];
}

export interface ResearchPanelCustomState {
    query?: string;
    applyLiteratureFilter?: boolean;
}

export type GuidanceViewType = "PDF" | "NATIVE";

export interface GuidanceSectionContent {
    lexicalContent: string;
    content: string;
    contentHtml: string;
}

interface ResearchPanelHookData {
    // panel UI state
    researchPanelBasicState: ResearchPanelBasicState;
    setResearchPanelBasicState: React.Dispatch<React.SetStateAction<ResearchPanelBasicState>>;
    researchPanelAdvisorState: ResearchPanelAdvisorState;
    setResearchPanelAdvisorState: React.Dispatch<React.SetStateAction<ResearchPanelAdvisorState>>;
    documentKeyTermHolders: Record<string, DocumentKeyTermsHolder>;
    setDocumentKeyTermHolders: React.Dispatch<React.SetStateAction<Record<string, DocumentKeyTermsHolder>>>
    selectedGuidanceItemId: string | undefined;
    setSelectedGuidanceItemId: React.Dispatch<React.SetStateAction<string | undefined>>;
    selectedGuidanceId: string | undefined;
    setSelectedGuidanceId: React.Dispatch<React.SetStateAction<string | undefined>>;
    guidanceOutlineOpen: boolean;
    setGuidanceOutlineOpen: React.Dispatch<React.SetStateAction<boolean>>;
    activeGuidanceIds: string[];
    setActiveGuidanceIds: React.Dispatch<React.SetStateAction<string[]>>;
    viewTypeByGuidanceId: Record<string, GuidanceViewType>;
    setViewTypeByGuidanceId: React.Dispatch<React.SetStateAction<Record<string, GuidanceViewType>>>;
    fullContentList: HeaderEntry[];
    targetIndexedGuidance: IndexedGuidance | undefined;
    targetDisplayMode: BuilderDocumentGuidanceDisplayMode;
    bdgToDisplay: BuilderDocumentGuidance[];
    shortcutForAdvisorKeyTermId: (identifier: string) => void;
}

export interface HeaderEntry {
    id: string;
    depth: number;
    header: string;
    guidanceId: string;
    hasContent: boolean;
}

export interface Task {
    transformType: string;
    display: string;
}

export interface TaskGroup {
    displayGroupName: string;
    tasks: Task[];
}

export const ALL_ADVISOR_SM_TASKS: TaskGroup[] = [
    {
        displayGroupName: "Transaction Summaries",
        tasks: [
            {
                transformType: "TXN_SUMMARY_OVERALL",
                display: "To help me get an overall understanding of the transaction, please generate a high level summary of the transaction."
            },
            {
                transformType: "TXN_SUMMARY_BY_DOCUMENTS",
                display: "To help me get an overall understanding of the transaction, please generate a high level summary of the transaction by document."
            },
            {
                transformType: "TXN_SUMMARY_BY_GROUP",
                display: "To help me get an overall understanding of the transaction, please generate a high level summary of the transaction by Key Term Group."
            }
        ]
    },
    {
        displayGroupName: "Document Questions",
        tasks: [
            {
                transformType: "DOCUMENT_QA_HIGH_LEVEL_ACCOUNTING_ISSUES",
                display: "What high level accounting issues do you see?"
            },
            {
                transformType: "DOCUMENT_QA_CONTRACT_CONTRADICTIONS",
                display: "Are there any contradictions in a contract?"
            },
            {
                transformType: "DOCUMENT_QA_IRRELEVANT_INFO",
                display: "What information do you think is irrelevant?"
            }
        ]
    },
    {
        displayGroupName: "Individual Key Terms",
        tasks: [
            {
                transformType: "INDIVIDUAL_KEY_TERMS_LEFT_OUT_DETAILS",
                display: "What details does this summary leave out?"
            }
        ]
    }
];

const Context = React.createContext({});

interface ResearchPanelDataProps {
    takerDocument: TakerDocument;
}

type ResearchPanelDataHook = () => ResearchPanelHookData;

export const useResearchPanelData: ResearchPanelDataHook = () => {
    return useContext(Context) as ResearchPanelHookData;
}

const ResearchPanelDataContainer: React.FC<PropsWithChildren<ResearchPanelDataProps>> = ({
    takerDocument,
    children
}) => {
    const { builderDocumentGuidances } = useReadOnlyBuilderData();

    const [researchPanelBasicState, setResearchPanelBasicState] = useLocalStorage<ResearchPanelBasicState>(`ResearchPanelData-researchPanelBasicState`, {
        isOpen: false,
        currentTab: "ADVISOR"
    });
    const [researchPanelAdvisorState, setResearchPanelAdvisorState] = useState<ResearchPanelAdvisorState>({});
    const [selectedGuidanceItemId, setSelectedGuidanceItemId] = useLocalStorage<string | undefined>("ResearchPanelData-selectedGuidanceItemId", undefined);
    const [selectedGuidanceId, setSelectedGuidanceId] = useLocalStorage<string | undefined>("ResearchPanelData-selectedGuidanceId", undefined);
    const [guidanceOutlineOpen, setGuidanceOutlineOpen] = useLocalStorage<boolean>("ResearchPanelData-guidanceOutlineOpen", false);
    const [activeGuidanceIds, setActiveGuidanceIds] = useLocalStorage<string[]>("ResearchPanelData-activeGuidanceIds", []);
    const [viewTypeByGuidanceId, setViewTypeByGuidanceId] = useLocalStorage<Record<string, GuidanceViewType>>("ResearchPanelData-viewTypeByGuidanceId", {});
    const [fullContentList, setFullContentList] = useState<HeaderEntry[]>([]);

    const [documentKeyTermHolders, setDocumentKeyTermHolders] = useState<{
        [key: string]: DocumentKeyTermsHolder;
    }>({}); // takerDocumentUploadId -> DocumentKeyTermsHolder;

    const targetIndexedGuidance = useMemo(() => {
        let bdg = builderDocumentGuidances.find(
            bdg => bdg.indexedGuidance?.guidanceId === selectedGuidanceId
        );
        if (bdg) {
            return bdg.indexedGuidance;
        }
    }, [
        selectedGuidanceId,
        builderDocumentGuidances
    ]);

    const targetDisplayMode = useMemo(() => {
        if (!selectedGuidanceId) {
            return "NONE";
        }

        let bdg = builderDocumentGuidances.find(
            bdg => bdg.indexedGuidance?.guidanceId === selectedGuidanceId
        );
        if (!bdg) {
            return "NONE";
        }

        let viewType = viewTypeByGuidanceId[selectedGuidanceId];
        if (viewType === "NATIVE") {
            return "LEXICAL";
        } else if (viewType === "PDF") {
            return "PDF";
        }
        return bdg.displayMode;
    }, [
        viewTypeByGuidanceId,
        selectedGuidanceId,
        builderDocumentGuidances
    ]);

    const bdgToDisplay = useMemo(
        () => builderDocumentGuidances.filter(
            bdg => bdg.displayMode !== "NONE"
                && bdg.indexedGuidance
                && activeGuidanceIds.includes(bdg.indexedGuidance?.guidanceId)
        ),
        [
            builderDocumentGuidances,
            activeGuidanceIds
        ]
    );

    useEffect(() => {
        if (builderDocumentGuidances.length === 0 || selectedGuidanceId !== undefined) {
            return;
        }

        const allGuidanceIds = [];
        for (const bdg of builderDocumentGuidances) {
            if (bdg.indexedGuidance) {
                allGuidanceIds.push(bdg.indexedGuidance.guidanceId);
            }
        }
        setActiveGuidanceIds(allGuidanceIds);
        setSelectedGuidanceId(allGuidanceIds[0]);
    }, [builderDocumentGuidances.length]);

    useEffect(() => {
        if (!targetIndexedGuidance) {
            return;
        }

        const labeledRefs = targetIndexedGuidance.referenceMap['labeledRefs'];
        const contentList: HeaderEntry[] = [];

        const iterate = async (depth: number, ref: any) => {
            contentList.push({
                id: ref['id'],
                depth: depth,
                header: ref['label'],
                guidanceId: targetIndexedGuidance.guidanceId,
                hasContent: !!ref['hasContent']
            });
            for (let i = 0; i < ref['nested'].length; i++) {
                iterate(depth + 1, ref['nested'][i]);
            }
        }

        for (let i = 0; i < labeledRefs.length; i++) {
            iterate(0, labeledRefs[i]);
        }
        setFullContentList(contentList);
    }, [targetIndexedGuidance?.referenceMap]);

    const shortcutForAdvisorKeyTermId = (identifier: string) => {
        setResearchPanelBasicState({
            isOpen: true,
            currentTab: "ADVISOR"
        });
        setResearchPanelAdvisorState({
            topLevelSelection: "SOURCE_MATERIALS",
            sourceMaterialsState: {
                selectedTaskIndex: [2, 0],
                documentIdFilter: undefined,
                targetKeyTermIdFilter: [identifier]
            }
        })
    };

    return (
        <Context.Provider
            value={{
                researchPanelBasicState,
                setResearchPanelBasicState,
                selectedGuidanceItemId,
                setSelectedGuidanceItemId,
                selectedGuidanceId,
                setSelectedGuidanceId,
                guidanceOutlineOpen,
                setGuidanceOutlineOpen,
                activeGuidanceIds,
                setActiveGuidanceIds,
                viewTypeByGuidanceId,
                setViewTypeByGuidanceId,
                fullContentList,
                targetIndexedGuidance,
                targetDisplayMode,
                bdgToDisplay,
                researchPanelAdvisorState,
                setResearchPanelAdvisorState,
                documentKeyTermHolders,
                setDocumentKeyTermHolders,
                shortcutForAdvisorKeyTermId
            }}>
            <>
                {takerDocument?.takerDocumentUploads && takerDocument?.takerDocumentUploads.map((tdu) => (
                    <KeyTermGroupLoader
                        key={tdu.id}
                        takerDocumentUploadId={tdu.id}
                        onLoaded={(holder) => {
                            setDocumentKeyTermHolders(prev => ({
                                ...prev,
                                [tdu.id]: holder
                            }));
                        }}
                    />
                ))}
                {children}
            </>
        </Context.Provider>
    );
}

export default memo(ResearchPanelDataContainer);