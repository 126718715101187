import React, { useCallback, useEffect, useState, useRef, useMemo } from "react";
import PageMask from "./PageMask";
import { Box, Chip } from "@mui/material";
import { elementScroll, useVirtualizer } from '@tanstack/react-virtual'
import { LexicalDocument } from "../../types/taker/documentkeyterms.generated";
import PdfPage from "./PdfViewer/PdfPage";
import { usePdfHighlighter } from "./context";

import type { VirtualizerOptions } from '@tanstack/react-virtual'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

const ASPECT_RATIO_SCALE_MULTIPLIER = .80;
const DEFAULT_ZOOM_LEVEL = 0.97;
const SCROLL_TO_ELEMENT_PADDING: number = 0.2;

interface EditablePagesWrapperProps {
    readOnly: boolean;
    lexicalDocuments: LexicalDocument[];
    parentDiv: HTMLDivElement;
    documentWidth: number;
    pdfUrlGetter: (lexicalDocumentIdentifier: string) => string;
    pdfNameGetter: (lexicalDocumentIdentifier: string) => string;
}

const EditablePagesWrapper = ({
    readOnly,
    lexicalDocuments,
    parentDiv,
    documentWidth,
    pdfUrlGetter,
    pdfNameGetter
}: EditablePagesWrapperProps) => {
    const {
        scrollToPage,
        setScrollToPage,
        zoomLevel,
        scrollToElementID,
        setScrollToElementID,
        currentPageRange,
        setCurrentPageRange,
        navigateHighlightElementIDs,
        stickyNavigateHighlightElementIDs,
        setNavigateHighlightElementIDs,
        pdfHighlighterService,
        panelViewMode,
        applyFiltersToDocuments,
        keyTermIdentifierFilters,
        availableHightlightTerms
    } = usePdfHighlighter();

    const [resizeJob, setResizeJob] = useState<any>();

    const isScrolling = useRef(false);

    const documentPageFilters = useMemo(() => {
        const documentPageFilters = [];
        const pagesByDocId: Record<string, Set<number>> = {};

        // if applyFiltersToDocuments and there are terms to filter by.
        if (applyFiltersToDocuments 
            && availableHightlightTerms 
            && keyTermIdentifierFilters && keyTermIdentifierFilters.length > 0
        ) {
            for (const ht of availableHightlightTerms) {
                // skip if no identifier or no annotations
                if (!ht.identifier || (!ht.documentAnnotations && !ht.aiDocumentAnnotations)) {
                    continue;
                }

                if (keyTermIdentifierFilters 
                    && keyTermIdentifierFilters.length > 0
                    && !keyTermIdentifierFilters.includes(ht.identifier)) {
                    continue;
                }

                if (ht.documentAnnotations) {
                    for (const da of ht.documentAnnotations) {
                        if (!pagesByDocId[da.lexicalDocumentIdentifier]) {
                            pagesByDocId[da.lexicalDocumentIdentifier] = new Set();
                        }
                        pagesByDocId[da.lexicalDocumentIdentifier].add(da.page);
                    }
                }

                if (ht.aiDocumentAnnotations) {
                    for (const da of ht.aiDocumentAnnotations) {
                        if (!pagesByDocId[da.lexicalDocumentIdentifier]) {
                            pagesByDocId[da.lexicalDocumentIdentifier] = new Set();
                        }
                        pagesByDocId[da.lexicalDocumentIdentifier].add(da.page);
                    }
                }
            }
        }

        for (const [docId, pages] of Object.entries(pagesByDocId)) {
            documentPageFilters.push({
                documentId: docId,
                pages
            });
        }
        return documentPageFilters;
    }, [
        applyFiltersToDocuments, 
        keyTermIdentifierFilters, 
        availableHightlightTerms
    ]);

    const getDocumentPageFromIndex = useCallback((index: number) => {
        let agg = 0;
        let pageIndex = index;
        for (let i = 0; i < lexicalDocuments.length; i++) {
            agg += lexicalDocuments[i].lexicalPages.length;
            if (index < agg) {
                return [i, pageIndex];
            }
            pageIndex -= lexicalDocuments[i].lexicalPages.length;
        }
    }, [lexicalDocuments]);

    const getIndexFromDocumentPage = useCallback((documentId: string, pageIndex: number) => {
        let agg = 0;
        for (let i = 0; i < lexicalDocuments.length; i++) {
            if (lexicalDocuments[i].identifier === documentId) {
                break;
            }
            agg += lexicalDocuments[i].lexicalPages.length;
        }
        return pageIndex + agg;
    }, [lexicalDocuments]);

    const getDocumentIndex = useCallback((documentId: string) => {
        for (let i = 0; i < lexicalDocuments.length; i++) {
            if (lexicalDocuments[i].identifier === documentId) {
                return i;
            }
        }
    }, [lexicalDocuments]);

    const getHeight = useCallback((thisWidth: number, index: number, zoom: number) => {
        const docPage = getDocumentPageFromIndex(index);
        if (docPage) {
            // if there is a filter and the page isn't in the filter 
            // or the document is not in the filter, return height of 0
            if (documentPageFilters && documentPageFilters.length > 0) {
                const filter = documentPageFilters.find(dpf => dpf.documentId === lexicalDocuments[docPage[0]].identifier);
                if (filter) {
                    if (!filter.pages.has(docPage[1])) {
                        return 0;
                    }
                } else {
                    return 0;
                }
            }

            const meta = lexicalDocuments[docPage[0]].pageMetadata[docPage[1]] as Record<string, number>;
            if (meta) {
                let ogWidth = meta['original_page_width'];
                let ogHeight = meta['original_page_height'];
                let isVersion2 = !!meta['is_version_2'];
                let scaledWidth = thisWidth * zoom;
                if (isVersion2) {
                    return (ogHeight * (scaledWidth / ogWidth)) + 35;
                }
                return ((ogHeight * (scaledWidth / ogWidth)) / ASPECT_RATIO_SCALE_MULTIPLIER) + 35;
            }
        }
        return 0;
    }, [getDocumentPageFromIndex, lexicalDocuments, documentPageFilters]);

    const scrollToFn: VirtualizerOptions<any, any>['scrollToFn'] = useCallback((offset, canSmooth, instance) => {
        elementScroll(offset, canSmooth, instance);
    }, []);

    const totalPages = useMemo(
        () => lexicalDocuments.reduce((total, { lexicalPages }) => total + lexicalPages.length, 0), 
        [lexicalDocuments]
    );

    const rowVirtualizer = useVirtualizer({
        count: totalPages,
        getScrollElement: () => parentDiv,
        estimateSize: (index) => getHeight(documentWidth, index, zoomLevel ?? DEFAULT_ZOOM_LEVEL),
        overscan: 1,
        scrollToFn
    });

    const pdfNameMap = useMemo(() => {
        const map = new Map<string, string>();
        for (const doc of lexicalDocuments) {
            map.set(doc.identifier, pdfNameGetter(doc.identifier));
        }
        return map;
    }, [lexicalDocuments, pdfNameGetter]);

    useEffect(() => {
        if (currentPageRange !== undefined 
            && currentPageRange[1] !== undefined 
            && currentPageRange[1] < totalPages) {
            rowVirtualizer.scrollToIndex(currentPageRange[1]);
        }
    }, [totalPages]);

    useEffect(() => {
        const range = rowVirtualizer.calculateRange();
        if (range) {
            const { startIndex, endIndex } = range;
            setCurrentPageRange([startIndex, endIndex]);
        }
    }, [rowVirtualizer.range]);

    const resizeAllHeights = useCallback((withScrollAdjustment: boolean) => {
        const originalOffset = (rowVirtualizer.scrollOffset ?? 0);
        const scrollRectHeight = rowVirtualizer.getTotalSize();

        // Resize all heights, and re-measure
        for (let i = 0; i < totalPages; i++) {
            rowVirtualizer.resizeItem(i, getHeight(parentDiv.offsetWidth, i, zoomLevel ?? DEFAULT_ZOOM_LEVEL));
        }

        if (withScrollAdjustment) {
            rowVirtualizer.measure();
                       
            const newScrollRectHeight = rowVirtualizer.getTotalSize();
            const scaleFactor = newScrollRectHeight / scrollRectHeight;
            const newScrollOffset = originalOffset * scaleFactor;

            const isNewBeyondOld = newScrollOffset > scrollRectHeight;
            if (isNewBeyondOld) {
                setTimeout(() => {
                    rowVirtualizer.scrollToOffset(newScrollOffset, { behavior: 'auto' });
                }, 5);
            } else {
                rowVirtualizer.scrollToOffset(newScrollOffset, { behavior: 'auto' });
            }
        }
    }, [totalPages, lexicalDocuments, rowVirtualizer, parentDiv, zoomLevel, currentPageRange]);

    // Resize on zoom level change, with scroll adjustment
    const [lastZoomLevel, setLastZoomLevel] = useState(zoomLevel);
    useEffect(() => {
        setLastZoomLevel(zoomLevel);
    }, [zoomLevel]);

    useEffect(() => {
        if (zoomLevel !== lastZoomLevel) {
            resizeAllHeights(true);
        }
    }, [zoomLevel, lastZoomLevel, documentPageFilters]);

    // Resize on panel change or document width change.
    useEffect(() => {
        if (panelViewMode === 0 || panelViewMode == 2) {
            resizeAllHeights(false);
        }
    }, [panelViewMode, documentWidth]);

    // Resize on window resize event.
    useEffect(() => {
        const onResize = () => {
            if (resizeJob) {
                clearTimeout(resizeJob);
            }
            setResizeJob(setTimeout(() => resizeAllHeights(false), 100));
        };
        window.addEventListener("resize", onResize);

        const scrollListener = () => {
            setScrollToPage(undefined);
            setScrollToElementID(undefined);
        }
        parentDiv.addEventListener("scroll", scrollListener);
        return () => {
            window.removeEventListener("resize", onResize);
            parentDiv.removeEventListener("scroll", scrollListener);
        };
    }, []);

    const scrollToElement = (element: Element) => {
        const rect = element.getBoundingClientRect();
        const scrollOffset = rowVirtualizer.scrollOffset ? rowVirtualizer.scrollOffset : 0;
        const windowHeight = rowVirtualizer.scrollRect !== null ? rowVirtualizer.scrollRect.height : 0;
        const topPadding = SCROLL_TO_ELEMENT_PADDING * windowHeight;
        const deltaToElement: number = rect.top - parentDiv.getBoundingClientRect().top;
        const targetOffset: number = scrollOffset + deltaToElement - topPadding;
        rowVirtualizer.scrollToOffset(targetOffset, { behavior: rowVirtualizer.isScrolling ? 'auto' : 'smooth' });
    };

    const globalClickListener = () => {
        window.removeEventListener("click", globalClickListener);
        if (!stickyNavigateHighlightElementIDs) {
            setScrollToPage(undefined);
            setScrollToElementID(undefined);
            setNavigateHighlightElementIDs(undefined);
        }
    }

    useEffect(() => {
        let observer: MutationObserver | undefined = undefined;
        if (scrollToElementID !== undefined 
            && scrollToPage !== undefined) {
            const idx = getIndexFromDocumentPage(scrollToPage.docId, scrollToPage.page);
            rowVirtualizer.scrollToIndex(idx, { 
                align: 'start', 
                behavior: 'smooth' 
            });

            const createOnElementAvailable = () => {
                const waitTimeout = 5000;
                const waitStart = Date.now();
                const observer = new MutationObserver(mutations => {
                    const docIdx = getDocumentIndex(scrollToElementID.docId);
                    if (docIdx === undefined) {
                        isScrolling.current = false;
                        return
                    }

                    const elements = document.querySelectorAll(`[id='${scrollToElementID.elementId}']`);
                    const element = elements[docIdx];
                    if ((element || Date.now() - waitStart > waitTimeout)) {
                        if (element) {
                            scrollToElement(element);
                            isScrolling.current = false;
                        }
                    }
                });
                observer.observe(parentDiv, { childList: true, subtree: true });
                return observer;
            };

            // only create an observer once
            if (!isScrolling.current) {
                isScrolling.current = true;
                window.removeEventListener("click", globalClickListener);
                observer = createOnElementAvailable();
                
                setScrollToPage(undefined);
                setScrollToElementID(undefined);
            }
        }

        return () => {
            if (observer !== undefined) {
                observer.disconnect();
            }
        };
    }, [scrollToElementID]);

    useEffect(() => {
        if (navigateHighlightElementIDs !== undefined) {
            window.addEventListener("click", globalClickListener);
        }
        return () => {
            if (navigateHighlightElementIDs !== undefined) {
                window.removeEventListener("click", globalClickListener);
            }
        }
    }, [navigateHighlightElementIDs]);

    if (lexicalDocuments.length === 0) {
        return (
            <div
                style={{
                    width: "100%"
                }}
            >
                <em>document(s) is/are blank or not available</em>
            </div>
        );
    }

    return (
        <div
            style={{
                height: `${rowVirtualizer.getTotalSize()}px`,
                width: `${(parentDiv.offsetWidth * (zoomLevel ?? DEFAULT_ZOOM_LEVEL))}px`,
                position: 'relative',
                overflowY: 'hidden',
            }}
            data-testid="full-document-div"
        >
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const docPage = getDocumentPageFromIndex(virtualRow.index);
                if (docPage) {
                    const lexicalDocument = lexicalDocuments[docPage[0]];
                    const meta = lexicalDocument.pageMetadata[docPage[1]] as Record<string, number>;
                    if (meta) {
                        let ogWidth = meta['original_page_width'];
                        let ogHeight = meta['original_page_height'];
                        let isVersion2 = !!meta['is_version_2'];

                        let scale = (parentDiv.offsetWidth * (zoomLevel ?? DEFAULT_ZOOM_LEVEL)) / ogWidth; // Adjusted for zoomLevel
                        const adjustedWidth = parentDiv.offsetWidth * (zoomLevel ?? DEFAULT_ZOOM_LEVEL);
                        const adjustedHeight = ogHeight * scale;

                        // signal to hide this page
                        if (virtualRow.size === 0) {
                            return null;
                        }

                        return (
                            <>
                                {isVersion2 && (
                                    <div
                                        key={`document-visual-${virtualRow.index}`}
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: `${virtualRow.size}px`,
                                            transform: `translateY(${virtualRow.start}px)`,
                                            zIndex: 1,
                                            pointerEvents: "none",
                                        }}
                                    >
                                        <PdfPage
                                            key={`pdf-page-${docPage[0]}-${docPage[1]}`}
                                            pdfUrl={pdfUrlGetter(lexicalDocument.identifier)}                                            
                                            pageNumber={docPage[1] + 1}
                                            height={adjustedHeight}
                                            width={adjustedWidth}
                                            scale={scale}
                                            targetFileUploadItemId={lexicalDocument.identifier}
                                        />
                                    </div>
                                )}
                                <div
                                    key={`document-separator-${virtualRow.index}`}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        height: `${adjustedHeight}px`,
                                        width: `${adjustedWidth}px`,
                                        transform: `translateY(${virtualRow.start}px)`,
                                        zIndex: 5,
                                        pointerEvents: "none",
                                        background: "rgba(0, 0, 0, 0)"
                                    }}
                                >
                                    <Box
                                        style={{
                                            position: 'absolute',
                                            width: "100%",
                                            zIndex: 1000,
                                            textAlign: "center",
                                            bottom: '-5px',
                                            left: 0,
                                            height: '35px',
                                        }}
                                    >
                                        <Chip
                                            sx={{ 
                                                background: "#ddd",
                                                opacity: 0.65
                                            }}
                                            variant="filled"
                                            label={`${pdfNameMap.get(lexicalDocument.identifier)} page ${(docPage[1] + 1)}`}
                                            size="small"
                                        />
                                    </Box>
                                </div>
                                <div
                                    key={`document-interactive-${virtualRow.index}`}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        height: `${adjustedHeight}px`,
                                        width: `${adjustedWidth}px`,
                                        transform: `translateY(${virtualRow.start}px)`,
                                        zIndex: 10
                                    }}
                                >
                                    <PageMask
                                        readOnly={readOnly}
                                        lexicalDocumentIdentifier={lexicalDocument.identifier}
                                        pageIndex={docPage[1]}
                                        lexicalPage={lexicalDocument.lexicalPages[docPage[1]]}
                                        annotations={
                                            pdfHighlighterService.getDocumentAnnotations(
                                                lexicalDocument.identifier,
                                                docPage[1]
                                            ) || []
                                        }
                                        aiAnnotations={
                                            pdfHighlighterService.getAiGeneratedDocumentAnnotations(
                                                lexicalDocument.identifier,
                                                docPage[1]
                                            ) || []
                                        }
                                        pageScaleFactor={scale}
                                        isV2Render={isVersion2}
                                        editorParentBoundingBox={parentDiv.getBoundingClientRect()}
                                        key={`${lexicalDocument.identifier}-${docPage[1]}-PageMask`}
                                    />
                                </div>
                            </>
                        );
                    }
                }
            })}
        </div>
    );
};

export default EditablePagesWrapper;