import React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
    accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { ArrowDropDownCircle } from '@mui/icons-material';
import GuidanceOutlineItem from './GuidanceOutlineItem';
import { Box, Typography, Chip, useTheme, Toolbar, Autocomplete, TextField } from "@mui/material";
import { blue } from '@mui/material/colors';
import { APP_BAR_HEIGHT } from '../../navigation/SideNav';
import GuidanceOutlineFilter from './GuidanceOutlineFilter';
import { useResearchPanelData } from '../../../containers/ResearchPanelData/ResearchPanelData';
import { createPortal } from 'react-dom';

const drawerWidth = 200;

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    backgroundColor: '#FBFCFE',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
    '& .Mui-expanded': {
        backgroundColor: blue[50],
    }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowDropDownCircle sx={{ transform: 'rotate(-90deg)', }} />}
        {...props}
    />
))(({ theme }) => ({
    flexDirection: 'row-reverse',
    [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]: {
        transform: 'rotate(90deg)',
    },
    [`& .${accordionSummaryClasses.content}`]: {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,
}));

const GuidanceOutline = () => {
    const theme = useTheme();
    const {
        guidanceOutlineOpen,
        selectedGuidanceId,
        setSelectedGuidanceId,
        bdgToDisplay
    } = useResearchPanelData();

    if (!guidanceOutlineOpen) {
        return null;
    }
    return createPortal(
        <Box style={{
            marginTop: `${3 * APP_BAR_HEIGHT}px`,
            height: `calc(100vh - ${3 * APP_BAR_HEIGHT}px)`,
            width: drawerWidth,
            overflowX: 'hidden',
            overflowY: 'hidden',
            borderRight: `1px solid ${theme.palette.divider}`,
            top: 0,
            left: '50vw',
            position: 'absolute',
            zIndex: 10000, // make this appear in a drawer
            backgroundColor: '#FBFCFE',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            })
        }}>
            <Toolbar
                variant="dense"
                disableGutters
                sx={{
                    width: "100%",
                    height: "48px",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    flexGrow={1}
                    paddingLeft={1}
                    paddingRight={1}
                >
                    <Autocomplete
                        size='small'
                        disabled
                        multiple
                        fullWidth
                        data-testid="bookmarks-filter"
                        options={[
                            {
                                id: "first",
                                name: "Test Bookmark",
                                group: "User Bookmarks",
                            },
                            {
                                id: "other",
                                name: "Other Bookmark",
                                group: "Question Bookmarks",
                            }
                        ]}
                        getOptionLabel={(option) => {
                            return option.name;
                        }}
                        groupBy={(option) => option.group}
                        value={undefined}
                        onChange={(event, newValue) => {

                        }}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Bookmarks"
                                variant="standard"
                                data-testid="bookmarks-filter-input"
                            />
                        )}
                        renderTags={(tagValues, getTagProps) => {
                            const allChips = tagValues.map((option, index) => {
                                return (
                                    <Chip
                                        label={option.name}
                                        size='small'
                                        {...getTagProps({ index })}
                                        data-testid={`bookmark-chip-${option}`}
                                    />
                                );
                            });
                            return allChips
                        }}
                    />
                </Box>
                <GuidanceOutlineFilter />
            </Toolbar>
            <Box
                display="flex"
                flexDirection="column"
                height="calc(100% - 48px)"
                flexGrow={1}
                sx={{ overflowY: "auto" }}
            >
                {bdgToDisplay.map((bdg) => {
                    if (!bdg.indexedGuidance) {
                        return null;
                    }

                    const thisGuidanceId = bdg.indexedGuidance.guidanceId;
                    const fullName = bdg.canCite ?
                        bdg.indexedGuidance.name :
                        `${bdg.indexedGuidance.name} (Non-Citation)`;
                    
                    return (
                        <Accordion
                            expanded={selectedGuidanceId === thisGuidanceId}
                            onChange={() => {
                                setSelectedGuidanceId(prev => prev === thisGuidanceId ? undefined : thisGuidanceId);
                            }}
                        >
                            <AccordionSummary
                                aria-controls={`${thisGuidanceId}-content`}
                                id={`${thisGuidanceId}-header`}
                            >
                                <Typography>{fullName}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <GuidanceOutlineItem
                                    guidanceId={thisGuidanceId}
                                    labeledRefs={bdg.indexedGuidance.referenceMap['labeledRefs']}
                                />
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Box>
        </Box>,
        document.body
    );
};

export default GuidanceOutline;
