import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
    $createTextNode,
    $getRoot,
    $isTextNode,
    BLUR_COMMAND,
    COMMAND_PRIORITY_LOW,
    FOCUS_COMMAND,
    LexicalCommand,
    LexicalNode,
    TextNode,
    createCommand,
} from 'lexical';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { $createKeyTermNode, $isKeyTermNode, KeyTermNode } from '../../nodes/KeyTermNode';
import { useKeyTermGroupState } from '../../../../containers/TakerDocumentState/KeyTermGroupState';
import { useCounter, useDebounce } from '@uidotdev/usehooks';

export const FORCE_REPROCESS_TEXT_FOR_KEY_TERMS_COMMAND: LexicalCommand<void> = createCommand('FORCE_REPROCESS_TEXT_FOR_KEY_TERMS_COMMAND')

interface AnnotateWithKeyTermsPluginProps {
    keyTermIdentifier: string;
};

function escapeRegExp(string: string): string {
    // Escape special characters for use in a regular expression
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const AnnotateWithKeyTermsPlugin = ({
    keyTermIdentifier,
}: AnnotateWithKeyTermsPluginProps) => {
    const { documentKeyTermsService } = useKeyTermGroupState();
    const [editor] = useLexicalComposerContext();
    const [text, setText] = useState<string>();
    const [isFocused, setIsFocused] = useState(false);
    const debouncedText = useDebounce(text, 500);
    const [textProcessingCounter, textProcessingMutator] = useCounter();

    const availableKeyTermNames = useMemo(() => {
        if (!documentKeyTermsService.keyTerms) {
            return [];
        }

        const availableKeyTermNames: { id: string; name: string }[] = [];
        if (documentKeyTermsService.keyTerms) {
            for (const keyTerm of documentKeyTermsService.keyTerms) {
                if (keyTerm.identifier 
                    && keyTerm.identifier !== keyTermIdentifier
                    && !keyTerm.addedByAi) {
                    availableKeyTermNames.push({
                        id: keyTerm.identifier,
                        name: keyTerm.termName
                    });
                }
            }
        }
        return availableKeyTermNames;
    }, [documentKeyTermsService.keyTerms]);

    const keyTermNameToId = useMemo(() => {
        const map = {} as { [name: string]: string };
        for (const { name, id } of availableKeyTermNames) {
            map[name] = id;
        }
        return map;
    }, [availableKeyTermNames]);

    const allKeyTermIdentifiers = useMemo(() =>
        new Set(availableKeyTermNames.map((term) => term.id)),
        [availableKeyTermNames]
    );

    const escapedTerms = useMemo(() => {
        return availableKeyTermNames.map((term) =>
            escapeRegExp(term.name)
        );
    }, [availableKeyTermNames]);

    const reprocessText = useCallback(() => {
        if (escapedTerms.length === 0) {
            return;
        }

        editor.update(() => {
            const root = $getRoot();
            const textNodes: TextNode[] = [];
            const keyTermNodes: KeyTermNode[] = [];

            // Recursively collect all text nodes in the editor
            function findTextNodes(node: LexicalNode) {
                const children = node.getChildren();
                for (const child of children) {
                    if ($isTextNode(child)) {
                        textNodes.push(child);
                    } else if (!$isKeyTermNode(child)) {
                        // Avoid descending into KeyTermNodes to prevent nesting
                        findTextNodes(child);
                    }
                }
            }

            function findKeyTermNodes(node: any) {
                const children = node.getChildren();
                for (const child of children) {
                    if ($isKeyTermNode(child)) {
                        keyTermNodes.push(child);
                    } else if (!$isTextNode(child)) {
                        // Avoid descending into text nodes to prevent nesting
                        findKeyTermNodes(child);
                    }
                }
            }

            findTextNodes(root);
            findKeyTermNodes(root);

            // replace any existing KeyTermNodes that are no longer valid
            for (const node of keyTermNodes) {
                if (!allKeyTermIdentifiers.has(node.__identifier)) {
                    node.replace($createTextNode(node.__name));
                }
            }

            const pattern = new RegExp(escapedTerms.join('|'), 'g');

            // Iterate over each text node to find and highlight matches
            for (const textNode of textNodes) {
                const textContent = textNode.getTextContent();
                let matches: { index: number; length: number }[] = [];

                // Find all matches in the text content
                let match;
                while ((match = pattern.exec(textContent)) !== null) {
                    matches.push({ index: match.index, length: match[0].length });
                }

                if (matches.length > 0) {
                    let lastIndex = 0;
                    const newNodes = [];

                    // Split the text node at each match and apply highlighting
                    for (const { index, length } of matches) {
                        if (index > lastIndex) {
                            const plainText = textContent.substring(lastIndex, index);
                            newNodes.push($createTextNode(plainText));
                        }
                        const highlightedText = textContent.substring(
                            index,
                            index + length
                        );

                        const identifier = keyTermNameToId[highlightedText];
                        if (identifier) {
                            const keyTermNode = $createKeyTermNode(identifier, highlightedText);
                            newNodes.push(keyTermNode);
                        }
                        lastIndex = index + length;
                    }

                    if (lastIndex < textContent.length) {
                        const remainingText = textContent.substring(lastIndex);
                        newNodes.push($createTextNode(remainingText));
                    }

                    // Insert new nodes before the original text node
                    for (const newNode of newNodes) {
                        textNode.insertBefore(newNode);
                    }

                    // Remove the original text node
                    textNode.remove();
                } else {
                    // No matches; leave the text node as is
                }
            }
        }, {
            onUpdate: () => {
                textProcessingMutator.increment();
            }
        });
    }, [
        isFocused,
        availableKeyTermNames,
        editor,
        keyTermNameToId,
        allKeyTermIdentifiers,
        debouncedText
    ]);

    const updateKeyTermNode = useCallback((keyTermNode: KeyTermNode) => {
        // update name if the names change
        const availableKt = availableKeyTermNames.find((kt) => kt.id === keyTermNode.__identifier);
        if (availableKt && (keyTermNode.__name !== availableKt.name)) {
            keyTermNode.__name = availableKt.name;
        }
    }, [
        availableKeyTermNames
    ]);

    useEffect(() => {
        if (!debouncedText || escapedTerms.length === 0) {
            return;
        }

        const pattern = new RegExp(escapedTerms.join('|'), 'g');

        let matches: { index: number; length: number }[] = [];
   
        // Find all matches in the text content
        let match;
        while ((match = pattern.exec(debouncedText)) !== null) {
            matches.push({ index: match.index, length: match[0].length });
        }

        if (matches.length > 0) {
            reprocessText();
        }
    }, [debouncedText]);

    useEffect(() => {
        if (isFocused) {
            reprocessText();
        }
    }, [availableKeyTermNames.length]);

    useEffect(() => {
        if (isFocused && textProcessingCounter === 0) {
            reprocessText();
        }
    }, [
        isFocused,
        textProcessingCounter
    ]);

    useEffect(() => {
        return mergeRegister(
            editor.registerNodeTransform(KeyTermNode, (keyTermNode) => {
                keyTermNode.__shouldHighlight = true;
            }),
            editor.registerCommand(
                FOCUS_COMMAND,
                () => {
                    setIsFocused(true);
                    return false;
                },
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                BLUR_COMMAND,
                () => {
                    setIsFocused(false);
                    return false;
                },
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                FORCE_REPROCESS_TEXT_FOR_KEY_TERMS_COMMAND,
                () => {
                    reprocessText();
                    return true;
                },
                COMMAND_PRIORITY_LOW
            )
        );
    }, [editor]);

    useEffect(() => {
        if (isFocused) {
            return mergeRegister(
                editor.registerNodeTransform(KeyTermNode, (keyTermNode) => {
                    updateKeyTermNode(keyTermNode);
                }),
                editor.registerTextContentListener((text: string) => {
                    setText(text);
                })
            );
        }
        return () => {};
    }, [
        editor, 
        isFocused
    ]);

    return null;
}

export default AnnotateWithKeyTermsPlugin;
