import React, { memo, useEffect, useRef, useState } from 'react';
import { usePdf } from './PdfDocumentProvider';

import type {
    PDFPageProxy,
    PDFDocumentProxy 
} from 'pdfjs-dist';

const CANVAS_CACHE_MAX_SIZE = 10;
const _canvasCache = new Map<string, HTMLCanvasElement>();
const _keysQueue: string[] = [];

export const clearCanvasCache = () => {
    _canvasCache.clear();
    _keysQueue.length = 0;
};

const _formatKey = (pageNumber: number, documentId: string) => {
    return `${documentId}-${pageNumber}`;
}

const hasCanvas = (pageNumber: number, documentId: string) => {
    const key = _formatKey(pageNumber, documentId);
    return _canvasCache.has(key);
};

const cacheCanvas = (pageNumber: number, documentId: string, canvas: HTMLCanvasElement) => {
    const key = _formatKey(pageNumber, documentId);
    _canvasCache.set(key, canvas);
    _keysQueue.push(key);
    
    if (_canvasCache.size > CANVAS_CACHE_MAX_SIZE) {
        const lastKey = _keysQueue.shift();
        if (lastKey) {
            _canvasCache.delete(lastKey);
        }
    }
};

interface RenderedPdfPageProps {
    pageNumber: number;
    height: number;
    width: number;
    documentId: string;
    pdfDocument?: PDFDocumentProxy;
}

const RenderedPdfPage = ({
    pageNumber,
    height,
    width,
    documentId,
    pdfDocument
}: RenderedPdfPageProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [rendered, setRendered] = useState(false);

    useEffect(() => {
        if (!rendered) {
            // Check if the canvas for this pageNumber is already cached
            if (hasCanvas(pageNumber, documentId)) {
                // Reuse the cached canvas
                const cachedCanvas = _canvasCache.get(_formatKey(pageNumber, documentId));
                if (containerRef.current && cachedCanvas) {
                    containerRef.current.innerHTML = ''; // Clear any existing content
                    cachedCanvas.style.width = `${width}px`;
                    cachedCanvas.style.height = `${height}px`;
                    containerRef.current.appendChild(cachedCanvas); // Append the cached canvas
                }
                setRendered(true); // Mark this page as rendered
            
            } else {
                if (pdfDocument) {
                    // Render the page if it's not cached and we have the document proxy
                    pdfDocument.getPage(pageNumber).then((page) => renderCustomPage(page));
                    setRendered(true); // Mark this page as rendered
                }
            }
        }
    }, [
        pageNumber, 
        width, 
        height, 
        rendered, 
        pdfDocument, 
        documentId
    ]);

    const renderCustomPage = (page: PDFPageProxy) => {
        const viewport = page.getViewport({ scale: 3 });

        // Create a custom rendering process
        // Here you can use your own canvas, SVG, or any custom DOM elements
        const canvas = document.createElement('canvas');
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const context = canvas.getContext('2d');
        if (!context) {
            return;
        }

        const renderTask = page.render({
            canvasContext: context,
            viewport,
            annotationMode: 0
        });

        // Add the canvas to the container when the task is resolved
        renderTask.promise.then(() => {
            if (containerRef.current) {
                // evict furthest away in page distance
                cacheCanvas(pageNumber, documentId, canvas);

                canvas.style.width = `${width}px`;
                canvas.style.height = `${height}px`;
                containerRef.current.appendChild(canvas);
            }
        }).catch((error) => {
            // Handle any errors that occur during rendering, e.g. displaying an error message
            // For now, just swallow the error.
            console.error(error);
        });
    };

    return (
        <div ref={containerRef} style={{ width: `${width}px`, height: `${height}px`, overflow: 'hidden' }}></div>
    );
};

const MemoizedRenderedPdfPage =  memo(RenderedPdfPage);

interface PdfPageProps {
    pdfUrl: string;
    pageNumber: number;
    height: number;
    width: number;
    scale: number;
    targetFileUploadItemId: string
}

const PdfPage = ({ 
    pdfUrl, 
    pageNumber,
    height, 
    width, 
    scale,
    targetFileUploadItemId
}: PdfPageProps) => {
    const { getDocument } = usePdf();
    const [pdfDocument, setPdfDocument] = useState<PDFDocumentProxy>();

    useEffect(() => {
        let isMounted = true;
        getDocument(pdfUrl).then((pdf) => {
            if (isMounted) {
                setPdfDocument(pdf);
            }
        });
        return () => {
            isMounted = false;
        };
    }, [pdfUrl]);

    return (
        <MemoizedRenderedPdfPage
            key={`rendered-page-${targetFileUploadItemId}-${pageNumber}-${scale}`}
            pageNumber={pageNumber} 
            pdfDocument={pdfDocument}
            height={height}
            width={width}
            documentId={targetFileUploadItemId}
        />
    );
};

export default memo(PdfPage);
