import { Box, Fade, Grid, IconButton, InputBase, List, ListItem, ListItemButton, ListItemText, Paper, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import { useResearchPanelData } from '../../../containers/ResearchPanelData/ResearchPanelData';
import RectangularLoading from '../../loading/RectangularLoading';
import { Search } from '@mui/icons-material';

const MAX_SEARCH_RESULTS = 100;

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

interface SearchResult {
    refId: string;
    displayLabel: string;
}

interface GuidanceSearchProps {
}

const GuidanceSearch = ({

}: GuidanceSearchProps) => {
    const {
        setSelectedGuidanceItemId,
        targetIndexedGuidance
    } = useResearchPanelData();

    const [inputValue, setInputValue] = useState<string>('');
    const [searchedInputValue, setSearchedInputValue] = useState<string>('');

    const [labeledRefs, setLabeledRefs] = useState<any[]>([]);
    const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);

    const [isLoadingOutline, setIsLoadingOutline] = useState<boolean>(false);
    const [outlineSearchResults, setOutlineSearchResults] = useState<SearchResult[]>([]);
    const [isSearchResultDirty, setIsSearchResultDirty] = useState<boolean>(false);

    const searchBoxRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (targetIndexedGuidance?.referenceMap['labeledRefs']) {
            setLabeledRefs(targetIndexedGuidance?.referenceMap['labeledRefs']);
        }
    }, [targetIndexedGuidance?.referenceMap]);

    useEffect(() => {
        if (inputValue === '') {
            setIsSearchOpen(false);
        } else {
            setIsSearchOpen(true);
        }
    }, [inputValue]);

    useEffect(() => {
        setIsSearchResultDirty(inputValue !== searchedInputValue);
    }, [inputValue, searchedInputValue]);

    useEffect(() => {
        if (!labeledRefs) {
            return;
        }

        setIsLoadingOutline(true);
        const resultSet = new Set<string>();
        const refQueue = [...labeledRefs];
        while (refQueue.length > 0) {
            const ref = refQueue.shift();
            const label = ref['label'];
            if (label.toLowerCase().includes(searchedInputValue.toLowerCase())) {
                resultSet.add(ref['id']);
                if (resultSet.size >= MAX_SEARCH_RESULTS) {
                    break;
                }
            }

            // add nested to queue to explore
            for (let i = 0; i < ref['nested'].length; i++) {
                refQueue.push(ref['nested'][i]);
            }
        }

        const osRes: SearchResult[] = [];
        const iterate = (labels: string[], ref: any) => {
            const label = ref['label'];
            if (resultSet.has(ref['id'])) {
                if (labels.length === 0) {
                    osRes.push({
                        refId: ref['id'],
                        displayLabel: label,
                    });
                } else {
                    osRes.push({
                        refId: ref['id'],
                        displayLabel: labels.join(' > ') + ' > ' + label,
                    });
                }
                resultSet.delete(ref['id']);
            }

            if (resultSet.size === 0) {
                return;
            }
            for (let i = 0; i < ref['nested'].length; i++) {
                iterate([...labels, label], ref['nested'][i]);
            }
        }

        for (let i = 0; i < labeledRefs.length; i++) {
            iterate([], labeledRefs[i]);
        }
        setOutlineSearchResults(osRes);
        setIsLoadingOutline(false);
    }, [
        searchedInputValue,
        labeledRefs
    ]);

    return (
        <>
            <Box
                ref={searchBoxRef}
                display="flex"
                alignItems="center"
                margin={1}
                borderBottom="1px solid #ccc"
            >
                <InputBase
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            setSearchedInputValue(inputValue);
                            setIsSearchOpen(true);
                        }
                    }}
                    fullWidth
                    placeholder="Search Guidance"
                    onFocus={() => setIsSearchOpen(true)}
                    onBlur={() => setIsSearchOpen(false)}
                />
                <IconButton
                    aria-label="search"
                    size={"small"}
                    color={isSearchResultDirty ? "primary" : "default"}
                    onClick={() => {
                        setSearchedInputValue(inputValue);
                        setIsSearchOpen(true);
                    }}
                >
                    <Search />
                </IconButton>
            </Box>
            <StyledPopper
                sx={{ zIndex: 10002 }}
                open={isSearchOpen}
                anchorEl={searchBoxRef.current}
                placement="bottom"
                transition
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper
                            sx={{
                                maxHeight: '50vh',
                                width: '30vw',
                                overflow: 'auto',
                            }}
                        >
                            {isLoadingOutline ? (
                                <RectangularLoading />
                            ) : (
                                <Grid container>
                                    <Grid item xs={12}>
                                        <List
                                            component="nav"
                                            dense
                                            disablePadding
                                        >
                                            {isSearchResultDirty && (
                                                <ListItem>
                                                    <ListItemText
                                                        secondary="Showing Stale Results"
                                                        secondaryTypographyProps={{
                                                            textAlign: 'center'
                                                        }}
                                                    />
                                                </ListItem>
                                            )}
                                            {(outlineSearchResults.length === 0) ? (
                                                <ListItem>
                                                    <ListItemText primary="No results" />
                                                </ListItem>
                                            ) : (outlineSearchResults.map((result, i) => (
                                                <ListItemButton
                                                    key={i}
                                                    onClick={() => {
                                                        setIsSearchOpen(false);
                                                        setSelectedGuidanceItemId(result.refId);
                                                    }}
                                                >
                                                    <ListItemText>{result.displayLabel}</ListItemText>
                                                </ListItemButton>
                                            )))}
                                            {(outlineSearchResults.length === MAX_SEARCH_RESULTS) && (
                                                <ListItem>
                                                    <ListItemText
                                                        secondary="Max Results Shown"
                                                        secondaryTypographyProps={{
                                                            textAlign: 'center'
                                                        }}
                                                    />
                                                </ListItem>
                                            )}
                                        </List>
                                    </Grid>
                                </Grid>
                            )}
                        </Paper>
                    </Fade>
                )}
            </StyledPopper>
        </>
    );
};

export default GuidanceSearch;