import { init as initAPM } from "@elastic/apm-rum"

const initPraxiAPM = () => {
  initAPM(
    {
      serviceName: window.__RUNTIME_CONFIG__.APM_RUM_AGENT_SERVICE_NAME,
      serverUrl: window.__RUNTIME_CONFIG__.APM_RUM_AGENT_SERVER_URL,
      environment: window.__RUNTIME_CONFIG__.APM_RUM_AGENT_ENVIRONMENT,
      distributedTracingOrigins: [window.__RUNTIME_CONFIG__.API_ENDPOINT],
      active: window.__RUNTIME_CONFIG__.APM_RUM_AGENT_ACTIVE === "TRUE"
    }
  );
}

export default initPraxiAPM;