import { WidgetsSharp } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    background: {
      default: "#FFFFFF"
    },
    primary: {
      main: "#276EDF"
    },
    secondary: {
      main: "rgb(228, 228, 228, 0.9)",
      contrastText: "#000",
      dark: "rgba(228, 228, 228, 0.75)",
    }
  },
  typography: {
    fontFamily: ["Roboto"].join(","),
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 4, // Set the border radius to 2
          width: 'auto',   // Ensures it's not restricted to a circular width/height
          height: 'auto',  // Ensures it's not restricted to a circular width/height
          padding: 2,     // Ensures it's not restricted to a circular width/height
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: 8, // Set the border radius to 2
          paddingTop: 1,     // Ensures it's not restricted to a circular width/height
          paddingBottom: 1,
          border: `2px solid #276EDF`,
          textTransform: 'unset',
          height: '2em'
        }
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeMedium: {
          height: '2.5em'
        }
      }
    }
  },
});

export default theme;