import React, { useContext, PropsWithChildren, useRef, useEffect, useMemo, useCallback, useState, memo, Suspense } from 'react';
import {
    AddMemoGenAnalysisPayload,
    TakerDocumentAnalysis,
} from "../../redux/models/dataModelTypes";
import {
    useGetLatestTakerDocumentDataQuery, useUpdateTakerDocumentDataMutation
} from '../../redux/services/takerData';
import { useTakerState } from './TakerDocumentState';
import { ReportStateHolder } from '../../types/report';
import { useAddMemoGenAnalysisMutation } from '../../redux/services/taker';
import { OptionsWithExtraProps, useSnackbar } from 'notistack';
import { ApiError } from '../../redux/reduxUtils/baseQuery';
import { ReportRevision } from '../../types/taker/reportstate.generated';

const SAVE_DELAY = 500;
const TOP_CENTER_ERROR_OPTION = {
    variant: 'error',
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
    }
} as OptionsWithExtraProps<'error'>;

interface ReportStateHookData {
    initStateCount: number;
    isReportUninitialized: boolean;
    lastSavedTimestamp: number | undefined;
    isReportStateDirty: boolean;
    allReportRevisions: ReportRevision[];
    latestReportRevision?: ReportRevision;
    latestReportDataId?: string;
    latestMemoGenAnalyses?: Array<TakerDocumentAnalysis>;
    createMemoGenAnalysis: () => void;
    updateSycnedWithGenerationId: (newSycnedWithGenerationId: string) => void;
    upsertEditLexical: (editIdentifier: string, editLexical: string | null, sectionHash: string,  updateHash?: boolean) => void;
}

type ReportStateHook = () => ReportStateHookData;

const Context = React.createContext({});

export const useReportState: ReportStateHook = () => {
    return useContext(Context) as ReportStateHookData;
}

interface ReportStateContainerProps {
}

const ReportStateContainer: React.FC<PropsWithChildren<ReportStateContainerProps>> = ({
    children
}) => {
    const { 
        activeTakerDocument, 
        takerDocumentId, 
        taker, 
        getActiveSetting,
        takerPermissionState,
        isTakerDocumentUninitialized
    } = useTakerState();
    const {
        data: latestReportData,
        isFetching: isReportDataLoading,
        isUninitialized: isReportDataUninitialized,
        isError: isReportDataError
    } = useGetLatestTakerDocumentDataQuery({ takerDocumentId, contentType: "REPORT" });

    const [addMemoGenAnalysis, addMemoGenAnalysisResult] = useAddMemoGenAnalysisMutation();
    const [
        updateTakerDocumentData,
        updateTakerDocumentDataRes
    ] = useUpdateTakerDocumentDataMutation();

    const [updatingJob, setUpdatingJob] = useState<any>(null);
    const [initStateCount, setInitStateCount] = useState<number>(0);
    const [isStateDirty, setIsStateDirty] = useState<boolean>(false);
    const [lastMarkedClean, setLastMarkedClean] = useState<number>();
    
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (isStateDirty && updateTakerDocumentDataRes.isSuccess) {
          setIsStateDirty(false);
          setLastMarkedClean(updateTakerDocumentDataRes.data.updatedAt);
        }
      }, [updateTakerDocumentDataRes]);

    const activeTargetReportState = useRef<ReportStateHolder | undefined>(
        latestReportData && new ReportStateHolder(latestReportData.content)
    );

    useEffect(() => {
        if (latestReportData) {
            activeTargetReportState.current = new ReportStateHolder(latestReportData.content);
            setInitStateCount(initStateCount + 1);
        }
    }, [latestReportData?.id]);

    useEffect(() => {
        if (addMemoGenAnalysisResult.isError && addMemoGenAnalysisResult.error) {
            enqueueSnackbar(
                (addMemoGenAnalysisResult.error as ApiError).data,
                TOP_CENTER_ERROR_OPTION
            );
        }
    }, [addMemoGenAnalysisResult]);

    const latestMemoGenAnalyses = useMemo(
        () => activeTakerDocument?.memoGenerationTakerDocumentAnalyses.slice().sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1),
        [activeTakerDocument?.memoGenerationTakerDocumentAnalyses]
    );

    const createMemoGenAnalysis = useCallback(() => {
        if (taker) {
            const disableLlm = getActiveSetting("REPORT_DISABLE_AI_GENERATED_CONTENT") === "true";
            const caching = getActiveSetting("REPORT_ENABLE_AI_FEATURE_CACHING") === "true";
            const payload = {
                takerId: taker.id,
                takerDocumentId,
                disableLlm: disableLlm
            } as AddMemoGenAnalysisPayload;
            if (caching && latestMemoGenAnalyses) {
                payload.previousAnalysisId = (latestMemoGenAnalyses.length > 0) ? latestMemoGenAnalyses[0].id : null;
            }
            addMemoGenAnalysis(payload);
        }
    }, [
        latestMemoGenAnalyses, 
        taker, 
        takerDocumentId,
        getActiveSetting, 
        addMemoGenAnalysis
    ]);

    const doStateUpdateFunc = (newState: ReportStateHolder) => {
        // no updates for non read/roles
        if (!takerPermissionState.isReadWrite) {
            return
        }
        
        activeTargetReportState.current = newState;
        setIsStateDirty(true);

        if (updatingJob) {
            clearTimeout(updatingJob)
        }
        let newUpdatingJob = setTimeout(() => {
            if (taker &&
                latestReportData &&
                activeTargetReportState.current) {
                updateTakerDocumentData({
                    takerId: taker.id,
                    takerDocumentId,
                    id: latestReportData.id,
                    content: activeTargetReportState.current.reportStateData
                });
            }
        }, SAVE_DELAY);
        setUpdatingJob(newUpdatingJob);
    };

    const doStateUpdate = useRef(doStateUpdateFunc);
    useEffect(() => {
        doStateUpdate.current = doStateUpdateFunc;
    });

    const reportRevisions = activeTargetReportState.current?.reportStateData.reportRevisions;
    const latestReportRevision = (reportRevisions && reportRevisions.length > 0) && reportRevisions[reportRevisions.length - 1];

    const updateSycnedWithGenerationId = useCallback((newSyncedWithGenerationId: string) => {
        if (activeTargetReportState.current && latestReportRevision) {
            const newState = new ReportStateHolder(activeTargetReportState.current.reportStateData);
            newState.updateSyncedWithGenerationId(latestReportRevision.revisionId, newSyncedWithGenerationId);
            doStateUpdate.current(newState);
        }
    }, [activeTargetReportState, latestReportRevision]);

    const upsertEditLexical = useCallback((editIdentifier: string, editLexical: string | null, sectionHash: string, updateHash: boolean=false) => {
        if (activeTargetReportState.current && latestReportRevision) {
            const newState = new ReportStateHolder(activeTargetReportState.current.reportStateData);
            newState.upsertEditLexical(latestReportRevision.revisionId, editIdentifier, editLexical, sectionHash, updateHash);
            doStateUpdate.current(newState);
        }
    }, [activeTargetReportState, latestReportRevision]);

    const lastSavedTimestamp = useMemo(
        () => !isReportDataError && (lastMarkedClean || latestReportData?.updatedAt),
        [
            isReportDataError, 
            lastMarkedClean, 
            latestReportData
        ]
    );

    return (
        <Context.Provider
            value={{
                initStateCount: initStateCount,
                isReportUninitialized: isReportDataUninitialized || isTakerDocumentUninitialized,
                lastSavedTimestamp,
                isReportStateDirty: isStateDirty,
                allReportRevisions: reportRevisions,
                latestReportRevision,
                latestReportDataId: latestReportData?.id,
                latestMemoGenAnalyses,
                createMemoGenAnalysis,
                updateSycnedWithGenerationId,
                upsertEditLexical
            }}>
            {children}
        </Context.Provider>
    );
};

export default memo(ReportStateContainer);