import React, { useEffect, useState, useRef, useCallback } from "react";

interface Coordinates {
    x: number;
    y: number;
}
interface DrawnArea {
    start: undefined | Coordinates;
    end: undefined | Coordinates;
}
interface UseAreaSelectionProps {
    container: React.RefObject<HTMLElement> | undefined;
    enabled: boolean;
}

const boxElem = document.createElement("div");
boxElem.style.position = "fixed";
boxElem.style.background = "hsl(206deg 100% 50% / 5%)";
boxElem.style.boxShadow = "inset 0 0 0 2px hsl(206deg 100% 50% / 50%)";
boxElem.style.borderRadius = "2px";
boxElem.style.pointerEvents = "none";
boxElem.style.mixBlendMode = "multiply";
boxElem.style.zIndex = "9999";

export function useAreaSelection({
    container = { current: document.body },
    enabled
}: UseAreaSelectionProps) {
    const boxRef = useRef<HTMLDivElement>(boxElem);
    const [mouseDown, setMouseDown] = useState<boolean>(false);
    const [selection, setSelection] = useState<DOMRect | null>(null);
    const [drawArea, setDrawArea] = useState<DrawnArea>({
        start: undefined,
        end: undefined
    });
    const [selectionDebounced, setSelectionDebounced] = React.useState<DOMRect | null>(null);

    const handleMouseMove = (e: MouseEvent) => {
        document.body.style.userSelect = "none";
        setDrawArea((prev) => ({
            ...prev,
            end: {
                x: e.clientX,
                y: e.clientY
            }
        }));
    };

    const handleMouseDown = (e: MouseEvent) => {
        const containerElement = container.current;

        setMouseDown(true);

        if (
            containerElement &&
            containerElement.contains(e.target as HTMLElement) && 
            enabled
        ) {
            document.addEventListener("mousemove", handleMouseMove);
            setDrawArea({
                start: {
                    x: e.clientX,
                    y: e.clientY
                },
                end: {
                    x: e.clientX,
                    y: e.clientY
                }
            });
        }
    };

    const handleMouseUp = (e: MouseEvent) => {
        document.body.style.userSelect = "initial";
        document.removeEventListener("mousemove", handleMouseMove);
        setMouseDown(false);
    };

    useEffect(() => {
        if (!mouseDown) {
            setSelectionDebounced(selection);
        }
    }, [selection, mouseDown]);

    useEffect(() => {
        const containerElement = container.current;
        if (containerElement && enabled) {
            containerElement.addEventListener("mousedown", handleMouseDown);
            document.addEventListener("mouseup", handleMouseUp);

            return () => {
                containerElement.removeEventListener("mousedown", handleMouseDown);
                document.removeEventListener("mouseup", handleMouseUp);
            };
        }
    }, [container, enabled]);

    useEffect(() => {
        const { start, end } = drawArea;
        if (start && end && boxRef.current && enabled) {
            drawSelectionBox(boxRef.current, start, end);
            setSelection(boxRef.current.getBoundingClientRect());
        }
    }, [drawArea, boxRef]);

    useEffect(() => {
        const containerElement = container.current;
        const selectionBoxElement = boxRef.current;
        if (containerElement && selectionBoxElement && enabled) {
            if (mouseDown) {
                if (!document.body.contains(selectionBoxElement)) {
                    containerElement.appendChild(selectionBoxElement);
                }
            } else {
                if (containerElement.contains(selectionBoxElement)) {
                    containerElement.removeChild(selectionBoxElement);
                }
            }
        }
    }, [mouseDown, container, boxRef, enabled]);

    return selectionDebounced;
}

export function highlightSelectionFromRect(rect: DOMRect, currentPageRange: [number, number]): void {
    for (let pageId = currentPageRange[0]; pageId <= currentPageRange[1]; pageId++) {
        const rootElement = document.querySelector(`.page[id="${pageId}"]`);

        // Create a range object
        const range = document.createRange();

        // Function to find the first and last text nodes within the provided rect
        function findTextNodesWithinRect(node: Node, rect: DOMRect): { startNode: Text | null, endNode: Text | null } {
            let startNode: Text | null = null;
            let endNode: Text | null = null;

            // Recursive function to check if a node's bounding box is within the rect
            function checkNode(node: Node) {
                if (node.nodeType === Node.TEXT_NODE) {
                    const textNode = node as Text;
                    const textRange = document.createRange();
                    textRange.selectNodeContents(textNode);
                    const boundingRect = textRange.getBoundingClientRect();

                    // Check if the bounding rect of the text node intersects the provided rect
                    if (boundingRect.left <= rect.right &&
                        boundingRect.right >= rect.left &&
                        boundingRect.top <= rect.bottom &&
                        boundingRect.bottom >= rect.top) {

                        if (!startNode) {
                            startNode = textNode;
                        }
                        endNode = textNode; // Update the endNode to the last found one within the rect
                    }
                } else if (node.nodeType === Node.ELEMENT_NODE) {
                    // If the node is an element, recurse through its children
                    const elementNode = node as HTMLElement;
                    for (let i = 0; i < elementNode.childNodes.length; i++) {
                        checkNode(elementNode.childNodes[i]);
                    }
                }
            }

            checkNode(node);
            return { startNode, endNode };
        }

        if (!rootElement) {
            console.error("No root element found.");
            return;
        }

        // Find the start and end text nodes within the rect
        const { startNode, endNode } = findTextNodesWithinRect(rootElement, rect);

        if (startNode && endNode) {
            // Set the start and end of the range
            range.setStart(startNode, 0);
            range.setEnd(endNode, endNode.textContent?.length || 0);

            // Apply the selection
            const selection = window.getSelection();
            if (selection) {
                selection.removeAllRanges();
                selection.addRange(range);
            }
        }
    }
}

function drawSelectionBox(
    boxElement: HTMLElement,
    start: Coordinates,
    end: Coordinates
): void {
    const b = boxElement;
    if (end.x > start.x) {
        b.style.left = start.x + "px";
        b.style.width = end.x - start.x + "px";
    } else {
        b.style.left = end.x + "px";
        b.style.width = start.x - end.x + "px";
    }

    if (end.y > start.y) {
        b.style.top = (start.y ) + "px";
        b.style.height = end.y - start.y + "px";
    } else {
        b.style.top = (end.y ) + "px";
        b.style.height = start.y - end.y + "px";
    }
}
