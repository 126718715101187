import { alpha, styled } from '@mui/material/styles';
import MuiMenu, { MenuProps } from '@mui/material/Menu';
import { blue } from '@mui/material/colors';

const StyledMenu = styled((props: MenuProps) => (
    <MuiMenu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    zIndex: 10001,
    '& .MuiPaper-root': {
        borderRadius: 2,
        maxWidth: 300,
        width: "30vw",
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: 0,
            backgroundColor: "#F0F4F8",
        },
        '& .MuiDivider-root': {
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
            marginLeft: theme.spacing(1), 
            marginRight: theme.spacing(1)
        },
        '& .MuiMenuItem-root': {
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            '&:hover': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.hoverOpacity,
                ),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
            '& .MuiSvgIcon-root': {
                marginRight: theme.spacing(1),
            },
            '& .MuiBox-root.menu-item-box-1': {
                padding: theme.spacing(1.25),
                marginTop: theme.spacing(0.5),
                marginBottom: theme.spacing(0.5),
                width: "100%",
                borderRadius: 1,
                backgroundColor: "#FBFCFE",
                textAlign: "center",
                textWrap: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            },
            '& .MuiBox-root.menu-item-box-2': {
                padding: theme.spacing(0.5),
                marginTop: theme.spacing(0.25),
                marginBottom: theme.spacing(0.25),
                width: "100%",
                borderRadius: 2,
                backgroundColor: "#FBFCFE",
                textAlign: "center",
                textWrap: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
            },
            '& .MuiBox-root.menu-item-highlighted': {
                backgroundColor: blue[100]
            },
        },
    },
}));

export default StyledMenu;