import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot } from "lexical";
import { useEffect } from "react";


const ForceUpdatePlugin = ({
    lexicalDocumentIdentifier,
    lexicalPage
}: {
    lexicalDocumentIdentifier: string;
    lexicalPage?: Object,
}) => {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        if (lexicalPage) {
            const editorState = editor.parseEditorState(JSON.stringify(lexicalPage));
            if (!editorState.isEmpty()) {
                editor.setEditorState(editorState);
            } else {
                editor.update(() => {
                    const root = $getRoot();
                    root.clear();
                });
                console.warn(`editorState was empty for page with id=${lexicalDocumentIdentifier}, page=${lexicalPage}`);
            }
        }
    }, [lexicalDocumentIdentifier])
    return null;
};

export default ForceUpdatePlugin;