import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import DefaultLoadingScreen from "../../components/loading/defaultLoadingScreen";

import "./SignIn.css";

export default function SignIn() {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      navigate("/");
    } else if (!isAuthenticated && !isLoading) {
      loginWithRedirect({
        appState: {
          returnTo: searchParams.get("redirect") ?? "/home",
        },
      });
    }
  }, [isAuthenticated, isLoading]);

  return (
    <DefaultLoadingScreen />
  );
}
